import { FC, useEffect, useState } from 'react';

import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../hooks/redux';
import { socket } from '../../../libs/socket';
import Button from '../../common/button';
import { CloseCircleCrossBtn } from '../../icons/close-circle-cross-btn';
import { IPsyBlock } from '../new-questions';
import { shuffleArray } from '../../../utils/textHelpers';

export const MacCards: FC<IPsyBlock> = ({ onSetMenu, setShowCards }) => {
  const psyTools = useAppSelector((state) => state.psyToolsSlice.tools);
  const params = useParams();
  const roomName = params.id;
  const [selectedCard, setSelectedCard] = useState<null | number>(null);
  const [showDeck, setShowDeck] = useState(false);
  const cards = psyTools.find((el) => el.name === 'metaforic_cards')?.psy_tool_settings || [];

  const love = cards.find((el) => el.name === 'Любовь и отношения');
  const money = cards.find((el) => el.name === 'Деньги');
  const resurs = cards.find((el) => el.name === 'Ресурс');
  const arr = [love, money, resurs];
  const handleGetDeck = () => {
    const deck = cards.find((el) => el.id === selectedCard)?.value || [];
    socket.emit('message', {
      roomId: roomName,
      message: {
        datetime: new Date().toISOString(),
        action: 'open_tool',
        data: {
          name: 'metaforic_cards',
          show: showDeck,
          result: {
            name: cards.find((el) => el.id === selectedCard)?.name || '',
            value: shuffleArray(Object.values(deck)),
          },
        },
      },
    });
    onSetMenu(0);
    if (selectedCard) {
      setShowCards && setShowCards(showDeck);
    }
  };

  useEffect(() => {
    console.log(selectedCard);
  }, [selectedCard]);

  return (
    <>
      <div className="text-white text-sm lg:text-lg font-comfortaa font-bold flex items-center justify-between mb-4">
        МАК-карты
        <button className=" w-[20px] h-[20px] md:w-[30px] md:h-[30px]" onClick={() => onSetMenu(0)}>
          <CloseCircleCrossBtn className="w-full h-full" />
        </button>
      </div>
      <p className="text-sm text-white mb-3 text-center">
        Психолог выбирает одну из трёх колод и способ её отображения: рубашкой вверх или вниз.
      </p>
      <p className="text-sm text-white mb-5 text-center">Клиент выбирает от 1-й до 4-х карт.</p>
      <div className="flex flex-col h-[85%] gap-[30px] md:gap-[12px] overflow-y-auto white-scroll">
        {arr &&
          arr.map((el, idx) => (
            <div
              className="flex flex-col items-center gap-[5px] lg:gap-[10px] hover:cursor-pointer"
              key={idx}>
              <div
                style={{
                  background: `url(${
                    el && process.env.REACT_APP_API_URL + el?.settings?.[0]?.value[0]
                  })`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  border: '3px solid transparent',
                  borderColor:
                    selectedCard === el?.id ? `${el?.settings?.[0]?.value[1]}` : 'transparent',
                }}
                className={classNames(
                  `w-[177px] md:w-[220px] h-[230px] md:h-[290px] rounded-[25px] relative overflow-hidden flex flex-col items-center justify-center text-white`,
                  {
                    '!bg-none !border-1': showDeck && selectedCard !== el?.id,
                  }
                )}
                onClick={() => setSelectedCard(el!.id)}
                key={idx}>
                {showDeck && Object.values(el!.value).length > 0 ? (
                  <img
                    className="object-cover w-full h-full"
                    src={`${process.env.REACT_APP_API_URL}/${Object.values(el!.value)[0]}`}
                    alt="show"
                  />
                ) : (
                  <p className="font-manrope uppercase text-2xl font-extrabold text-center">
                    {/*{el?.name}*/}
                  </p>
                )}
              </div>
            </div>
          ))}
      </div>

      <div className="mt-auto flex gap-[10px] pt-4">
        <Button
          className={classNames(
            '!w-auto p-[5px] flex items-center justify-center border-transparent hover:border-blackBase hover:bg-grey3',
            {
              '!bg-[#DEC05E] hover:!bg-[#DEC05E] !border-[#DEC05E]': showDeck,
            }
          )}
          type="grey"
          onClick={() => setShowDeck(!showDeck)}>
          <svg
            width="23"
            height="23"
            viewBox="0 0 23 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M9.5 6.52622C9.75718 5.03554 10.564 3.69549 11.7611 2.77072C12.9582 1.84595 14.4586 1.40371 15.9659 1.53132C17.4732 1.65894 18.8779 2.34715 19.9025 3.46002C20.9271 4.57289 21.4971 6.02952 21.5 7.54222C21.4998 8.96872 20.9951 10.3492 20.0751 11.4394C19.1551 12.5295 17.8792 13.2592 16.473 13.4992M1.5 8.49922C1.5 5.18222 4.183 2.49922 7.5 2.49922L6.643 4.21322M21.5 14.4992C21.5 17.8162 18.817 20.4992 15.5 20.4992L16.357 18.7852M7.5 21.4992C9.0913 21.4992 10.6174 20.8671 11.7426 19.7419C12.8679 18.6166 13.5 17.0905 13.5 15.4992C13.5 13.9079 12.8679 12.3818 11.7426 11.2566C10.6174 10.1314 9.0913 9.49922 7.5 9.49922C5.9087 9.49922 4.38258 10.1314 3.25736 11.2566C2.13214 12.3818 1.5 13.9079 1.5 15.4992C1.5 17.0905 2.13214 18.6166 3.25736 19.7419C4.38258 20.8671 5.9087 21.4992 7.5 21.4992Z"
              stroke="#111026"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Button>
        <Button
          className="!text-[13px] lg:!text-sm p-[6px_10px]"
          disabled={!selectedCard}
          onClick={() => {
            handleGetDeck();
          }}>
          Показать колоду
        </Button>
      </div>
    </>
  );
};
