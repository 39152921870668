import React, { FC, useState, } from 'react'
import { IconProps } from '../../types/icon.types'

export const QuestionsCircleIcon: FC<IconProps> = ({width = 22, height = 22, fill = "#585858", needHover = true, className}) => {
  const [hover, setHover] = useState(false)

  return (
    <svg
      className={className}
      width={width}
      height={height}
      onMouseMove={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 0.5C13.7848 0.5 16.4555 1.60625 18.4246 3.57538C20.3938 5.54451 21.5 8.21523 21.5 11C21.5 13.7848 20.3938 16.4555 18.4246 18.4246C16.4555 20.3938 13.7848 21.5 11 21.5C8.21523 21.5 5.54451 20.3938 3.57538 18.4246C1.60625 16.4555 0.5 13.7848 0.5 11C0.5 8.21523 1.60625 5.54451 3.57538 3.57538C5.54451 1.60625 8.21523 0.5 11 0.5ZM11.5565 4.988C10.3355 4.988 9.3785 5.3345 8.669 6.0275C7.943 6.7205 7.5965 7.6775 7.5965 8.8985H9.4775C9.4775 8.2055 9.6095 7.661 9.89 7.2815C10.2035 6.8195 10.715 6.605 11.441 6.605C12.002 6.605 12.4475 6.7535 12.761 7.067C13.058 7.3805 13.223 7.8095 13.223 8.354C13.223 8.7665 13.0745 9.1625 12.7775 9.5255L12.5795 9.7565C11.507 10.7135 10.8635 11.4065 10.649 11.852C10.418 12.2975 10.319 12.842 10.319 13.469V13.7H12.2165V13.469C12.2165 13.073 12.299 12.7265 12.464 12.3965C12.6125 12.0995 12.827 11.819 13.124 11.5715C13.916 10.8785 14.3945 10.433 14.543 10.268C14.939 9.74 15.1535 9.0635 15.1535 8.2385C15.1535 7.232 14.8235 6.44 14.1635 5.8625C13.5035 5.2685 12.629 4.988 11.5565 4.988ZM11.2595 14.5085C10.9231 14.4994 10.5967 14.624 10.352 14.855C10.2312 14.9688 10.1362 15.1073 10.0736 15.261C10.0109 15.4147 9.9821 15.5801 9.989 15.746C9.989 16.109 10.1045 16.406 10.352 16.637C10.5949 16.8728 10.921 17.0032 11.2595 17C11.6225 17 11.9195 16.8845 12.167 16.6535C12.2904 16.5373 12.388 16.3965 12.4533 16.2401C12.5187 16.0838 12.5505 15.9154 12.5465 15.746C12.5497 15.5806 12.5191 15.4163 12.4567 15.2631C12.3943 15.11 12.3013 14.9711 12.1835 14.855C11.9324 14.6236 11.6009 14.4993 11.2595 14.5085Z"
        fill={(needHover && hover) ? '#111026' : fill}/>
    </svg>

  )
}
