import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft } from '../../components/icons/arrow-left';
import { Switch } from '../../components/common/switch';
import { Love } from '../../components/icons/reactions/love';
import { Joy } from '../../components/icons/reactions/joy';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { selectEmotions } from '../../store/psyTools.slice';
import { Sadness } from '../../components/icons/reactions/sadness';
import { Fear } from '../../components/icons/reactions/fear';
import { Anger } from '../../components/icons/reactions/anger';
import { selectUser, setUserData } from '../../store/auth.slice';
import { useChangeUserMutation } from '../../services/users.api';
import { toast } from 'react-toastify';

const EmotionDetector = () => {
  const tool = 'emotions_detection';
  const dispatch = useAppDispatch();
  const userData = useAppSelector(selectUser);
  const [changeUser] = useChangeUserMutation();
  const emotions = useAppSelector(selectEmotions);

  const getEmotionSmile = (val: string) => {
    switch (val) {
      case 'Любовь':
        return <Love needHover={false} />;
      case 'Радость':
        return <Joy needHover={false} />;
      case 'Гнев':
        return <Anger needHover={false} />;
      case 'Страх':
        return <Fear needHover={false} />;
      case 'Грусть':
        return <Sadness needHover={false} />;
      default:
        return null;
    }
  };

  const handleCheck = async () => {
    try {
      if (!userData) return;
      if (userData?.active_psy_tools?.includes(tool)) {
        const arr = userData?.active_psy_tools.filter((el) => el !== tool);
        dispatch(setUserData({ ...userData, active_psy_tools: arr }));
        await changeUser({
          id: userData.id,
          active_psy_tools: arr,
        });
      } else {
        dispatch(
          setUserData({
            ...userData,
            active_psy_tools: [...userData?.active_psy_tools, tool],
          })
        );
        await changeUser({
          id: userData.id,
          active_psy_tools: [...userData?.active_psy_tools, tool],
        });
      }
      toast.success('Данные успешно сохранены');
    } catch (error) {
      toast.error('Произошла ошибка');
    }
  };

  return (
    <div className="pb-[80px]">
      <h2 className="flex items-center gap-[14px] font-comfortaa font-semibold text-xl text-blackBase mb-5">
        <Link to={'/tools'} className="group">
          <ArrowLeft className="fill-blackBase group-hover:fill-redBase" width={21} height={16} />
        </Link>
        <span>«Детектор эмоций» - верный помощник для Психолога и Клиента</span>
      </h2>
      <p className="text-sm text-greyBase mb-5">
        «Что Вы чувствуете?» - часто сложный вопрос для Клиента. Встроенный в видео сервис ZiNGiZi
        «Детектор эмоций» поможет идентифицировать чувства, отследит динамику до и после сессии,
        запишет выбранные клиентом эмоции в терапевтический журнал.
      </p>

      <div className="flex items-center gap-5 mb-[40px]">
        <Switch
          checked={userData?.active_psy_tools?.includes(tool)}
          setChecked={() => handleCheck()}
          label={'Использовать Детектор эмоций в видео-сессиях'}
        />
      </div>

      <div className="flex flex-col gap-[60px]">
        {emotions?.psy_tool_settings &&
          emotions.psy_tool_settings.map((emotion) => (
            <div key={emotion.id} className="flex flex-col md:flex-row gap-[40px]">
              <div className="w-[120px] flex flex-col items-center justify-center self-center gap-[15px] bg-grey5 p-5 rounded-[10px]">
                {getEmotionSmile(emotion.name)}
                <p className="font-medium text-blackBase">{emotion.name}</p>
              </div>
              <div className="flex flex-wrap content-start gap-[20px]">
                {emotion.value.map((item) => (
                  <div
                    key={item}
                    className="w-max font-medium text-sm text-blackBase bg-goldBase p-[5px_15px] rounded-[5px]">
                    {item}
                  </div>
                ))}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default EmotionDetector;
