import React, { FC, useState } from 'react';
import { IconProps } from '../../types/icon.types';

export const PlusIcon: FC<IconProps> = ({ width = 60, height = 60, fill = '#fff', className }) => {
  return (
    <svg
      className={`${className}`}
      width={width}
      height={height}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M30 12.3228V47.6781M12.3223 30.0004H47.6777"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
