import React from 'react'

const Tariffs = () => {
  return (
    <div className="w-full pb-[85px]">
      <p className="text-greyBase mb-5">
        Дорогой психолог!
      </p>
      <p className="font-bold mb-5">
        С радостью сообщаем, что пять полезных базовых инструментов для Вас бесплатны и навсегда!
      </p>
      <p className="text-greyBase mb-5">
        Нам приятно объявить, что "Детектор эмоций", "ДПДГ онлайн", "Подкинь вопрос", три базовые
        колоды "МАК" и "Терапевтический журнал" теперь Ваши для неограниченного использования. Эти
        инструменты помогут Вам повысить эффективность терапии, а Ваши клиенты, несомненно, оценят
        комфорт и результативность работы с Вами.
      </p>
      <p className="text-greyBase mb-5">
        Наш сервис непрерывно развивается, и мы с нетерпением ждем, чтобы представить Вам новые
        инструменты, на которые вы сможете оформить подписку. Стоимость подписки скоро будет
        доступна в разделе "Тарифы" личного кабинета.
      </p>
      <p className="text-greyBase mb-5">
        ZiNGiZi дает новые возможности для развития профессиональных навыков, укрепления доверия,
        повышения Вашего статуса и улучшения восприятия Вас вашими клиентами.
      </p>
      <p className="text-greyBase">
        С уважением,<br/>
        Команда ZiNGiZi
      </p>
    </div>
  )
}

export default Tariffs