import classNames from 'classnames';
import React, { FC } from 'react';

const Loader: FC<{ className?: string }> = ({ className }) => {
  return (
    <div
      className={classNames(
        'absolute top-0 left-0 w-full h-full bg-greyBase04 z-[100] flex items-center justify-center',
        className
      )}>
      <svg className="w-[100px] h-[100px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
        <linearGradient id="a9">
          <stop offset="0" stopColor="#C00000" stopOpacity="0"></stop>
          <stop offset="1" stopColor="#C00000"></stop>
        </linearGradient>
        <circle
          fill="none"
          stroke="url(#a9)"
          strokeWidth="26"
          strokeLinecap="round"
          strokeDasharray="0 44 0 44 0 44 0 44 0 360"
          cx="100"
          cy="100"
          r="70"
          transform-origin="center">
          <animateTransform
            type="rotate"
            attributeName="transform"
            calcMode="discrete"
            dur="0.7"
            values="360;324;288;252;216;180;144;108;72;36"
            repeatCount="indefinite"></animateTransform>
        </circle>
      </svg>
    </div>
  );
};

export default Loader;
