import React, { FC, useState } from 'react'
import { IconProps } from '../../types/icon.types'

export const QuestionIcon: FC<IconProps> = ({
                                              width = 48,
                                              height = 48,
                                              fillBg = '#828282',
                                              fill = '#fff',
                                              className,
                                            }) => {
  const [hover, setHover] = useState(false)

  return (
    <svg
      className={`hover:cursor-pointer ${className}`}
      width={width}
      height={height}
      onMouseMove={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="10" fill={hover ? '#111026' : fillBg}/>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.1924 33.1924C30.7544 35.6304 27.4478 37 24 37C20.5522 37 17.2456 35.6304 14.8076 33.1924C12.3696 30.7544 11 27.4478 11 24C11 20.5522 12.3696 17.2456 14.8076 14.8076C17.2456 12.3696 20.5522 11 24 11C27.4478 11 30.7544 12.3696 33.1924 14.8076C35.6304 17.2456 37 20.5522 37 24C37 27.4478 35.6304 30.7544 33.1924 33.1924ZM24.8824 26.3016C27.2199 25.9102 29 23.9898 29 21.6875C29 19.1031 26.7574 17 24 17C21.2426 17 19 19.1031 19 21.6875C19 21.9361 19.093 22.1746 19.2584 22.3504C19.4239 22.5262 19.6483 22.625 19.8824 22.625C20.1164 22.625 20.3408 22.5262 20.5063 22.3504C20.6717 22.1746 20.7647 21.9361 20.7647 21.6875C20.7647 20.1367 22.2162 18.875 24 18.875C25.7838 18.875 27.2353 20.1367 27.2353 21.6875C27.2353 23.2383 25.7838 24.5 24 24.5C23.766 24.5 23.5416 24.5988 23.3761 24.7746C23.2106 24.9504 23.1176 25.1889 23.1176 25.4375V26.375C23.1176 26.6236 23.2106 26.8621 23.3761 27.0379C23.5416 27.2137 23.766 27.3125 24 27.3125C24.234 27.3125 24.4584 27.2137 24.6239 27.0379C24.7894 26.8621 24.8824 26.6236 24.8824 26.375V26.3016ZM23.183 29.1383C23.4248 28.9666 23.7091 28.875 24 28.875C24.39 28.875 24.7641 29.0396 25.0399 29.3326C25.3157 29.6257 25.4706 30.0231 25.4706 30.4375C25.4706 30.7465 25.3843 31.0486 25.2227 31.3056C25.0612 31.5625 24.8315 31.7628 24.5628 31.8811C24.2941 31.9993 23.9984 32.0303 23.7131 31.97C23.4278 31.9097 23.1658 31.7609 22.9601 31.5424C22.7545 31.3238 22.6144 31.0454 22.5577 30.7423C22.5009 30.4392 22.53 30.1251 22.6414 29.8396C22.7527 29.554 22.9411 29.31 23.183 29.1383Z"
        fill={fill}
        fillOpacity="0.8"
      />
    </svg>
  )
}
