import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../header';
import Menu from '../menu';

const Layout: FC = () => {
  return (
    <div className="pt-[50px] lg:pt-[70px] h-full">
      <Header />
      <div className="layout">
        <div className="h-full flex flex-col lg:flex-row gap-y-[40px] lg:gap-x-[100px] pt-[30px] lg:pt-[60px]">
          <Menu />
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;
