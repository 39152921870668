import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import Input from '../../common/input';
import { SearchIcon } from '../../icons/search';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { IClient, IConsultation } from '../../../store/types';
import { declOfNum } from '../../../utils/decOfNum';
import { getTime } from '../../../utils/getTime';
import dayjs from 'dayjs';
export interface IClientTab {
  activeClient: number | null;
  setActiveClient: React.Dispatch<React.SetStateAction<number | null>>;
  clients: IClient[];
  consultations: IConsultation[];
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
}

export const ActiveClients: FC<IClientTab> = ({
  clients,
  consultations,
  search,
  setSearch,
  activeClient,
  setActiveClient,
}) => {
  return (
    <div className="h-full flex flex-col lg:flex-row bg-grey5 p-[30px] rounded-[30px]">
      <div className="flex flex-col gap-5 lg:w-[285px] pb-[30px] lg:pb-0 lg:pr-[30px] border-b border-b-greyBase40 lg:border-b-[0] lg:border-r lg:border-r-greyBase40 shrink-0">
        <Input
          classNameLabel="text-xs text-[#585858]"
          className="border-none shadow-shadowJournalItem"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Поиск клиента"
          showError={false}
          icon={{
            icon: <SearchIcon />,
            type: 'suffix',
          }}
        />
        <div className="p-5 bg-white rounded-[20px]">
          <div className="flex justify-between mb-5">
            <div className="text-sm text-blackBase">Найдено:</div>
            <div className="text-sm text-blackBase">{`${clients.length} ${declOfNum(
              clients.length,
              ['клиент', 'клиента', 'клиентов']
            )}`}</div>
          </div>

          <div className="flex flex-col gap-5">
            {clients.map((el, idx) => (
              <div
                key={idx}
                onClick={() => setActiveClient(el.id)}
                className={classNames(
                  'p-[8px_30px] border border-grey5 rounded-[10px] cursor-pointer',
                  {
                    'text-white bg-redBase': activeClient === el.id,
                  }
                )}>
                {el.full_name}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="w-full pt-[30px] lg:pt-0 lg:pl-[30px]">
        <div className="flex justify-between mb-5">
          <div className="font-comfortaa font-semibold text-sm text-blackBase">Журнал клиента</div>
          <div className="text-sm text-blackBase">
            {`${consultations?.length} ${declOfNum(consultations?.length || 0, [
              'сессия',
              'сессии',
              'сессий',
            ])}`}
          </div>
        </div>
        <div className="w-full flex flex-col gap-5">
          {consultations?.map((el, idx) => (
            <div
              className="flex justify-between items-center shadow-shadowJournalItem rounded-[15px] p-5"
              key={idx}>
              <div className="text-blackBase">
                {`${dayjs(new Date(el.date)).format('DD.MM.YYYY')} ${getTime(
                  new Date(el.datetime_start)
                )}`}
              </div>
              <Link
                to={`${el.id}`}
                className="font-comfortaa font-semibold text-sm text-redBase1 hover:underline">
                Открыть
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
