import React, { useEffect, useRef, useState } from 'react';
import Input from '../components/common/input';
import { useLoginMutation } from '../services/auth.api';
import { checkEmailMask, checkEmailSymbol } from '../utils/validators';
import ConfirmAuth from '../components/confirm-auth';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { LANDING } from '../configs/api/api.config';
import { ArrowLeft } from '../components/icons/arrow-left';
import { useAppSelector } from '../hooks/redux';
import { selectUser } from '../store/auth.slice';

const Login = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const userData = useAppSelector(selectUser);
  const [login] = useLoginMutation();
  const [isInputAuthCode, setIsInputAuthCode] = useState(false);
  const refEnter = useRef<HTMLButtonElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (userData) {
      navigate('/profile');
    }
    // eslint-disable-next-line
  }, [userData]);

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (email.trim() === '') {
        toast.error('Введите Email');
        setError(' ');
        return;
      }
      const isEmail = checkEmailMask(email);
      if (!isEmail) {
        setError('Неверный формат Email');
        return;
      } else {
        setError('');
      }
      refEnter?.current?.click();
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isCorrect = checkEmailSymbol(e.target.value);
    if (!isCorrect) {
      setEmail(e.target.value);
      setError('');
    }
  };

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (email.trim() === '') {
      setError(' ');
      return;
    }
    const isEmail = checkEmailMask(e.target.value);
    if (!isEmail) {
      setError('Неверный формат Email');
    } else {
      setError('');
    }
  };

  const onLogin = async () => {
    if (email.trim() === '') {
      setError('Введите email');
      return;
    }
    const isEmail = checkEmailMask(email);
    if (!isEmail) {
      setError('Неверный формат Email');
      return;
    }
    try {
      const authCode = await login({ email }).unwrap();

      if (authCode?.result) {
        setIsInputAuthCode(true);
      }
    } catch (e: any) {
      console.log(e);
      if (e.status === 422) {
        if (e.data.detail === "Пользователь заблокирован") {
          toast.error("Ваш аккаунт заблокирован, чтобы разблокировать, обратитесь к администратору сервиса info@zingizi.pro")
        }
        setError(e.data.detail);
      }
    }
  };

  return (
    <div className="w-screen h-screen flex items-center justify-center bg-black px-[15px] pt-[50px] lg:pt-[80px]">
      {!isInputAuthCode ? (
        <div className="w-[420px] sm:w-[550px] max-h-[90%] overflow-y-auto rounded-[30px] bg-white small:p-[10px] p-[10px] md:p-[50px]">
          <div className="flex items-center font-comfortaa font-bold text-center text-2xl md:text-4xl small:text-2xl mb-2.5">
            <Link to={LANDING} className="group">
              <ArrowLeft className="w-[14px] h-[18px] md:w-[21px] md:h-[27px] fill-blackBase group-hover:fill-redBase" />
            </Link>

            <span className="grow text-center">Вход</span>
          </div>
          <div className="text-center sm:px-[30px] small:px-[0px] text-sm md:text-base text-greyBase mb-7 md:mb-10 small:mb-5">
            Войдите в аккаунт, чтобы получить доступ к личному кабинету
          </div>
          <div className="mb-7">
            <div className="text-xs text-greyBase mb-1">Email</div>
            <Input
              classNameWrap=""
              placeholder="Введите Email"
              error={error}
              value={email}
              onKeyDown={onKeyDown}
              onChange={onChange}
              onBlur={onBlur}
              autoComplete={'email'}
            />
          </div>
          <div className="mb-7 small:mb-5">
            <button
              ref={refEnter}
              className="font-comfortaa font-bold flex justify-center p-[15px] bg-redBase text-white md:text-xl w-full rounded-[10px] hover:bg-redBaseWithBlur20"
              onClick={onLogin}>
              Войти
            </button>
          </div>
          <div className="text-center">
            <div className="text-sm md:text-base text-greyBase">Нет аккаунта?</div>
            <a href="/registration" className="text-sm md:text-base text-redBase underline">
              Зарегистрироваться
            </a>
          </div>
        </div>
      ) : (
        <ConfirmAuth
          onBackToAuth={() => setIsInputAuthCode(false)}
          email={email}
          confirmCodex={false}
        />
      )}
    </div>
  );
};

export default Login;
