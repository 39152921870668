import React, { FC, useState } from 'react';
import { SquaresIcon } from '../../icons/squares';
import Button from '../button';
import classNames from 'classnames';

interface IProps {
  id: number;
  text: string;
  btnText?: string;
  cardName: string;
  isBlocked: boolean;
  selected: string[];
  setSelected: React.Dispatch<React.SetStateAction<string[]>>;
}

const ToolsCard: FC<IProps> = ({
  id,
  text,
  btnText = 'Голосовать',
  cardName,
  isBlocked,
  selected,
  setSelected,
}) => {
  const [hover, setHover] = useState(false);
  return (
    <>
      <div
        className="w-full h-[180px] flex items-center justify-center bg-white mb-[15px] border border-goldBase rounded-[10px] relative pt-[42px]"
        onMouseMove={() => setHover(true)}
        onMouseLeave={() => setHover(false)}>
        <div className="w-full h-[42px] flex items-center justify-center absolute top-0 left-0 p-[5px] bg-goldBase font-medium text-xs text-blackBase rounded-t-[10px] text-center">
          {cardName}
        </div>
        {!hover && <SquaresIcon fill="#C00000" needHover={false} />}
        {hover && <div className="text-xs text-blackBase px-[5px] text-center">{text}</div>}
      </div>
      <Button
        type="primary"
        className={classNames('!text-base !p-[6px]', {
          ['!bg-greyBase !border-greyBase !text-white']:
            selected.includes(`/api/v1/psy-tools/${id}`) || isBlocked,
        })}
        onClick={() => {
          if (isBlocked) return;
          setSelected((prev) => {
            const iri = `/api/v1/psy-tools/${id}`;
            if (prev.includes(iri)) {
              return prev.filter((el) => el !== iri);
            } else {
              return [...prev, iri];
            }
          });
        }}>
        {selected.includes(`/api/v1/psy-tools/${id}`) ? 'Мой выбор' : btnText}
      </Button>
    </>
  );
};

export default ToolsCard;
