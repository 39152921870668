import React, { FC, useState } from 'react';

const Tooltip: FC<{ children: React.ReactNode; text: string }> = ({ text, children }) => {
  const [hover, setHover] = useState(false);

  return (
    <div className="relative">
      <span onMouseMove={() => setHover(true)} onMouseLeave={() => setHover(false)}>
        {children}
      </span>
      {hover && (
        <span className="w-[200px] absolute top-[180%] -right-[30px] z-10 bg-white p-[6px_10px] rounded-[6px] text-sm text-blackBase shadow-formFeedback border border-[#dd7373]">
          <span className="w-[20px] h-[20px] absolute -top-[11px] right-[30px] z-[-1] rotate-[45deg] border-l border-t border-[#dd7373] bg-white"></span>
          {text}
        </span>
      )}
    </div>
  );
};

export default Tooltip;
