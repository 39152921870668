import React, { FC } from 'react';
import { IconProps } from '../../types/icon.types';

export const SmileIcon: FC<IconProps> = ({
  width = 52,
  height = 52,
  fill = 'white',
  className,
  opacity,
}) => {
  return (
    <svg
      className={`hover:cursor-pointer ${className}`}
      width={width}
      height={height}
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26 52C32.8956 52 39.5088 49.2607 44.3848 44.3848C49.2607 39.5088 52 32.8956 52 26C52 19.1044 49.2607 12.4912 44.3848 7.61522C39.5088 2.73928 32.8956 0 26 0C19.1044 0 12.4912 2.73928 7.61522 7.61522C2.73928 12.4912 0 19.1044 0 26C0 32.8956 2.73928 39.5088 7.61522 44.3848C12.4912 49.2607 19.1044 52 26 52ZM16.6664 33.0586C18.4844 35.1609 21.5922 37.375 26 37.375C30.4078 37.375 33.5156 35.1609 35.3336 33.0586C35.9227 32.3781 36.9484 32.307 37.6289 32.8961C38.3094 33.4852 38.3805 34.5109 37.7914 35.1914C35.5266 37.7914 31.5961 40.625 26 40.625C20.4039 40.625 16.4734 37.7914 14.2086 35.1914C13.6195 34.5109 13.6906 33.4852 14.3711 32.8961C15.0516 32.307 16.0773 32.3781 16.6664 33.0586ZM14.6656 21.125C14.6656 20.263 15.008 19.4364 15.6175 18.8269C16.227 18.2174 17.0537 17.875 17.9156 17.875C18.7776 17.875 19.6042 18.2174 20.2137 18.8269C20.8232 19.4364 21.1656 20.263 21.1656 21.125C21.1656 21.987 20.8232 22.8136 20.2137 23.4231C19.6042 24.0326 18.7776 24.375 17.9156 24.375C17.0537 24.375 16.227 24.0326 15.6175 23.4231C15.008 22.8136 14.6656 21.987 14.6656 21.125ZM34.1656 17.875C35.0276 17.875 35.8542 18.2174 36.4637 18.8269C37.0732 19.4364 37.4156 20.263 37.4156 21.125C37.4156 21.987 37.0732 22.8136 36.4637 23.4231C35.8542 24.0326 35.0276 24.375 34.1656 24.375C33.3037 24.375 32.477 24.0326 31.8675 23.4231C31.258 22.8136 30.9156 21.987 30.9156 21.125C30.9156 20.263 31.258 19.4364 31.8675 18.8269C32.477 18.2174 33.3037 17.875 34.1656 17.875Z"
        fill={fill}
        fillOpacity={opacity || '0.8'}
      />
    </svg>
  );
};
