import React, { FC, useState } from 'react';
import { IconProps } from '../../../types/icon.types';

export const Love: FC<IconProps> = ({
  width = 80,
  height = 80,
  fill = '#C8C8C8',
  className,
  stroke,
  needHover = true,
}) => {
  const [hover, setHover] = useState(false);

  return (
    <svg
      className={`hover:cursor-pointer ${className}`}
      width={width}
      height={height}
      onMouseMove={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        width="80"
        height="80"
        rx="40"
        fill={(hover && needHover) ? stroke || 'rgba(192, 0, 0, .5)' : fill}
        fillOpacity={(hover && needHover) && stroke ? '1' : '0.2'}
      />
      <path
        d="M40 65C53.8071 65 65 53.8071 65 40C65 26.1929 53.8071 15 40 15C26.1929 15 15 26.1929 15 40C15 53.8071 26.1929 65 40 65Z"
        fill="#FFCC4D"
      />
      <path
        d="M37.2224 38.5523C36.6405 38.5523 36.0988 38.1842 35.9044 37.6023C35.6224 36.7703 34.4905 34.3856 33.0558 34.3856C31.5808 34.3856 30.4335 36.9231 30.2072 37.6023C29.9641 38.3287 29.1822 38.7245 28.4502 38.4801C27.7224 38.2384 27.3294 37.4509 27.5724 36.7231C27.746 36.1995 29.3835 31.6064 33.0572 31.6064C36.7308 31.6064 38.3669 36.1995 38.5419 36.7231C38.7849 37.4509 38.3919 38.2384 37.6641 38.4801C37.5169 38.5301 37.3683 38.5523 37.2224 38.5523ZM51.1113 38.5523C50.5294 38.5523 49.9877 38.1842 49.7947 37.6023C49.5127 36.7703 48.3794 34.3856 46.9447 34.3856C45.4697 34.3856 44.321 36.9231 44.0947 37.6023C43.8516 38.3287 43.0724 38.7245 42.3377 38.4801C41.6113 38.2384 41.2169 37.4509 41.4599 36.7231C41.6335 36.1995 43.271 31.6064 46.9433 31.6064C50.6155 31.6064 52.253 36.1995 52.4266 36.7231C52.6697 37.4509 52.2766 38.2384 51.5488 38.4801C51.4044 38.5301 51.2572 38.5523 51.1113 38.5523ZM52.9655 43.737C52.7183 43.5134 52.3488 43.4995 52.0835 43.6967C52.0294 43.737 46.6363 47.7245 40.0002 47.7245C33.3808 47.7245 27.9697 43.737 27.9169 43.6967C27.6516 43.4995 27.2822 43.5162 27.0349 43.737C26.7891 43.9592 26.7335 44.3259 26.9044 44.6092C27.0835 44.9078 31.3724 51.8912 40.0002 51.8912C48.628 51.8912 52.9183 44.9078 53.096 44.6092C53.2669 44.3245 53.2127 43.9606 52.9655 43.737Z"
        fill="#664500"
      />
      <path
        d="M44.4849 20.4825C44.4849 20.8158 44.5266 21.138 44.5988 21.4491C45.1891 25.12 49.2711 28.9922 52.1961 30.0561C54.5086 29.2158 57.5377 26.6172 58.9988 23.7519C56.0974 20.363 52.3058 17.7602 47.9863 16.3047C45.9974 16.6616 44.4849 18.3908 44.4849 20.4825ZM34.0877 55.3866C34.1669 55.0505 34.2127 54.7005 34.2127 54.3394C34.2127 51.7936 32.1488 49.7297 29.603 49.7297C28.0572 49.7297 26.6947 50.4922 25.8572 51.6589C25.021 50.4936 23.6572 49.7297 22.1113 49.7297C20.3877 49.7297 18.9016 50.6866 18.1113 52.088C20.6447 56.6644 24.5697 60.3616 29.3169 62.6075C31.5905 60.7311 33.6655 58.0144 34.0877 55.3866ZM62.6405 50.6144C62.2488 49.295 61.0405 48.3269 59.5933 48.3269C58.5224 48.3269 57.578 48.8547 56.9988 49.663C56.4197 48.8547 55.4752 48.3269 54.4044 48.3269C52.6405 48.3269 51.2113 49.7561 51.2113 51.52C51.2113 51.77 51.2433 52.0116 51.2974 52.245C51.7238 54.8908 54.5586 57.6561 56.7266 58.5797C59.1933 56.3575 61.2141 53.6533 62.6405 50.6144Z"
        fill="#FFAC33"
      />
      <path
        d="M61.7011 19.5403C61.7011 17.1903 59.7955 15.2861 57.4469 15.2861C56.0205 15.2861 54.7622 15.9903 53.99 17.0667C53.2178 15.9903 51.9594 15.2861 50.533 15.2861C48.183 15.2861 46.2789 17.1903 46.2789 19.5403C46.2789 19.8736 46.3205 20.1959 46.3928 20.507C46.9844 24.1778 51.065 28.05 53.99 29.1139C56.915 28.05 60.9955 24.1778 61.5858 20.5084C61.6594 20.1959 61.7011 19.8736 61.7011 19.5403ZM31.9553 54.4556C31.9553 51.9097 29.8914 49.8459 27.3455 49.8459C25.7997 49.8459 24.4372 50.6084 23.5997 51.775C22.7636 50.6084 21.3997 49.8459 19.8539 49.8459C17.308 49.8459 15.2441 51.9097 15.2441 54.4556C15.2441 54.8167 15.29 55.1653 15.3678 55.5028C16.008 59.4792 20.4289 63.675 23.5983 64.8278C26.7678 63.6764 31.1886 59.4806 31.8289 55.5042C31.9094 55.1653 31.9553 54.8153 31.9553 54.4556ZM64.9275 51.2875C64.9275 49.5236 63.4983 48.0945 61.7344 48.0945C60.6636 48.0945 59.7191 48.6222 59.14 49.4306C58.5608 48.6222 57.6164 48.0945 56.5455 48.0945C54.7816 48.0945 53.3525 49.5236 53.3525 51.2875C53.3525 51.5375 53.3844 51.7792 53.4386 52.0125C53.8816 54.7667 56.9441 57.6736 59.14 58.4709C61.3344 57.6736 64.3983 54.7667 64.84 52.0125C64.8955 51.7792 64.9275 51.5375 64.9275 51.2875Z"
        fill="#DD2E44"
      />
    </svg>
  );
};
