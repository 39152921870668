import React from 'react';
import { ArrowLeft } from '../../components/icons/arrow-left';
import { Switch } from '../../components/common/switch';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { selectUser, setUserData } from '../../store/auth.slice';
import { toast } from 'react-toastify';
import { useChangeUserMutation } from '../../services/users.api';

const Dpdg = () => {
  const tool = 'dpdg';
  const dispatch = useAppDispatch();
  const userData = useAppSelector(selectUser);
  const [changeUser] = useChangeUserMutation();

  const handleCheck = async () => {
    try {
      if (!userData) return;
      if (userData?.active_psy_tools?.includes(tool)) {
        const arr = userData?.active_psy_tools.filter((el) => el !== tool);
        dispatch(setUserData({ ...userData, active_psy_tools: arr }));
        await changeUser({
          id: userData.id,
          active_psy_tools: arr,
        });
      } else {
        dispatch(
          setUserData({
            ...userData,
            active_psy_tools: [...userData?.active_psy_tools, tool],
          })
        );
        await changeUser({
          id: userData.id,
          active_psy_tools: [...userData?.active_psy_tools, tool],
        });
      }
      toast.success('Данные успешно сохранены');
    } catch (error) {
      toast.error('Произошла ошибка');
    }
  };

  return (
    <div className="pb-[80px]">
      <h2 className="flex items-center gap-[14px] font-comfortaa font-semibold text-xl text-blackBase mb-5">
        <Link to={'/tools'} className="group">
          <ArrowLeft className="fill-blackBase group-hover:fill-redBase" width={21} height={16} />
        </Link>
        <span>ДПДГ онлайн</span>
      </h2>
      <p className="text-sm text-greyBase mb-5">
        Инструмент используется сертифицированными для работы методом ДПДГ психологами для лечения
        ПТСР. Эффективность метода доказана клиническими исследованиями: было выявлено, что ДПДГ
        снижает связанные с ПТСР симптомы, а также тревожные симптомы и депрессивные состояния.
      </p>

      <div className="flex items-center gap-5 mb-[40px]">
        <Switch
          checked={userData?.active_psy_tools?.includes(tool)}
          setChecked={() => handleCheck()}
          label={'Использовать ДПДГ в видео-сессиях'}
        />
      </div>
      <div className="mb-5">
        <video
          className="w-full rounded-[20px]"
          src={`${process.env.REACT_APP_API_URL}/uploads/dpdg/ball1.mp4`}
          controls
        />
      </div>
      <div className="mb-[10px] text-sm text-greyBase">
        Хронометраж видео: <span className="font-medium text-sm">12 мин. 4 сек.</span>
      </div>

      <div className="mb-[10px] font-medium text-sm">
        Предусмотрен режим паузы и повторного воспроизведения.
      </div>
      <div className="flex items-center gap-5">
        <p className="text-sm text-greyBase">Типы движения:</p>
        <div>
          <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect width="30" height="30" rx="6" fill="#F7F7F7" />
            <path
              d="M6.46967 14.4697C6.17678 14.7626 6.17678 15.2374 6.46967 15.5303L11.2426 20.3033C11.5355 20.5962 12.0104 20.5962 12.3033 20.3033C12.5962 20.0104 12.5962 19.5355 12.3033 19.2426L8.06066 15L12.3033 10.7574C12.5962 10.4645 12.5962 9.98959 12.3033 9.6967C12.0104 9.40381 11.5355 9.40381 11.2426 9.6967L6.46967 14.4697ZM24.0303 15.5303C24.3232 15.2374 24.3232 14.7626 24.0303 14.4697L19.2574 9.6967C18.9645 9.40381 18.4896 9.40381 18.1967 9.6967C17.9038 9.98959 17.9038 10.4645 18.1967 10.7574L22.4393 15L18.1967 19.2426C17.9038 19.5355 17.9038 20.0104 18.1967 20.3033C18.4896 20.5962 18.9645 20.5962 19.2574 20.3033L24.0303 15.5303ZM7 15.75H17V14.25H7V15.75ZM17 15.75H23.5V14.25H17V15.75Z"
              fill="#C00000"
            />
          </svg>
        </div>
        <div>
          <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect width="30" height="30" rx="6" fill="#F7F7F7" />
            <path
              d="M14.7197 23.7803C15.0126 24.0732 15.4874 24.0732 15.7803 23.7803L20.5533 19.0074C20.8462 18.7145 20.8462 18.2396 20.5533 17.9467C20.2604 17.6538 19.7855 17.6538 19.4926 17.9467L15.25 22.1893L11.0074 17.9467C10.7145 17.6538 10.2396 17.6538 9.9467 17.9467C9.65381 18.2396 9.65381 18.7145 9.9467 19.0074L14.7197 23.7803ZM15.7803 6.21967C15.4874 5.92678 15.0126 5.92678 14.7197 6.21967L9.9467 10.9926C9.65381 11.2855 9.65381 11.7604 9.9467 12.0533C10.2396 12.3462 10.7145 12.3462 11.0074 12.0533L15.25 7.81066L19.4926 12.0533C19.7855 12.3462 20.2604 12.3462 20.5533 12.0533C20.8462 11.7604 20.8462 11.2855 20.5533 10.9926L15.7803 6.21967ZM16 23.25L16 13.25L14.5 13.25L14.5 23.25L16 23.25ZM16 13.25L16 6.75L14.5 6.75L14.5 13.25L16 13.25Z"
              fill="#C00000"
            />
          </svg>
        </div>
        <div>
          <svg width="30"
               height="30"
               viewBox="0 0 30 30"
               fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <rect width="30" height="30" rx="6" fill="#F7F7F7"/>
            <path d="M8 7.25C7.58579 7.25 7.25 7.58579 7.25 8L7.25 14.75C7.25 15.1642 7.58579 15.5 8 15.5C8.41421 15.5 8.75 15.1642 8.75 14.75L8.75 8.75L14.75 8.75C15.1642 8.75 15.5 8.41421 15.5 8C15.5 7.58579 15.1642 7.25 14.75 7.25L8 7.25ZM22 22.75C22.4142 22.75 22.75 22.4142 22.75 22L22.75 15.25C22.75 14.8358 22.4142 14.5 22 14.5C21.5858 14.5 21.25 14.8358 21.25 15.25L21.25 21.25L15.25 21.25C14.8358 21.25 14.5 21.5858 14.5 22C14.5 22.4142 14.8358 22.75 15.25 22.75L22 22.75ZM7.46967 8.53033L21.4697 22.5303L22.5303 21.4697L8.53033 7.46967L7.46967 8.53033Z" fill="#C00000"/>
          </svg>
        </div>
        <div>
          <svg width="30"
               height="30"
               viewBox="0 0 30 30"
               fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <rect width="30" height="30" rx="6" fill="#F7F7F7"/>
            <path d="M7.25 22C7.25 22.4142 7.58579 22.75 8 22.75H14.75C15.1642 22.75 15.5 22.4142 15.5 22C15.5 21.5858 15.1642 21.25 14.75 21.25H8.75V15.25C8.75 14.8358 8.41421 14.5 8 14.5C7.58579 14.5 7.25 14.8358 7.25 15.25V22ZM22.75 8C22.75 7.58579 22.4142 7.25 22 7.25H15.25C14.8358 7.25 14.5 7.58579 14.5 8C14.5 8.41421 14.8358 8.75 15.25 8.75H21.25V14.75C21.25 15.1642 21.5858 15.5 22 15.5C22.4142 15.5 22.75 15.1642 22.75 14.75V8ZM8.53033 22.5303L22.5303 8.53033L21.4697 7.46967L7.46967 21.4697L8.53033 22.5303Z"
                  fill="#C00000"/>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default Dpdg;
