import React, { FC, useState } from 'react';
import { IconProps } from '../../types/icon.types';

export const Arrow: FC<IconProps> = ({ width = 13, height = 12, fill = '#111026', className }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.16826 2.01338H11.1683C11.2594 2.01366 11.3487 2.03879 11.4266 2.08606C11.5045 2.13334 11.568 2.20096 11.6104 2.28165C11.6527 2.36235 11.6722 2.45306 11.6668 2.54402C11.6614 2.63498 11.6313 2.72275 11.5798 2.79788L7.07976 9.29788C6.89326 9.56738 6.44426 9.56738 6.25726 9.29788L1.75726 2.79788C1.70517 2.7229 1.67462 2.63509 1.66894 2.54398C1.66325 2.45286 1.68265 2.36194 1.72501 2.28107C1.76738 2.20021 1.83109 2.1325 1.90924 2.0853C1.98738 2.03811 2.07697 2.01323 2.16826 2.01338Z"
        fill="#111026"
      />
    </svg>
  );
};
