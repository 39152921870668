import { FC, useEffect, useRef } from 'react';

type Props = {
  value: string;
  onChange: (a: string) => void;
  placeholder?: string;
  label?: string;
};

export const TextArea: FC<Props> = ({ value, onChange, placeholder, label }) => {
  const ref = useRef<any>();

  useEffect(() => {
    const handleClick = (event: any) => {
      ref.current.style.height = ``;
      ref.current.style.height = `${ref.current.scrollHeight + 2}px`;
    };

    const element = ref.current;

    element.addEventListener('input', handleClick);

    return () => {
      element.removeEventListener('input', handleClick);
    };
  }, []);

  return (
    <div className="flex flex-col">
      <p className="text-xs text-greyBase mb-[5px]">{label}</p>
      <textarea
        ref={ref}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className="w-full resize-none box-border h-[100px] p-[13px_15px] lg:p-[13px_20px] text-blackBase text-sm lg:text-base placeholder:text-sm lg:placeholder:text-base border border-greyBase rounded-[10px] mb-5 lg:mb-6"
        placeholder={placeholder}
      />
    </div>
  );
};
