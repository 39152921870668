export class VideoJSPlayer {
  constructor(videoElement, player, options) {
    this.videoElement = videoElement;
    this.options = options;
    this.player = player;
    this.timeSyncInterval = null;
  }

  setSrc = (src) => {
    this.player.src(src);
  };

  play = () => {
    this.player
      .play()
      .then((_) => console.log('play'))
      .catch((error) => {
        console.log(error);
      });
    this.timeSyncInterval && clearInterval(this.timeSyncInterval);
    this.timeSyncInterval = setInterval(this.timeSync, 1000);
    this.videoElement.dispatchEvent(
      new CustomEvent('changedStatus', {
        detail: { status: 'play', time: this.player.currentTime() },
      })
    );
  };

  pause = () => {
    this.player.pause();
    this.timeSyncInterval && clearInterval(this.timeSyncInterval);
    this.videoElement.dispatchEvent(
      new CustomEvent('changedStatus', {
        detail: { status: 'pause', time: this.player.currentTime() },
      })
    );
  };

  stop = () => {
    this.player.pause();
    this.player.currentTime(0);
    this.timeSyncInterval && clearInterval(this.timeSyncInterval);
    this.videoElement.dispatchEvent(
      new CustomEvent('changedStatus', {
        detail: { status: 'stop' },
      })
    );
  };

  timeSync = () => {
    this.videoElement.dispatchEvent(
      new CustomEvent('changedStatus', {
        detail: { status: 'timeSync', time: this.player.currentTime() },
      })
    );
  };

  onChangedStatus = ({ status, time }) => {
    this.timeSyncInterval && clearInterval(this.timeSyncInterval);

    if (status === 'play') {
      this.player
        .play()
        .then((_) => console.log('play'))
        .catch((error) => {
          console.log(error);
        });
      this.player.currentTime(time);
    }

    if (status === 'pause') {
      this.player.pause();
    }

    if (status === 'stop') {
      this.player.pause();
      this.player.currentTime(0);
    }

    if (status === 'timeSync') {
      const currentTime = this.player.currentTime();

      if (currentTime + 0.5 < time) {
        this.player.currentTime(time);
      }
      if (this.player.paused() || this.player.ended()) {
        this.player
          .play()
          .then((_) => console.log('play'))
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  addChangedStatusListener = (handler) => {
    if (this.handler) {
      this.videoElement.removeEventListener('changedStatus', this.handler);
    }

    this.handler = handler;
    this.videoElement.addEventListener('changedStatus', handler);
  };

  deleteChangeStatusHandler = () => {
    if (this.handler) {
      this.videoElement.removeEventListener('changedStatus', this.handler);
    }
  };
}
