import { Dispatch, FC, SetStateAction } from 'react';

type Props = {
  setUnicornActive: Dispatch<SetStateAction<number>>;
  hoverUnicorn: number;
  unicornActive: number;
  setHoverUnicorn: Dispatch<SetStateAction<number>>;
};

export const UnicornReview: FC<Props> = ({
  setUnicornActive,
  setHoverUnicorn,
  unicornActive,
  hoverUnicorn,
}) => {
  return (
    <svg
      width="280"
      height="36"
      viewBox="0 0 280 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g
        clipPath="url(#clip0_0_1)"
        className="hover:cursor-pointer"
        onClick={() => setUnicornActive((state) => (state === 1 ? 0 : 1))}
        onMouseMove={() => setHoverUnicorn(1)}
        onMouseLeave={() => setHoverUnicorn(0)}>
        <path
          d="M22.6307 9.06338L23.4123 2.84179C23.432 2.70749 23.4094 2.57037 23.3475 2.44954C23.2857 2.3287 23.1877 2.23017 23.0672 2.16766L23.0426 2.1849C22.9097 2.1252 22.7605 2.11226 22.6193 2.14817C22.4782 2.18408 22.3533 2.26673 22.2651 2.38267L18.2888 7.97L22.6307 9.06338Z"
          fill="#FFFACF"
        />
        <path
          d="M23.3814 3.10963C23.2089 3.14823 23.0588 3.25363 22.964 3.40272L19.485 8.30177L18.2981 8.00799L22.2651 2.38135C22.3575 2.2656 22.487 2.18519 22.6318 2.15365C22.7765 2.1221 22.9277 2.14134 23.0599 2.20813C23.1804 2.27064 23.2784 2.36918 23.3403 2.49001C23.4021 2.61084 23.4247 2.74796 23.405 2.88226L23.3814 3.10963Z"
          fill="#FFFCE5"
        />
        <path
          d="M23.069 9.80267L24.1592 1.58154C24.1865 1.40405 24.1577 1.22322 24.0767 1.06422C23.9958 0.90522 23.8667 0.775977 23.7075 0.69449L23.6748 0.717466C23.4991 0.639805 23.3014 0.624007 23.1139 0.672646C22.9264 0.721285 22.7601 0.831495 22.6421 0.98533L17.3216 8.39678L23.069 9.80267Z"
          fill={unicornActive >= 1 ? '#DEC05E' : hoverUnicorn >= 1 ? '#111026' : '#B7B7B7'}
        />
        <path
          d="M13.7638 7.85869L4.60851 28.04C3.85443 27.27 3.16442 26.4396 2.54534 25.5573C2.21789 25.0908 1.89044 24.6242 1.6348 24.1164C1.33616 23.6093 1.2078 23.0199 1.26856 22.4345C1.32932 21.849 1.576 21.2985 1.9724 20.8635C2.06262 20.7638 2.15825 20.669 2.25887 20.5798C2.19529 20.2575 2.15887 19.9305 2.15 19.6022C2.10672 18.7861 2.26786 17.9722 2.61879 17.2342C2.96973 16.4962 3.49935 15.8576 4.15955 15.3763C4.09342 14.9206 4.07671 14.4591 4.10971 13.9998C4.15989 13.246 4.38551 12.5144 4.76856 11.8633C5.15161 11.2123 5.68149 10.6598 6.31588 10.25C6.33168 10.1289 6.33952 10.0225 6.36146 9.89712C6.48919 9.09251 6.81398 8.332 7.30687 7.68343C7.79976 7.03486 8.44542 6.5184 9.18626 6.18011C9.60088 5.18283 10.2605 4.3064 11.1039 3.63211C11.9473 2.95782 12.9473 2.5075 14.011 2.32291C18.7047 1.39924 21.1332 7.30079 18.014 9.16228C16.1973 10.2554 13.6498 9.3412 13.7638 7.85869Z"
          fill={unicornActive >= 1 ? '#DEC05E' : hoverUnicorn >= 1 ? '#111026' : '#B7B7B7'}
        />
        <path
          d="M10.4691 7.95517C9.72633 8.29066 9.07895 8.80632 8.58567 9.45539C8.0924 10.1045 7.76884 10.8664 7.64433 11.6722C7.61808 11.7914 7.61025 11.8978 7.59444 12.0189C6.96087 12.4339 6.43299 12.9913 6.05294 13.6466C5.67289 14.302 5.45118 15.0371 5.40551 15.7933C5.37251 16.2526 5.38922 16.7141 5.45535 17.1698C4.79514 17.6511 4.26552 18.2897 3.91459 19.0277C3.56365 19.7657 3.40251 20.5796 3.4458 21.3957C3.45467 21.724 3.49108 22.051 3.55467 22.3733C2.83985 23.0204 2.33253 23.8648 2.09668 24.8L1.70891 24.2475C1.36794 23.6951 1.22404 23.0435 1.30067 22.3987C1.37729 21.754 1.66992 21.1542 2.13087 20.6971L2.27815 20.5936C2.21457 20.2714 2.17815 19.9444 2.16928 19.616C2.12599 18.8 2.28713 17.986 2.63807 17.2481C2.98901 16.5101 3.51862 15.8715 4.17883 15.3902C4.1127 14.9345 4.09599 14.473 4.12899 14.0137C4.17859 13.2597 4.40396 12.5279 4.78707 11.8767C5.17017 11.2256 5.70036 10.6732 6.33516 10.2638C6.35096 10.1427 6.3588 10.0364 6.38074 9.91097C6.50846 9.10636 6.83326 8.34585 7.32615 7.69728C7.81904 7.04871 8.4647 6.53225 9.20554 6.19396C9.62016 5.19668 10.2798 4.32025 11.1232 3.64596C11.9666 2.97167 12.9666 2.52135 14.0303 2.33676C15.0658 2.10965 16.1483 2.26143 17.0814 2.76455C18.0145 3.26768 18.7364 4.08882 19.1161 5.07897C18.6005 4.62708 17.9859 4.30283 17.322 4.13242C16.658 3.96201 15.9633 3.95021 15.2938 4.09797C14.2294 4.28056 13.2285 4.73013 12.3848 5.40468C11.5411 6.07922 10.882 6.95671 10.4691 7.95517Z"
          fill={unicornActive >= 1 ? '#DEC05E' : hoverUnicorn >= 1 ? '#111026' : '#B7B7B7'}
        />
        <path
          d="M23.9303 20.8072L21.0046 20.1123L19.396 20.9669C20.8714 24.0741 21.6845 27.4542 21.7837 30.8925C21.7896 31.3187 21.7091 31.7417 21.5471 32.136C21.3851 32.5303 21.145 32.8878 20.8411 33.1868C20.5373 33.4858 20.1761 33.7201 19.7792 33.8758C19.3823 34.0314 18.9581 34.105 18.532 34.0923C13.224 33.9749 8.1761 31.7706 4.48204 27.9572L8.21853 15.6466C8.617 14.3714 9.12371 13.1326 9.73312 11.9437C10.4894 10.3617 11.6968 9.03872 13.2033 8.14137C14.539 7.38801 16.055 7.0133 17.5879 7.05758C19.1208 7.10186 20.6126 7.56346 21.9027 8.39267L28.9089 12.8756C29.4843 13.2504 29.9589 13.7608 30.2909 14.362C30.6228 14.9632 30.802 15.6368 30.8127 16.3235C30.8233 17.0101 30.6652 17.6889 30.352 18.3002C30.0389 18.9114 29.5804 19.4363 29.0169 19.8288C28.2851 20.3397 27.454 20.6908 26.5777 20.8593C25.7013 21.0279 24.7993 21.0101 23.9303 20.8072Z"
          fill="#E5F8FF"
        />
        <path
          d="M23.8763 11.2026C22.5797 10.3703 21.0799 9.90917 19.5397 9.86942C17.9994 9.82968 16.4778 10.2128 15.1401 10.9772C13.6368 11.8799 12.4317 13.2042 11.6742 14.7856C11.0683 15.9726 10.5705 17.2117 10.1866 18.4879L6.71248 29.9453C5.91969 29.3395 5.17394 28.6745 4.48162 27.956L8.21811 15.6454C8.61658 14.3702 9.12329 13.1314 9.7327 11.9424C10.489 10.3604 11.6964 9.0375 13.2029 8.14016C14.5386 7.3868 16.0546 7.01209 17.5875 7.05637C19.1204 7.10065 20.6122 7.56224 21.9023 8.39145L28.9084 12.8743C29.3818 13.1783 29.7878 13.576 30.1015 14.0429C30.4152 14.5099 30.6299 15.0361 30.7324 15.5892L23.8763 11.2026Z"
          fill="white"
        />
        <path
          d="M17.8441 13.2472C17.9209 13.3565 18.0283 13.4406 18.1529 13.4889C18.2775 13.5371 18.4136 13.5474 18.544 13.5183C18.6744 13.4892 18.7932 13.4221 18.8855 13.3254C18.9777 13.2288 19.0393 13.107 19.0623 12.9754C19.0853 12.8438 19.0688 12.7083 19.0148 12.5861C18.9609 12.4639 18.8719 12.3604 18.7591 12.2888C18.6463 12.2171 18.5148 12.1806 18.3813 12.1837C18.2477 12.1868 18.118 12.2294 18.0087 12.3062C17.8621 12.4091 17.7624 12.5661 17.7315 12.7426C17.7006 12.919 17.7411 13.1006 17.8441 13.2472Z"
          fill="#45413C"
          stroke="#45413C"
          strokeWidth="0.750388"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.735 16.6435C17.9723 16.9814 17.7548 17.5468 17.245 17.9048C16.7352 18.2628 16.1295 18.2753 15.8923 17.9375C15.6551 17.5997 15.8725 17.0343 16.3824 16.6763C16.8922 16.3183 17.4935 16.2996 17.735 16.6435Z"
          fill="#FFB0CA"
        />
        <path
          d="M23.9303 20.8072L21.5061 20.2314C21.1829 20.1546 20.8427 20.1983 20.5494 20.3542C19.9042 20.6969 19.6467 21.4853 19.9249 22.1609C21.066 24.9317 21.697 27.8899 21.7837 30.8925C21.7896 31.3187 21.7091 31.7417 21.5471 32.136C21.3851 32.5303 21.145 32.8878 20.8411 33.1868C20.5373 33.4858 20.1761 33.7201 19.7792 33.8758C19.3823 34.0314 18.9581 34.105 18.532 34.0923C13.224 33.9749 8.1761 31.7706 4.48204 27.9572L8.21853 15.6466C8.617 14.3714 9.12371 13.1326 9.73312 11.9437C10.4894 10.3617 11.6968 9.03872 13.2033 8.14137C14.539 7.38801 16.055 7.0133 17.5879 7.05758C19.1208 7.10186 20.6126 7.56346 21.9027 8.39267L28.9089 12.8756C29.4843 13.2504 29.9589 13.7608 30.2909 14.362C30.6228 14.9632 30.802 15.6368 30.8127 16.3235C30.8233 17.0101 30.6652 17.6889 30.352 18.3002C30.0389 18.9114 29.5804 19.4363 29.0169 19.8288C28.2851 20.3397 27.454 20.6908 26.5777 20.8593C25.7013 21.0279 24.7993 21.0101 23.9303 20.8072Z"
          fill={unicornActive >= 1 ? '#DEC05E' : hoverUnicorn >= 1 ? '#111026' : '#B7B7B7'}
        />
      </g>
      <g
        clipPath="url(#clip1_0_1)"
        className="hover:cursor-pointer"
        onClick={() => setUnicornActive((state) => (state === 2 ? 0 : 2))}
        onMouseMove={() => setHoverUnicorn(2)}
        onMouseLeave={() => setHoverUnicorn(0)}>
        <path
          d="M83.6302 9.06338L84.4118 2.84179C84.4315 2.70749 84.4089 2.57037 84.347 2.44954C84.2852 2.3287 84.1872 2.23017 84.0667 2.16766L84.0421 2.1849C83.9092 2.1252 83.7601 2.11226 83.6189 2.14817C83.4777 2.18408 83.3528 2.26673 83.2646 2.38267L79.2883 7.97L83.6302 9.06338Z"
          fill="#FFFACF"
        />
        <path
          d="M84.3809 3.10963C84.2084 3.14823 84.0583 3.25363 83.9635 3.40272L80.4845 8.30177L79.2976 8.00799L83.2646 2.38135C83.3571 2.2656 83.4865 2.18519 83.6313 2.15365C83.776 2.1221 83.9272 2.14134 84.0594 2.20813C84.1799 2.27064 84.2779 2.36918 84.3398 2.49001C84.4016 2.61084 84.4242 2.74796 84.4045 2.88226L84.3809 3.10963Z"
          fill="#FFFCE5"
        />
        <path
          d="M84.0685 9.80267L85.1587 1.58154C85.186 1.40405 85.1572 1.22322 85.0762 1.06422C84.9953 0.90522 84.8662 0.775977 84.707 0.69449L84.6743 0.717466C84.4986 0.639805 84.3009 0.624007 84.1134 0.672646C83.9259 0.721285 83.7596 0.831495 83.6416 0.98533L78.3211 8.39678L84.0685 9.80267Z"
          fill={unicornActive >= 2 ? '#DEC05E' : hoverUnicorn >= 2 ? '#111026' : '#B7B7B7'}
        />
        <path
          d="M74.7633 7.85869L65.608 28.04C64.8539 27.27 64.1639 26.4396 63.5448 25.5573C63.2174 25.0908 62.8899 24.6242 62.6343 24.1164C62.3357 23.6093 62.2073 23.0199 62.2681 22.4345C62.3288 21.849 62.5755 21.2985 62.9719 20.8635C63.0621 20.7638 63.1578 20.669 63.2584 20.5798C63.1948 20.2575 63.1584 19.9305 63.1495 19.6022C63.1062 18.7861 63.2674 17.9722 63.6183 17.2342C63.9692 16.4962 64.4989 15.8576 65.1591 15.3763C65.0929 14.9206 65.0762 14.4591 65.1092 13.9998C65.1594 13.246 65.385 12.5144 65.7681 11.8633C66.1511 11.2123 66.681 10.6598 67.3154 10.25C67.3312 10.1289 67.339 10.0225 67.361 9.89712C67.4887 9.09251 67.8135 8.332 68.3064 7.68343C68.7993 7.03486 69.4449 6.5184 70.1858 6.18011C70.6004 5.18283 71.26 4.3064 72.1034 3.63211C72.9469 2.95782 73.9468 2.5075 75.0105 2.32291C79.7042 1.39924 82.1327 7.30079 79.0135 9.16228C77.1968 10.2554 74.6493 9.3412 74.7633 7.85869Z"
          fill={unicornActive >= 2 ? '#DEC05E' : hoverUnicorn >= 2 ? '#111026' : '#B7B7B7'}
        />
        <path
          d="M71.4686 7.95517C70.7258 8.29066 70.0785 8.80632 69.5852 9.45539C69.0919 10.1045 68.7684 10.8664 68.6438 11.6722C68.6176 11.7914 68.6098 11.8978 68.594 12.0189C67.9604 12.4339 67.4325 12.9913 67.0525 13.6466C66.6724 14.302 66.4507 15.0371 66.405 15.7933C66.372 16.2526 66.3887 16.7141 66.4549 17.1698C65.7947 17.6511 65.265 18.2897 64.9141 19.0277C64.5632 19.7657 64.402 20.5796 64.4453 21.3957C64.4542 21.724 64.4906 22.051 64.5542 22.3733C63.8394 23.0204 63.332 23.8648 63.0962 24.8L62.7084 24.2475C62.3675 23.6951 62.2236 23.0435 62.3002 22.3987C62.3768 21.754 62.6694 21.1542 63.1304 20.6971L63.2777 20.5936C63.2141 20.2714 63.1777 19.9444 63.1688 19.616C63.1255 18.8 63.2866 17.986 63.6376 17.2481C63.9885 16.5101 64.5181 15.8715 65.1783 15.3902C65.1122 14.9345 65.0955 14.473 65.1285 14.0137C65.1781 13.2597 65.4035 12.5279 65.7866 11.8767C66.1697 11.2256 66.6999 10.6732 67.3347 10.2638C67.3505 10.1427 67.3583 10.0364 67.3802 9.91097C67.508 9.10636 67.8328 8.34585 68.3257 7.69728C68.8185 7.04871 69.4642 6.53225 70.205 6.19396C70.6197 5.19668 71.2793 4.32025 72.1227 3.64596C72.9661 2.97167 73.9661 2.52135 75.0298 2.33676C76.0653 2.10965 77.1478 2.26143 78.0809 2.76455C79.0141 3.26768 79.7359 4.08882 80.1156 5.07897C79.6 4.62708 78.9854 4.30283 78.3215 4.13242C77.6575 3.96201 76.9628 3.95021 76.2934 4.09797C75.2289 4.28056 74.228 4.73013 73.3843 5.40468C72.5406 6.07922 71.8815 6.95671 71.4686 7.95517Z"
          fill={unicornActive >= 2 ? '#DEC05E' : hoverUnicorn >= 2 ? '#111026' : '#B7B7B7'}
        />
        <path
          d="M84.9298 20.8072L82.0041 20.1123L80.3956 20.9669C81.8709 24.0741 82.684 27.4542 82.7832 30.8925C82.7891 31.3187 82.7086 31.7417 82.5466 32.136C82.3847 32.5303 82.1445 32.8878 81.8407 33.1868C81.5368 33.4858 81.1756 33.7201 80.7787 33.8758C80.3818 34.0314 79.9576 34.105 79.5315 34.0923C74.2235 33.9749 69.1756 31.7706 65.4816 27.9572L69.218 15.6466C69.6165 14.3714 70.1232 13.1326 70.7326 11.9437C71.4889 10.3617 72.6963 9.03872 74.2028 8.14137C75.5386 7.38801 77.0545 7.0133 78.5874 7.05758C80.1203 7.10186 81.6121 7.56346 82.9022 8.39267L89.9084 12.8756C90.4838 13.2504 90.9584 13.7608 91.2904 14.362C91.6223 14.9632 91.8015 15.6368 91.8122 16.3235C91.8229 17.0101 91.6647 17.6889 91.3515 18.3002C91.0384 18.9114 90.5799 19.4363 90.0164 19.8288C89.2846 20.3397 88.4535 20.6908 87.5772 20.8593C86.7008 21.0279 85.7988 21.0101 84.9298 20.8072Z"
          fill="#E5F8FF"
        />
        <path
          d="M84.8758 11.2026C83.5792 10.3703 82.0794 9.90917 80.5392 9.86942C78.999 9.82968 77.4773 10.2128 76.1396 10.9772C74.6363 11.8799 73.4312 13.2042 72.6737 14.7856C72.0679 15.9726 71.57 17.2117 71.1862 18.4879L67.712 29.9453C66.9192 29.3395 66.1735 28.6745 65.4811 27.956L69.2176 15.6454C69.6161 14.3702 70.1228 13.1314 70.7322 11.9424C71.4885 10.3604 72.6959 9.0375 74.2024 8.14016C75.5381 7.3868 77.0541 7.01209 78.587 7.05637C80.1199 7.10065 81.6117 7.56224 82.9018 8.39145L89.9079 12.8743C90.3813 13.1783 90.7874 13.576 91.1011 14.0429C91.4148 14.5099 91.6295 15.0361 91.7319 15.5892L84.8758 11.2026Z"
          fill="white"
        />
        <path
          d="M78.8436 13.2472C78.9204 13.3565 79.0279 13.4406 79.1524 13.4889C79.277 13.5371 79.4131 13.5474 79.5435 13.5183C79.6739 13.4892 79.7927 13.4221 79.885 13.3254C79.9773 13.2288 80.0388 13.107 80.0618 12.9754C80.0848 12.8438 80.0683 12.7083 80.0143 12.5861C79.9604 12.4639 79.8714 12.3604 79.7586 12.2888C79.6458 12.2171 79.5143 12.1806 79.3808 12.1837C79.2472 12.1868 79.1176 12.2294 79.0082 12.3062C78.8616 12.4091 78.7619 12.5661 78.731 12.7426C78.7001 12.919 78.7406 13.1006 78.8436 13.2472Z"
          fill="#45413C"
          stroke="#45413C"
          strokeWidth="0.750388"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M78.7345 16.6435C78.9718 16.9814 78.7543 17.5468 78.2445 17.9048C77.7347 18.2628 77.1291 18.2753 76.8918 17.9375C76.6546 17.5997 76.872 17.0343 77.3819 16.6763C77.8917 16.3183 78.493 16.2996 78.7345 16.6435Z"
          fill="#FFB0CA"
        />
        <path
          d="M84.9298 20.8072L82.5056 20.2314C82.1824 20.1546 81.8423 20.1983 81.5489 20.3542V20.3542C80.9037 20.6969 80.6462 21.4853 80.9244 22.1609C82.0655 24.9317 82.6965 27.8899 82.7832 30.8925C82.7891 31.3187 82.7086 31.7417 82.5466 32.136C82.3847 32.5304 82.1445 32.8878 81.8407 33.1868C81.5368 33.4858 81.1756 33.7201 80.7787 33.8758C80.3818 34.0314 79.9576 34.105 79.5315 34.0923C74.2235 33.9749 69.1756 31.7706 65.4816 27.9572L69.218 15.6466C69.6165 14.3714 70.1232 13.1326 70.7326 11.9437C71.4889 10.3617 72.6963 9.03872 74.2028 8.14137C75.5386 7.38801 77.0545 7.0133 78.5874 7.05758C80.1203 7.10186 81.6121 7.56346 82.9022 8.39267L89.9084 12.8756C90.4838 13.2504 90.9584 13.7608 91.2904 14.362C91.6223 14.9632 91.8015 15.6368 91.8122 16.3235C91.8229 17.0101 91.6647 17.6889 91.3515 18.3002C91.0384 18.9114 90.5799 19.4363 90.0164 19.8288C89.2846 20.3397 88.4535 20.6908 87.5772 20.8593C86.7008 21.0279 85.7988 21.0101 84.9298 20.8072Z"
          fill={unicornActive >= 2 ? '#DEC05E' : hoverUnicorn >= 2 ? '#111026' : '#B7B7B7'}
        />
      </g>
      <g
        clipPath="url(#clip2_0_1)"
        className="hover:cursor-pointer"
        onClick={() => setUnicornActive((state) => (state === 3 ? 0 : 3))}
        onMouseMove={() => setHoverUnicorn(3)}
        onMouseLeave={() => setHoverUnicorn(0)}>
        <path
          d="M144.631 9.06338L145.412 2.84179C145.432 2.70749 145.409 2.57037 145.348 2.44954C145.286 2.3287 145.188 2.23017 145.067 2.16766L145.043 2.1849C144.91 2.1252 144.761 2.11226 144.619 2.14817C144.478 2.18408 144.353 2.26673 144.265 2.38267L140.289 7.97L144.631 9.06338Z"
          fill="#FFFACF"
        />
        <path
          d="M145.381 3.10963C145.209 3.14823 145.059 3.25363 144.964 3.40272L141.485 8.30177L140.298 8.00799L144.265 2.38135C144.358 2.2656 144.487 2.18519 144.632 2.15365C144.776 2.1221 144.928 2.14134 145.06 2.20813C145.18 2.27064 145.278 2.36918 145.34 2.49001C145.402 2.61084 145.425 2.74796 145.405 2.88226L145.381 3.10963Z"
          fill="#FFFCE5"
        />
        <path
          d="M145.069 9.80267L146.159 1.58154C146.187 1.40405 146.158 1.22322 146.077 1.06422C145.996 0.90522 145.867 0.775977 145.707 0.69449L145.675 0.717466C145.499 0.639805 145.301 0.624007 145.114 0.672646C144.926 0.721285 144.76 0.831495 144.642 0.98533L139.322 8.39678L145.069 9.80267Z"
          fill={unicornActive >= 3 ? '#DEC05E' : hoverUnicorn >= 3 ? '#111026' : '#B7B7B7'}
        />
        <path
          d="M135.764 7.85869L126.609 28.04C125.854 27.27 125.164 26.4396 124.545 25.5573C124.218 25.0908 123.89 24.6242 123.635 24.1164C123.336 23.6093 123.208 23.0199 123.269 22.4345C123.329 21.849 123.576 21.2985 123.972 20.8635C124.063 20.7638 124.158 20.669 124.259 20.5798C124.195 20.2575 124.159 19.9305 124.15 19.6022C124.107 18.7861 124.268 17.9722 124.619 17.2342C124.97 16.4962 125.499 15.8576 126.16 15.3763C126.093 14.9206 126.077 14.4591 126.11 13.9998C126.16 13.246 126.386 12.5144 126.769 11.8633C127.152 11.2123 127.681 10.6598 128.316 10.25C128.332 10.1289 128.34 10.0225 128.361 9.89712C128.489 9.09251 128.814 8.332 129.307 7.68343C129.8 7.03486 130.445 6.5184 131.186 6.18011C131.601 5.18283 132.26 4.3064 133.104 3.63211C133.947 2.95782 134.947 2.5075 136.011 2.32291C140.705 1.39924 143.133 7.30079 140.014 9.16228C138.197 10.2554 135.65 9.3412 135.764 7.85869Z"
          fill={unicornActive >= 3 ? '#DEC05E' : hoverUnicorn >= 3 ? '#111026' : '#B7B7B7'}
        />
        <path
          d="M132.469 7.95517C131.726 8.29066 131.079 8.80632 130.586 9.45539C130.092 10.1045 129.769 10.8664 129.644 11.6722C129.618 11.7914 129.61 11.8978 129.594 12.0189C128.961 12.4339 128.433 12.9913 128.053 13.6466C127.673 14.302 127.451 15.0371 127.406 15.7933C127.373 16.2526 127.389 16.7141 127.455 17.1698C126.795 17.6511 126.266 18.2897 125.915 19.0277C125.564 19.7657 125.403 20.5796 125.446 21.3957C125.455 21.724 125.491 22.051 125.555 22.3733C124.84 23.0204 124.333 23.8648 124.097 24.8L123.709 24.2475C123.368 23.6951 123.224 23.0435 123.301 22.3987C123.377 21.754 123.67 21.1542 124.131 20.6971L124.278 20.5936C124.215 20.2714 124.178 19.9444 124.169 19.616C124.126 18.8 124.287 17.986 124.638 17.2481C124.989 16.5101 125.519 15.8715 126.179 15.3902C126.113 14.9345 126.096 14.473 126.129 14.0137C126.179 13.2597 126.404 12.5279 126.787 11.8767C127.17 11.2256 127.7 10.6732 128.335 10.2638C128.351 10.1427 128.359 10.0364 128.381 9.91097C128.508 9.10636 128.833 8.34585 129.326 7.69728C129.819 7.04871 130.465 6.53225 131.206 6.19396C131.62 5.19668 132.28 4.32025 133.123 3.64596C133.967 2.97167 134.967 2.52135 136.03 2.33676C137.066 2.10965 138.148 2.26143 139.081 2.76455C140.015 3.26768 140.736 4.08882 141.116 5.07897C140.601 4.62708 139.986 4.30283 139.322 4.13242C138.658 3.96201 137.963 3.95021 137.294 4.09797C136.229 4.28056 135.229 4.73013 134.385 5.40468C133.541 6.07922 132.882 6.95671 132.469 7.95517Z"
          fill={unicornActive >= 3 ? '#DEC05E' : hoverUnicorn >= 3 ? '#111026' : '#B7B7B7'}
        />
        <path
          d="M145.93 20.8072L143.005 20.1123L141.396 20.9669C142.871 24.0741 143.684 27.4542 143.784 30.8925C143.79 31.3187 143.709 31.7417 143.547 32.136C143.385 32.5303 143.145 32.8878 142.841 33.1868C142.537 33.4858 142.176 33.7201 141.779 33.8758C141.382 34.0314 140.958 34.105 140.532 34.0923C135.224 33.9749 130.176 31.7706 126.482 27.9572L130.219 15.6466C130.617 14.3714 131.124 13.1326 131.733 11.9437C132.489 10.3617 133.697 9.03872 135.203 8.14137C136.539 7.38801 138.055 7.0133 139.588 7.05758C141.121 7.10186 142.613 7.56346 143.903 8.39267L150.909 12.8756C151.484 13.2504 151.959 13.7608 152.291 14.362C152.623 14.9632 152.802 15.6368 152.813 16.3235C152.823 17.0101 152.665 17.6889 152.352 18.3002C152.039 18.9114 151.58 19.4363 151.017 19.8288C150.285 20.3397 149.454 20.6908 148.578 20.8593C147.701 21.0279 146.799 21.0101 145.93 20.8072Z"
          fill="#E5F8FF"
        />
        <path
          d="M145.876 11.2026C144.58 10.3703 143.08 9.90917 141.54 9.86942C139.999 9.82968 138.478 10.2128 137.14 10.9772C135.637 11.8799 134.432 13.2042 133.674 14.7856C133.068 15.9726 132.57 17.2117 132.187 18.4879L128.712 29.9453C127.92 29.3395 127.174 28.6745 126.482 27.956L130.218 15.6454C130.617 14.3702 131.123 13.1314 131.733 11.9424C132.489 10.3604 133.696 9.0375 135.203 8.14016C136.539 7.3868 138.055 7.01209 139.587 7.05637C141.12 7.10065 142.612 7.56224 143.902 8.39145L150.908 12.8743C151.382 13.1783 151.788 13.576 152.102 14.0429C152.415 14.5099 152.63 15.0361 152.732 15.5892L145.876 11.2026Z"
          fill="white"
        />
        <path
          d="M139.844 13.2472C139.921 13.3565 140.028 13.4406 140.153 13.4889C140.278 13.5371 140.414 13.5474 140.544 13.5183C140.674 13.4892 140.793 13.4221 140.885 13.3254C140.978 13.2288 141.039 13.107 141.062 12.9754C141.085 12.8438 141.069 12.7083 141.015 12.5861C140.961 12.4639 140.872 12.3604 140.759 12.2888C140.646 12.2171 140.515 12.1806 140.381 12.1837C140.248 12.1868 140.118 12.2294 140.009 12.3062C139.862 12.4091 139.762 12.5661 139.732 12.7426C139.701 12.919 139.741 13.1006 139.844 13.2472Z"
          fill="#45413C"
          stroke="#45413C"
          strokeWidth="0.750388"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M139.735 16.6435C139.972 16.9814 139.755 17.5468 139.245 17.9048C138.735 18.2628 138.13 18.2753 137.892 17.9375C137.655 17.5997 137.873 17.0343 138.382 16.6763C138.892 16.3183 139.493 16.2996 139.735 16.6435Z"
          fill="#FFB0CA"
        />
        <path
          d="M145.93 20.8072L143.506 20.2314C143.183 20.1546 142.843 20.1983 142.549 20.3542C141.904 20.6969 141.647 21.4853 141.925 22.1609C143.066 24.9317 143.697 27.8899 143.784 30.8925C143.79 31.3187 143.709 31.7417 143.547 32.136C143.385 32.5303 143.145 32.8878 142.841 33.1868C142.537 33.4858 142.176 33.7201 141.779 33.8758C141.382 34.0314 140.958 34.105 140.532 34.0923C135.224 33.9749 130.176 31.7706 126.482 27.9572L130.219 15.6466C130.617 14.3714 131.124 13.1326 131.733 11.9437C132.489 10.3617 133.697 9.03872 135.203 8.14137C136.539 7.38801 138.055 7.0133 139.588 7.05758C141.121 7.10186 142.613 7.56346 143.903 8.39267L150.909 12.8756C151.484 13.2504 151.959 13.7608 152.291 14.362C152.623 14.9632 152.802 15.6368 152.813 16.3235C152.823 17.0101 152.665 17.6889 152.352 18.3002C152.039 18.9114 151.58 19.4363 151.017 19.8288C150.285 20.3397 149.454 20.6908 148.578 20.8593C147.701 21.0279 146.799 21.0101 145.93 20.8072Z"
          fill={unicornActive >= 3 ? '#DEC05E' : hoverUnicorn >= 3 ? '#111026' : '#B7B7B7'}
        />
      </g>
      <g
        clipPath="url(#clip3_0_1)"
        className="hover:cursor-pointer"
        onClick={() => setUnicornActive((state) => (state === 4 ? 0 : 4))}
        onMouseMove={() => setHoverUnicorn(4)}
        onMouseLeave={() => setHoverUnicorn(0)}>
        <path
          d="M205.631 9.06338L206.412 2.84179C206.432 2.70749 206.409 2.57037 206.348 2.44954C206.286 2.3287 206.188 2.23017 206.067 2.16766L206.043 2.1849C205.91 2.1252 205.761 2.11226 205.619 2.14817C205.478 2.18408 205.353 2.26673 205.265 2.38267L201.289 7.97L205.631 9.06338Z"
          fill="#FFFACF"
        />
        <path
          d="M206.381 3.10963C206.209 3.14823 206.059 3.25363 205.964 3.40272L202.485 8.30177L201.298 8.00799L205.265 2.38135C205.358 2.2656 205.487 2.18519 205.632 2.15365C205.776 2.1221 205.928 2.14134 206.06 2.20813C206.18 2.27064 206.278 2.36918 206.34 2.49001C206.402 2.61084 206.425 2.74796 206.405 2.88226L206.381 3.10963Z"
          fill="#FFFCE5"
        />
        <path
          d="M206.069 9.80267L207.159 1.58154C207.187 1.40405 207.158 1.22322 207.077 1.06422C206.996 0.90522 206.867 0.775977 206.707 0.69449L206.675 0.717466C206.499 0.639805 206.301 0.624007 206.114 0.672646C205.926 0.721285 205.76 0.831495 205.642 0.98533L200.322 8.39678L206.069 9.80267Z"
          fill={unicornActive >= 4 ? '#DEC05E' : hoverUnicorn >= 4 ? '#111026' : '#B7B7B7'}
        />
        <path
          d="M196.764 7.85869L187.609 28.04C186.854 27.27 186.164 26.4396 185.545 25.5573C185.218 25.0908 184.89 24.6242 184.635 24.1164C184.336 23.6093 184.208 23.0199 184.269 22.4345C184.329 21.849 184.576 21.2985 184.972 20.8635C185.063 20.7638 185.158 20.669 185.259 20.5798C185.195 20.2575 185.159 19.9305 185.15 19.6022C185.107 18.7861 185.268 17.9722 185.619 17.2342C185.97 16.4962 186.499 15.8576 187.16 15.3763C187.093 14.9206 187.077 14.4591 187.11 13.9998C187.16 13.246 187.386 12.5144 187.769 11.8633C188.152 11.2123 188.681 10.6598 189.316 10.25C189.332 10.1289 189.34 10.0225 189.361 9.89712C189.489 9.09251 189.814 8.332 190.307 7.68343C190.8 7.03486 191.445 6.5184 192.186 6.18011C192.601 5.18283 193.26 4.3064 194.104 3.63211C194.947 2.95782 195.947 2.5075 197.011 2.32291C201.705 1.39924 204.133 7.30079 201.014 9.16228C199.197 10.2554 196.65 9.3412 196.764 7.85869Z"
          fill={unicornActive >= 4 ? '#DEC05E' : hoverUnicorn >= 4 ? '#111026' : '#B7B7B7'}
        />
        <path
          d="M193.469 7.95517C192.726 8.29066 192.079 8.80632 191.586 9.45539C191.092 10.1045 190.769 10.8664 190.644 11.6722C190.618 11.7914 190.61 11.8978 190.594 12.0189C189.961 12.4339 189.433 12.9913 189.053 13.6466C188.673 14.302 188.451 15.0371 188.406 15.7933C188.373 16.2526 188.389 16.7141 188.455 17.1698C187.795 17.6511 187.266 18.2897 186.915 19.0277C186.564 19.7657 186.403 20.5796 186.446 21.3957C186.455 21.724 186.491 22.051 186.555 22.3733C185.84 23.0204 185.333 23.8648 185.097 24.8L184.709 24.2475C184.368 23.6951 184.224 23.0435 184.301 22.3987C184.377 21.754 184.67 21.1542 185.131 20.6971L185.278 20.5936C185.215 20.2714 185.178 19.9444 185.169 19.616C185.126 18.8 185.287 17.986 185.638 17.2481C185.989 16.5101 186.519 15.8715 187.179 15.3902C187.113 14.9345 187.096 14.473 187.129 14.0137C187.179 13.2597 187.404 12.5279 187.787 11.8767C188.17 11.2256 188.7 10.6732 189.335 10.2638C189.351 10.1427 189.359 10.0364 189.381 9.91097C189.508 9.10636 189.833 8.34585 190.326 7.69728C190.819 7.04871 191.465 6.53225 192.206 6.19396C192.62 5.19668 193.28 4.32025 194.123 3.64596C194.967 2.97167 195.967 2.52135 197.03 2.33676C198.066 2.10965 199.148 2.26143 200.081 2.76455C201.015 3.26768 201.736 4.08882 202.116 5.07897C201.601 4.62708 200.986 4.30283 200.322 4.13242C199.658 3.96201 198.963 3.95021 198.294 4.09797C197.229 4.28056 196.229 4.73013 195.385 5.40468C194.541 6.07922 193.882 6.95671 193.469 7.95517Z"
          fill={unicornActive >= 4 ? '#DEC05E' : hoverUnicorn >= 4 ? '#111026' : '#B7B7B7'}
        />
        <path
          d="M206.93 20.8072L204.005 20.1123L202.396 20.9669C203.871 24.0741 204.684 27.4542 204.784 30.8925C204.79 31.3187 204.709 31.7417 204.547 32.136C204.385 32.5303 204.145 32.8878 203.841 33.1868C203.537 33.4858 203.176 33.7201 202.779 33.8758C202.382 34.0314 201.958 34.105 201.532 34.0923C196.224 33.9749 191.176 31.7706 187.482 27.9572L191.219 15.6466C191.617 14.3714 192.124 13.1326 192.733 11.9437C193.489 10.3617 194.697 9.03872 196.203 8.14137C197.539 7.38801 199.055 7.0133 200.588 7.05758C202.121 7.10186 203.613 7.56346 204.903 8.39267L211.909 12.8756C212.484 13.2504 212.959 13.7608 213.291 14.362C213.623 14.9632 213.802 15.6368 213.813 16.3235C213.823 17.0101 213.665 17.6889 213.352 18.3002C213.039 18.9114 212.58 19.4363 212.017 19.8288C211.285 20.3397 210.454 20.6908 209.578 20.8593C208.701 21.0279 207.799 21.0101 206.93 20.8072Z"
          fill="#E5F8FF"
        />
        <path
          d="M206.876 11.2026C205.58 10.3703 204.08 9.90917 202.54 9.86942C200.999 9.82968 199.478 10.2128 198.14 10.9772C196.637 11.8799 195.432 13.2042 194.674 14.7856C194.068 15.9726 193.57 17.2117 193.187 18.4879L189.712 29.9453C188.92 29.3395 188.174 28.6745 187.482 27.956L191.218 15.6454C191.617 14.3702 192.123 13.1314 192.733 11.9424C193.489 10.3604 194.696 9.0375 196.203 8.14016C197.539 7.3868 199.055 7.01209 200.587 7.05637C202.12 7.10065 203.612 7.56224 204.902 8.39145L211.908 12.8743C212.382 13.1783 212.788 13.576 213.102 14.0429C213.415 14.5099 213.63 15.0361 213.732 15.5892L206.876 11.2026Z"
          fill="white"
        />
        <path
          d="M200.844 13.2472C200.921 13.3565 201.028 13.4406 201.153 13.4889C201.278 13.5371 201.414 13.5474 201.544 13.5183C201.674 13.4892 201.793 13.4221 201.885 13.3254C201.978 13.2288 202.039 13.107 202.062 12.9754C202.085 12.8438 202.069 12.7083 202.015 12.5861C201.961 12.4639 201.872 12.3604 201.759 12.2888C201.646 12.2171 201.515 12.1806 201.381 12.1837C201.248 12.1868 201.118 12.2294 201.009 12.3062C200.862 12.4091 200.762 12.5661 200.732 12.7426C200.701 12.919 200.741 13.1006 200.844 13.2472Z"
          fill="#45413C"
          stroke="#45413C"
          strokeWidth="0.750388"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M200.735 16.6435C200.972 16.9814 200.755 17.5468 200.245 17.9048C199.735 18.2628 199.13 18.2753 198.892 17.9375C198.655 17.5997 198.873 17.0343 199.382 16.6763C199.892 16.3183 200.493 16.2996 200.735 16.6435Z"
          fill="#FFB0CA"
        />
        <path
          d="M206.93 20.8072L204.506 20.2314C204.183 20.1546 203.843 20.1983 203.549 20.3542C202.904 20.6969 202.647 21.4853 202.925 22.1609C204.066 24.9317 204.697 27.8899 204.784 30.8925C204.79 31.3187 204.709 31.7417 204.547 32.136C204.385 32.5303 204.145 32.8878 203.841 33.1868C203.537 33.4858 203.176 33.7201 202.779 33.8758C202.382 34.0314 201.958 34.105 201.532 34.0923C196.224 33.9749 191.176 31.7706 187.482 27.9572L191.219 15.6466C191.617 14.3714 192.124 13.1326 192.733 11.9437C193.489 10.3617 194.697 9.03872 196.203 8.14137C197.539 7.38801 199.055 7.0133 200.588 7.05758C202.121 7.10186 203.613 7.56346 204.903 8.39267L211.909 12.8756C212.484 13.2504 212.959 13.7608 213.291 14.362C213.623 14.9632 213.802 15.6368 213.813 16.3235C213.823 17.0101 213.665 17.6889 213.352 18.3002C213.039 18.9114 212.58 19.4363 212.017 19.8288C211.285 20.3397 210.454 20.6908 209.578 20.8593C208.701 21.0279 207.799 21.0101 206.93 20.8072Z"
          fill={unicornActive >= 4 ? '#DEC05E' : hoverUnicorn >= 4 ? '#111026' : '#B7B7B7'}
        />
      </g>
      <g
        clipPath="url(#clip4_0_1)"
        className="hover:cursor-pointer"
        onClick={() => setUnicornActive((state) => (state === 5 ? 0 : 5))}
        onMouseMove={() => setHoverUnicorn(5)}
        onMouseLeave={() => setHoverUnicorn(0)}>
        <path
          d="M266.63 9.06338L267.412 2.84179C267.432 2.70749 267.409 2.57037 267.347 2.44954C267.285 2.3287 267.187 2.23017 267.067 2.16766L267.042 2.1849C266.909 2.1252 266.76 2.11226 266.619 2.14817C266.478 2.18408 266.353 2.26673 266.265 2.38267L262.288 7.97L266.63 9.06338Z"
          fill="#FFFACF"
        />
        <path
          d="M267.381 3.10963C267.208 3.14823 267.058 3.25363 266.963 3.40272L263.485 8.30177L262.298 8.00799L266.265 2.38135C266.357 2.2656 266.487 2.18519 266.631 2.15365C266.776 2.1221 266.927 2.14134 267.059 2.20813C267.18 2.27064 267.278 2.36918 267.34 2.49001C267.402 2.61084 267.424 2.74796 267.404 2.88226L267.381 3.10963Z"
          fill="#FFFCE5"
        />
        <path
          d="M267.068 9.80267L268.159 1.58154C268.186 1.40405 268.157 1.22322 268.076 1.06422C267.995 0.90522 267.866 0.775977 267.707 0.69449L267.674 0.717466C267.499 0.639805 267.301 0.624007 267.113 0.672646C266.926 0.721285 266.76 0.831495 266.642 0.98533L261.321 8.39678L267.068 9.80267Z"
          fill={unicornActive >= 5 ? '#DEC05E' : hoverUnicorn >= 5 ? '#111026' : '#B7B7B7'}
        />
        <path
          d="M257.763 7.85869L248.608 28.04C247.854 27.27 247.164 26.4396 246.545 25.5573C246.217 25.0908 245.89 24.6242 245.634 24.1164C245.336 23.6093 245.207 23.0199 245.268 22.4345C245.329 21.849 245.576 21.2985 245.972 20.8635C246.062 20.7638 246.158 20.669 246.258 20.5798C246.195 20.2575 246.158 19.9305 246.15 19.6022C246.106 18.7861 246.267 17.9722 246.618 17.2342C246.969 16.4962 247.499 15.8576 248.159 15.3763C248.093 14.9206 248.076 14.4591 248.109 13.9998C248.159 13.246 248.385 12.5144 248.768 11.8633C249.151 11.2123 249.681 10.6598 250.315 10.25C250.331 10.1289 250.339 10.0225 250.361 9.89712C250.489 9.09251 250.813 8.332 251.306 7.68343C251.799 7.03486 252.445 6.5184 253.186 6.18011C253.6 5.18283 254.26 4.3064 255.103 3.63211C255.947 2.95782 256.947 2.5075 258.01 2.32291C262.704 1.39924 265.133 7.30079 262.014 9.16228C260.197 10.2554 257.649 9.3412 257.763 7.85869Z"
          fill={unicornActive >= 5 ? '#DEC05E' : hoverUnicorn >= 5 ? '#111026' : '#B7B7B7'}
        />
        <path
          d="M254.469 7.95517C253.726 8.29066 253.078 8.80632 252.585 9.45539C252.092 10.1045 251.768 10.8664 251.644 11.6722C251.618 11.7914 251.61 11.8978 251.594 12.0189C250.96 12.4339 250.433 12.9913 250.052 13.6466C249.672 14.302 249.451 15.0371 249.405 15.7933C249.372 16.2526 249.389 16.7141 249.455 17.1698C248.795 17.6511 248.265 18.2897 247.914 19.0277C247.563 19.7657 247.402 20.5796 247.445 21.3957C247.454 21.724 247.491 22.051 247.554 22.3733C246.839 23.0204 246.332 23.8648 246.096 24.8L245.708 24.2475C245.367 23.6951 245.224 23.0435 245.3 22.3987C245.377 21.754 245.669 21.1542 246.13 20.6971L246.278 20.5936C246.214 20.2714 246.178 19.9444 246.169 19.616C246.126 18.8 246.287 17.986 246.638 17.2481C246.989 16.5101 247.518 15.8715 248.178 15.3902C248.112 14.9345 248.095 14.473 248.128 14.0137C248.178 13.2597 248.403 12.5279 248.787 11.8767C249.17 11.2256 249.7 10.6732 250.335 10.2638C250.35 10.1427 250.358 10.0364 250.38 9.91097C250.508 9.10636 250.833 8.34585 251.326 7.69728C251.819 7.04871 252.464 6.53225 253.205 6.19396C253.62 5.19668 254.279 4.32025 255.123 3.64596C255.966 2.97167 256.966 2.52135 258.03 2.33676C259.065 2.10965 260.148 2.26143 261.081 2.76455C262.014 3.26768 262.736 4.08882 263.116 5.07897C262.6 4.62708 261.985 4.30283 261.321 4.13242C260.658 3.96201 259.963 3.95021 259.293 4.09797C258.229 4.28056 257.228 4.73013 256.384 5.40468C255.541 6.07922 254.881 6.95671 254.469 7.95517Z"
          fill={unicornActive >= 5 ? '#DEC05E' : hoverUnicorn >= 5 ? '#111026' : '#B7B7B7'}
        />
        <path
          d="M267.93 20.8072L265.004 20.1123L263.396 20.9669C264.871 24.0741 265.684 27.4542 265.783 30.8925C265.789 31.3187 265.709 31.7417 265.547 32.136C265.385 32.5303 265.145 32.8878 264.841 33.1868C264.537 33.4858 264.176 33.7201 263.779 33.8758C263.382 34.0314 262.958 34.105 262.531 34.0923C257.224 33.9749 252.176 31.7706 248.482 27.9572L252.218 15.6466C252.617 14.3714 253.123 13.1326 253.733 11.9437C254.489 10.3617 255.696 9.03872 257.203 8.14137C258.539 7.38801 260.055 7.0133 261.587 7.05758C263.12 7.10186 264.612 7.56346 265.902 8.39267L272.908 12.8756C273.484 13.2504 273.958 13.7608 274.29 14.362C274.622 14.9632 274.802 15.6368 274.812 16.3235C274.823 17.0101 274.665 17.6889 274.352 18.3002C274.038 18.9114 273.58 19.4363 273.016 19.8288C272.285 20.3397 271.454 20.6908 270.577 20.8593C269.701 21.0279 268.799 21.0101 267.93 20.8072Z"
          fill="#E5F8FF"
        />
        <path
          d="M267.876 11.2026C266.579 10.3703 265.079 9.90917 263.539 9.86942C261.999 9.82968 260.477 10.2128 259.14 10.9772C257.636 11.8799 256.431 13.2042 255.674 14.7856C255.068 15.9726 254.57 17.2117 254.186 18.4879L250.712 29.9453C249.919 29.3395 249.173 28.6745 248.481 27.956L252.218 15.6454C252.616 14.3702 253.123 13.1314 253.732 11.9424C254.489 10.3604 255.696 9.0375 257.202 8.14016C258.538 7.3868 260.054 7.01209 261.587 7.05637C263.12 7.10065 264.612 7.56224 265.902 8.39145L272.908 12.8743C273.381 13.1783 273.787 13.576 274.101 14.0429C274.415 14.5099 274.629 15.0361 274.732 15.5892L267.876 11.2026Z"
          fill="white"
        />
        <path
          d="M261.844 13.2472C261.92 13.3565 262.028 13.4406 262.152 13.4889C262.277 13.5371 262.413 13.5474 262.544 13.5183C262.674 13.4892 262.793 13.4221 262.885 13.3254C262.977 13.2288 263.039 13.107 263.062 12.9754C263.085 12.8438 263.068 12.7083 263.014 12.5861C262.96 12.4639 262.871 12.3604 262.759 12.2888C262.646 12.2171 262.514 12.1806 262.381 12.1837C262.247 12.1868 262.118 12.2294 262.008 12.3062C261.862 12.4091 261.762 12.5661 261.731 12.7426C261.7 12.919 261.741 13.1006 261.844 13.2472Z"
          fill="#45413C"
          stroke="#45413C"
          strokeWidth="0.750388"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M261.735 16.6435C261.972 16.9814 261.754 17.5468 261.245 17.9048C260.735 18.2628 260.129 18.2753 259.892 17.9375C259.655 17.5997 259.872 17.0343 260.382 16.6763C260.892 16.3183 261.493 16.2996 261.735 16.6435Z"
          fill="#FFB0CA"
        />
        <path
          d="M267.93 20.8072L265.506 20.2314C265.182 20.1546 264.842 20.1983 264.549 20.3542C263.904 20.6969 263.646 21.4853 263.924 22.1609C265.065 24.9317 265.697 27.8899 265.783 30.8925C265.789 31.3187 265.709 31.7417 265.547 32.136C265.385 32.5303 265.145 32.8878 264.841 33.1868C264.537 33.4858 264.176 33.7201 263.779 33.8758C263.382 34.0314 262.958 34.105 262.531 34.0923C257.224 33.9749 252.176 31.7706 248.482 27.9572L252.218 15.6466C252.617 14.3714 253.123 13.1326 253.733 11.9437C254.489 10.3617 255.696 9.03872 257.203 8.14137C258.539 7.38801 260.055 7.0133 261.587 7.05758C263.12 7.10186 264.612 7.56346 265.902 8.39267L272.908 12.8756C273.484 13.2504 273.958 13.7608 274.29 14.362C274.622 14.9632 274.802 15.6368 274.812 16.3235C274.823 17.0101 274.665 17.6889 274.352 18.3002C274.038 18.9114 273.58 19.4363 273.016 19.8288C272.285 20.3397 271.454 20.6908 270.577 20.8593C269.701 21.0279 268.799 21.0101 267.93 20.8072Z"
          fill={unicornActive >= 5 ? '#DEC05E' : hoverUnicorn >= 5 ? '#111026' : '#B7B7B7'}
        />
      </g>
      <defs>
        <clipPath id="clip0_0_1">
          <rect width="36" height="36" fill="white" />
        </clipPath>
        <clipPath id="clip1_0_1">
          <rect width="36" height="36" fill="white" transform="translate(61)" />
        </clipPath>
        <clipPath id="clip2_0_1">
          <rect width="36" height="36" fill="white" transform="translate(122)" />
        </clipPath>
        <clipPath id="clip3_0_1">
          <rect width="36" height="36" fill="white" transform="translate(183)" />
        </clipPath>
        <clipPath id="clip4_0_1">
          <rect width="36" height="36" fill="white" transform="translate(244)" />
        </clipPath>
      </defs>
    </svg>
  );
};
