import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from './api';

export const ideasApi = createApi({
  reducerPath: 'Ideas',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Ideas'],
  endpoints: (build) => ({
    setIdeas: build.mutation<void, string>({
      query: (text) => ({
        url: '/ideas',
        method: 'POST',
        body: JSON.stringify({ text })
      }),
    }),
  }),
});

export const { useSetIdeasMutation } = ideasApi;
