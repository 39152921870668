import React from 'react';
import { LogoIcon } from '../icons/logo';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { logout, selectUser } from '../../store/auth.slice';
import { Link, useNavigate } from 'react-router-dom';
import { LANDING } from '../../configs/api/api.config';

const Header = () => {
  const userData = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onLogout = () => {
    dispatch(logout());
  };

  const onLogin = () => {
    navigate('/');
  };

  return (
    <div className="fixed top-0 left-0 z-10 w-screen h-headerHeightMobile lg:h-headerHeight bg-white shadow-shadowHeader">
      <div className="w-full max-w-[1280px] h-full py-[8px] px-[15px] lg:py-2 mx-auto">
        <div className="h-full flex items-center justify-between">
          <div className="flex flex-col">
            <div className="text-xs md:text-base text-black font-bold leading-3 md:leading-4 mb-[3px] md:mb-[7px]">BETA</div>
            <Link to={LANDING}>
              <LogoIcon className="w-[80px] h-[15px] lg:w-[155px] lg:h-[30px]" />
            </Link>
          </div>
          <div>
            <button
              className="font-comfortaa font-medium flex justify-center py-[6px] lg:py-[10px] px-[30px] bg-blackBase text-white leading-4 w-full rounded-[10px] hover:text-redBase border-2 border-blackBase active:border-redBase active:text-white"
              onClick={!userData ? onLogin : onLogout}>
              {!userData ? 'Войти в ЛК' : 'Выйти'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
