import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from './api';

export const psyToolsApi = createApi({
  reducerPath: 'psyTools',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['PsyTools'],
  endpoints: (build) => ({
    getTools: build.query<any, void>({
      query: () => ({
        url: '/psy-tools',
      }),
    }),
  }),
});

export const { useLazyGetToolsQuery } = psyToolsApi;
