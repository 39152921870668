import React from 'react'
import { useAppSelector } from '../../hooks/redux'
import { selectCurrentToken } from '../../store/auth.slice'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

const PrivateRouter = () => {
  const token = useAppSelector(selectCurrentToken)
  const location = useLocation()
  return token ? <Outlet/> : <Navigate to={'/'} state={{from: location}} replace={true}/>
}

export default PrivateRouter