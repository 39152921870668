import { FC, useEffect, useState } from 'react';
import Button from '../../common/button';
import ModalWrap from '../../common/modal-wrap';
import { FormDataConsultations } from '../../../pages/Consultations';
import { CloseCrossBtn } from '../../icons/close-cross-btn';
import { getDateForFilter, getMyTimeZone, getTime, getTimeWithTZ } from '../../../utils/getTime';
import Input from '../../common/input';
import { useAppSelector } from '../../../hooks/redux';
import { selectUser } from '../../../store/auth.slice';
import { timezoneList } from '../../../assets/timezone';
import { IClient, IConsultation } from '../../../store/types';
import { capitalizeFirstLetter } from '../../../utils/textHelpers';
import { CustomSelect } from '../../common/select';
import { DatePickerCustom } from '../../common/datepicker';
import { TimePickerCustom } from '../../common/timepicker';
import classNames from 'classnames';
import axios from 'axios';
import { toast } from 'react-toastify';
import {
  useCreateConsultationMutation,
  useLazyGetAllConsultationsQuery,
  useLazyGetConsultationsQuery,
} from '../../../services/users.api';
import dayjs from 'dayjs';

type ConsultationFormProps = {
  isCreate: boolean;
  setIsCreate: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenCreateClient: React.Dispatch<React.SetStateAction<boolean>>;
  isEditOnCalendar: boolean;
  openCalendar: boolean;
  formData: FormDataConsultations;
  setFormData: React.Dispatch<React.SetStateAction<FormDataConsultations>>;
  consultationList: IConsultation[];
  allConsultationList: IConsultation[];
  clientsList: { id: number; name: string }[];
  clients: IClient[];
  closeForm: () => void;
  handleOpenSettings: () => void;
  handleChangeCons: (a: number, isEdit?: boolean) => void;
  setIsEditOnCalendar: React.Dispatch<React.SetStateAction<boolean>>;
  setConsultationList: React.Dispatch<React.SetStateAction<IConsultation[]>>;
};

export const ConsultationForm: FC<ConsultationFormProps> = ({
  isCreate,
  handleOpenSettings,
  closeForm,
  isEditOnCalendar,
  setIsEditOnCalendar,
  consultationList,
  allConsultationList,
  setConsultationList,
  clientsList,
  clients,
  openCalendar,
  formData,
  setFormData,
  handleChangeCons,
  setIsCreate,
  setOpenCreateClient,
}) => {
  const userData = useAppSelector(selectUser);
  const [getAllConsultations] = useLazyGetAllConsultationsQuery();
  const [getConsultations] = useLazyGetConsultationsQuery();
  const [createConsultation] = useCreateConsultationMutation();
  const [copy, setCopy] = useState(false);
  const [errorClient, setErrorClient] = useState('');
  const [requiredFields, setRequiredFields] = useState({
    date: false,
    time_start: false,
    time_end: false,
    tz: false,
  });

  const createClient = () => {
    setOpenCreateClient(true);
    setIsCreate(false);
  };

  const handleCopy = () => {
    const tzOffset = timezoneList.find((el) => el.name === formData.tz)?.offset;
    const curr = openCalendar
      ? allConsultationList.find((el) => el.id === formData.id)
      : consultationList.find((el) => el.id === formData.id);
    if (!curr) return;
    navigator.clipboard.writeText(
      `У Вас назначена встреча в ZiNGiZi.
Психолог: ${userData?.last_name || ''} ${userData?.first_name || ''} ${userData?.middle_name || ''}
${capitalizeFirstLetter(dayjs(formData.date).format('dddd'))}, ${dayjs(formData.date).format(
        'DD MMMM'
      )} · ${getTimeWithTZ(new Date(curr?.datetime_start), Number(tzOffset))}–${getTimeWithTZ(
        new Date(curr?.datetime_end),
        Number(tzOffset)
      )}
Часовой пояс: ${formData.tz}
Ссылка на видеовстречу: ${formData.link}`
    );
    setCopy(!copy);
  };

  useEffect(() => {
    if (!copy) return;
    const timer = setTimeout(() => {
      setCopy(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, [copy]);

  const getToken = () => localStorage.getItem('tokensZZ');

  const handleClose = () => {
    setErrorClient('');
    setRequiredFields({
      date: false,
      time_start: false,
      time_end: false,
      tz: false,
    });
    closeForm();
  };

  const handleSaveConsultation = async () => {
    const tzListName = timezoneList.map(i => i.name).includes(formData.tz)
    const dates = checkForSameDates(formData.date)
    const timesStart = checkTimesForToday(formData.time_start)

    if (
      formData.client === '' ||
      !formData.date ||
      (!formData.time_start || (formData.time_start && (dates && timesStart))) ||
      !formData.time_end ||
      !tzListName
    ) {
      setErrorClient(formData.client === '' ? 'Заполните поле Клиент' : '');
      setRequiredFields((val) => ({
        ...val,
        date: !formData.date,
        time_start: !formData.time_start || (dates && timesStart),
        time_end: !formData.time_end,
        tz: !tzListName,
      }));
      return;
    }

    try {
      if (formData?.id) {
        const data = {
          date: formData.date,
          datetime_start: formData.time_start,
          datetime_end: formData.time_end,
          client_timezone: formData.tz,
        };
        const res = await axios.put(
          `${process.env.REACT_APP_API_URL}/api/v1/consultations/${formData?.id}`,
          data,
          {
            headers: {
              // @ts-ignore
              Authorization: `Bearer ${JSON.parse(getToken()).token}`,
            },
          }
        );
        if (res.status >= 200 && res.status < 300) {
          const arr = consultationList.map((el) => {
            if (el.id === formData.id) {
              return res.data;
            } else {
              return el;
            }
          });
          setConsultationList(arr);
          setFormData({
            client: '',
            date: '',
            time_start: '',
            time_end: '',
            tz: '',
            link: '',
          });
          getAllConsultations();
          getConsultations({
            'date[before]': getDateForFilter(new Date()).end,
            'date[after]': getDateForFilter(new Date()).start,
            'sort[datetime_start]': 'asc',
          });
          setIsCreate(false);
          setIsEditOnCalendar(false);
          toast.success('Встреча успешно изменена');
        }
      } else {
        const client = clientsList.find((el) => el.name === formData.client);
        const data = {
          client_entity: `/api/v1/clients/${client?.id}`,
          date: formData.date,
          datetime_start: formData.time_start,
          datetime_end: formData.time_end,
          client_timezone: formData.tz,
          code: formData.link.split('/').at(-1),
          link: formData.link,
        };
        const res = await createConsultation(data);
        if (res?.data) {
          setFormData({
            client: '',
            date: '',
            time_start: '',
            time_end: '',
            tz: '',
            link: '',
          });
          setIsCreate(false);
          setIsEditOnCalendar(false);
          toast.success('Встреча успешно создана');
        }
        if (res?.error) {
          // @ts-ignore
          if (res?.error?.status === 422) {
            // @ts-ignore
            toast.error(res?.error.data?.detail);
          } else {
            toast.error('Не удалось создать консультацию');
          }
        }
      }
    } catch (error: any) {
      console.log(error);
      if (error?.status === 422) {
        toast.error(error.response.data.detail);
      }
    }
  };

  const checkForSameDates = (selectData: string) => {
    const now = new Date(dayjs().year(), dayjs().month(), dayjs().date())
    return now >= new Date(selectData + 'T00:00:00')
  }

  const checkTimesForToday = (selectTime: string) => {
    const selectTimeArr: string[] = selectTime.split(":")
    return dayjs().hour() === +selectTimeArr[0] && dayjs().add(1, 'minute').minute() > +selectTimeArr[1]
  }

  return (
    <ModalWrap isShow={isCreate}>
      {openCalendar && !isEditOnCalendar ? (
        <div className="flex flex-col gap-5 lg:gap-10 w-full max-w-[978px] rounded-[20px] sm:rounded-[40px] p-4 sm:p-6 md:p-10 bg-white">
          <div className="w-full flex justify-between items-center">
            <h2 className="font-comfortaa text-2xl text-[#111026] font-bold">
              {!formData.id || !formData.client ? '' : formData.client.split('-')[0]}
            </h2>
            <button className="cursor-pointer" onClick={() => handleClose()}>
              <CloseCrossBtn width={20} height={20} />
            </button>
          </div>
          <div className="flex flex-col gap-[10px]">
            <div className="flex gap-[15px]">
              <p className="text-[#70707c]">Дата встречи:</p>
              <p>{dayjs(formData.date).format('DD.MM.YYYY')}</p>
            </div>
            <div className="flex items-center justify-between max-w-[900px] flex-wrap gap-[10px]">
              <div className="flex gap-[15px]">
                <p className="text-[#70707c]">Ваше время:</p>
                <p>{`${getTime(new Date(formData.time_start))} - ${getTime(
                  new Date(formData.time_end)
                )} ${getMyTimeZone()}`}</p>
              </div>
              <div className="flex gap-[15px]">
                <p className="text-[#70707c]">Время у клиента:</p>
                <p>{`${getTimeWithTZ(
                  new Date(formData.time_start),
                  Number(formData.tzOffset)
                )} - ${getTimeWithTZ(new Date(formData.time_end), Number(formData.tzOffset))} ${
                  formData.tz
                }`}</p>
              </div>
            </div>
          </div>

          <div className="w-full flex flex-col gap-[15px]">
            <p className="font-comfortaa text-lg text-[#111026] font-semibold">Ссылка на встречу</p>
            <p className="text-sm text-[#585858]">
              Все сессии в ZiNGiZi являются видео-конференциями. Перед началом встречи проверьте
              доступы и качество видео и звука в{' '}
              <span
                onClick={() => handleOpenSettings()}
                className="cursor-pointer text-redBase hover:text-blackBase">
                настройках
              </span>
              .
            </p>
            <Input
              value={formData.link}
              className="!pl-[6px]"
              classNameWrap="[&>div>span]:!right-[6px] hidden sm:block"
              readOnly
              icon={{
                type: 'suffix',
                icon: (
                  <Button
                    onClick={() => {
                      handleCopy();
                    }}
                    disabled={copy}
                    className="w-full min-w-[228px] min-w-full !p-[5px_15px] !text-sm !font-medium !rounded-[10px] flex items-center gap-2.5">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M6 11C6 8.172 6 6.757 6.879 5.879C7.757 5 9.172 5 12 5H15C17.828 5 19.243 5 20.121 5.879C21 6.757 21 8.172 21 11V16C21 18.828 21 20.243 20.121 21.121C19.243 22 17.828 22 15 22H12C9.172 22 7.757 22 6.879 21.121C6 20.243 6 18.828 6 16V11Z"
                        stroke="white"
                        stroke-width="1.5"
                      />
                      <path
                        d="M6 19C5.20435 19 4.44129 18.6839 3.87868 18.1213C3.31607 17.5587 3 16.7956 3 16V10C3 6.229 3 4.343 4.172 3.172C5.343 2 7.229 2 11 2H15C15.7956 2 16.5587 2.31607 17.1213 2.87868C17.6839 3.44129 18 4.20435 18 5"
                        stroke="white"
                        stroke-width="1.5"
                      />
                    </svg>
                    {copy ? 'Скопировано' : 'Скопировать ссылку'}
                  </Button>
                ),
              }}
            />
            <div className="w-full flex sm:hidden flex-col items-center  border border-[#585858] rounded-[6px] py-[13px] px-[15px] gap-[15px]">
              <p className="text-sm break-all">{formData.link}</p>
              <Button
                onClick={() => {
                  handleCopy();
                }}
                disabled={copy}
                className="w-full max-w-[280px] !p-[5px_15px] !text-sm !font-medium !rounded-[10px] flex items-center gap-2.5">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6 11C6 8.172 6 6.757 6.879 5.879C7.757 5 9.172 5 12 5H15C17.828 5 19.243 5 20.121 5.879C21 6.757 21 8.172 21 11V16C21 18.828 21 20.243 20.121 21.121C19.243 22 17.828 22 15 22H12C9.172 22 7.757 22 6.879 21.121C6 20.243 6 18.828 6 16V11Z"
                    stroke="white"
                    stroke-width="1.5"
                  />
                  <path
                    d="M6 19C5.20435 19 4.44129 18.6839 3.87868 18.1213C3.31607 17.5587 3 16.7956 3 16V10C3 6.229 3 4.343 4.172 3.172C5.343 2 7.229 2 11 2H15C15.7956 2 16.5587 2.31607 17.1213 2.87868C17.6839 3.44129 18 4.20435 18 5"
                    stroke="white"
                    stroke-width="1.5"
                  />
                </svg>
                {copy ? 'Скопировано' : 'Скопировать ссылку'}
              </Button>
            </div>
          </div>

          <div className="w-full flex flex-col md+:flex-row lg:flex-col xl:flex-row justify-between gap-4 items-center">
            <Button
              type="grey"
              className="!text-base !p-0 h-[38px] flex items-center w-full xl:max-w-[152px] !font-semibold"
              onClick={() => {
                setIsEditOnCalendar(true);
                handleChangeCons(formData?.id || 0, true);
              }}>
              Изменить
            </Button>
            <Button
              onClick={() => window.open(`/meet/${formData.code}`, '_blank')}
              className="!text-base !p-0 h-[38px] flex items-center w-full xl:max-w-[286px] !font-semibold"
              type="black">
              Подключиться к встрече
            </Button>
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-5 lg:gap-10 w-full max-w-[978px] rounded-[40px] p-6 md:p-10 bg-white">
          <div className="w-full flex justify-between items-center">
            <h2 className="font-comfortaa text-2xl text-[#111026] font-bold">
              {!formData.id || !formData.client
                ? 'Создание встречи'
                : formData.client.split('-')[0]}
            </h2>
            <button className="cursor-pointer" onClick={() => handleClose()}>
              <CloseCrossBtn width={20} height={20} />
            </button>
          </div>
          {!formData?.id && (
            <div className="w-full lg:max-w-[587px]">
              <CustomSelect
                label="Клиент"
                selected={formData.client}
                items={clientsList.map((el) => el.name)}
                creatble
                error={errorClient}
                required
                showError={false}
                onCreate={() => createClient()}
                onChange={(a) => {
                  setFormData({ ...formData, client: a });
                  const cl = a.split('-')[0].trim();
                  const findClient = clients && clients.find((el) => el.full_name === cl);
                  if (findClient) {
                    setFormData((prev) => {
                      return { ...prev, tz: findClient.time_zone };
                    });
                  }
                  setErrorClient('');
                }}
              />
            </div>
          )}
          <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:flex flex-col lg:flex-row justify-between gap-[20px]">
            <div className="w-full lg:max-w-[259px]">
              <DatePickerCustom
                date={formData.date}
                label="Дата встречи"
                required
                setDate={(a) => {
                  console.log(a)
                  setFormData({ ...formData, date: a });
                  setRequiredFields((val) => ({ ...val, date: false }));

                  if (formData.time_start) {
                    setRequiredFields((val) => ({
                      ...val,
                      time_start: checkForSameDates(a) && checkTimesForToday(formData.time_start)
                    }));
                  } else {
                    setRequiredFields((val) => ({ ...val, time_start: false }))
                  }
                }}
                className={classNames({
                  '!border-redBase': requiredFields.date,
                })}
                disabledDate={(current) => {
                  return current && (current < dayjs().subtract(1, 'days') || current > dayjs().add(1, 'years'))
                }}
              />
            </div>
            <div className="lg:min-w-[145px]">
              <TimePickerCustom
                classNameLabel="text-xs text-[#585858]"
                value={formData.time_start}
                label="Начало встречи"
                required
                onTimeChange={(a) => {

                  setFormData({
                    ...formData,
                    time_start: a
                  });
                  setRequiredFields((val) => ({
                    ...val,
                    time_start: checkForSameDates(formData.date) && checkTimesForToday(a)
                  }));
                }}
                placeholder="00:00"
                onBlurHandler={() => {
                  if (formData.time_start.length === 0) return;
                  if (!formData.id) {
                    const timeArr = formData.time_start.split(':');
                    setFormData({
                      ...formData,
                      time_end: `${
                        Number(timeArr[0]) === 23
                          ? '00'
                          : Number(timeArr[0]) >= 1 &&
                            Number(timeArr[0]) < 10 &&
                            Number(timeArr[0]) !== 9
                          ? `0${Number(timeArr[0]) + 1}`
                          : Number(timeArr[0]) + 1
                      }:${timeArr[1] || '00'}`,
                    });
                    setRequiredFields((val) => ({ ...val, time_end: false }));
                  }
                }}
                error={requiredFields.time_start ? 'Ошибка' : ''}
                showError={false}
              />
            </div>
            <div className="lg:min-w-[145px]">
              <TimePickerCustom
                classNameLabel="text-xs text-[#585858]"
                value={formData.time_end}
                label="Конец встречи"
                required
                onTimeChange={(a) => {
                  setFormData({ ...formData, time_end: a });
                  setRequiredFields((val) => ({ ...val, time_end: false }));
                }}
                placeholder="00:00"
                error={requiredFields.time_start ? 'Ошибка' : ''}
                showError={false}
              />
            </div>

            <div className="w-full lg:max-w-[259px]">
              <CustomSelect
                selected={formData.tz}
                onChange={(a) => {
                  setFormData({ ...formData, tz: a })
                  setRequiredFields(val => ({...val, tz: false}))
                }}
                label="Часовой пояс клиента"
                items={timezoneList.map((el) => el.name)}
                error={requiredFields.tz ? 'Выберите из списка' : ''}
                showError={false}
              />
            </div>
          </div>

          {!formData.id ? (
            <p className="text-xs sm:text-sm text-[#585858]">
              После создания встречи: имя клиента, дата, время и ссылка на видео сессию появится в
              карточке встречи.
              <br /> Все сессии в ZiNGiZi являются видео-конференциями. Перед началом встречи
              проверьте доступы и качество видео и звука в{' '}
              <span
                className="text-redBase hover:text-blackBase cursor-pointer"
                onClick={() => handleOpenSettings()}>
                настройках
              </span>
              .
            </p>
          ) : (
            <div className="w-full flex flex-col gap-[15px]">
              <p className="font-comfortaa text-lg text-[#111026] font-semibold">
                Ссылка на встречу
              </p>
              <p className="text-sm text-[#585858]">
                Все сессии в ZiNGiZi являются видео-конференциями. Перед началом встречи проверьте
                доступы и качество видео и звука в{' '}
                <span
                  className="text-redBase hover:text-blackBase cursor-pointer"
                  onClick={() => handleOpenSettings()}>
                  настройках
                </span>
                .
              </p>
              <Input
                value={formData.link}
                className="!pl-[6px]"
                classNameWrap="[&>div>span]:!right-[6px] hidden sm:block"
                readOnly
                icon={{
                  type: 'suffix',
                  icon: (
                    <Button
                      onClick={() => {
                        handleCopy();
                      }}
                      disabled={copy}
                      className="w-full min-w-[228px] min-w-full !p-[5px_15px] !text-sm !font-medium !rounded-[10px] flex items-center gap-2.5">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M6 11C6 8.172 6 6.757 6.879 5.879C7.757 5 9.172 5 12 5H15C17.828 5 19.243 5 20.121 5.879C21 6.757 21 8.172 21 11V16C21 18.828 21 20.243 20.121 21.121C19.243 22 17.828 22 15 22H12C9.172 22 7.757 22 6.879 21.121C6 20.243 6 18.828 6 16V11Z"
                          stroke="white"
                          stroke-width="1.5"
                        />
                        <path
                          d="M6 19C5.20435 19 4.44129 18.6839 3.87868 18.1213C3.31607 17.5587 3 16.7956 3 16V10C3 6.229 3 4.343 4.172 3.172C5.343 2 7.229 2 11 2H15C15.7956 2 16.5587 2.31607 17.1213 2.87868C17.6839 3.44129 18 4.20435 18 5"
                          stroke="white"
                          stroke-width="1.5"
                        />
                      </svg>
                      {copy ? 'Скопировано' : 'Скопировать ссылку'}
                    </Button>
                  ),
                }}
              />
              <div className="w-full flex sm:hidden flex-col items-center  border border-[#585858] rounded-[6px] py-[13px] px-[15px] gap-[15px]">
                <p className="text-sm break-all">{formData.link}</p>
                <Button
                  onClick={() => {
                    handleCopy();
                  }}
                  disabled={copy}
                  className="w-full max-w-[280px] !p-[5px_15px] !text-sm !font-medium !rounded-[10px] flex items-center gap-2.5">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M6 11C6 8.172 6 6.757 6.879 5.879C7.757 5 9.172 5 12 5H15C17.828 5 19.243 5 20.121 5.879C21 6.757 21 8.172 21 11V16C21 18.828 21 20.243 20.121 21.121C19.243 22 17.828 22 15 22H12C9.172 22 7.757 22 6.879 21.121C6 20.243 6 18.828 6 16V11Z"
                      stroke="white"
                      stroke-width="1.5"
                    />
                    <path
                      d="M6 19C5.20435 19 4.44129 18.6839 3.87868 18.1213C3.31607 17.5587 3 16.7956 3 16V10C3 6.229 3 4.343 4.172 3.172C5.343 2 7.229 2 11 2H15C15.7956 2 16.5587 2.31607 17.1213 2.87868C17.6839 3.44129 18 4.20435 18 5"
                      stroke="white"
                      stroke-width="1.5"
                    />
                  </svg>
                  {copy ? 'Скопировано' : 'Скопировать ссылку'}
                </Button>
              </div>
            </div>
          )}

          <Button
            className="!ml-auto max-w-[160px] !text-base py-[6px] px-2.5"
            type="black"
            onClick={handleSaveConsultation}>
            Сохранить
          </Button>
        </div>
      )}
    </ModalWrap>
  );
};
