import React, { ChangeEvent, useEffect, useState } from 'react';
import ConfirmCodex from '../components/common/confirm-codex';
import Button from '../components/common/button';
import photo from '../assets/images/profile-photo-base.png';
import Input from '../components/common/input';
import Checkbox from '../components/common/checkbox';
import { ArrowTopRight } from '../components/icons/arrow-top-right';
import { QuestionsCircleIcon } from '../components/icons/question-circle';
import Tooltip from '../components/common/tooltip';
import { useChangeUserMutation } from '../services/users.api';
import { useAppDispatch, useAppSelector } from '../hooks/redux';
import { selectUser, setUserData } from '../store/auth.slice';
import { toast } from 'react-toastify';
import axios from 'axios';
import { baseUrl } from '../configs/api/api.config';
import Loader from '../components/common/loader';
import { PhoneInputWithMask } from '../components/common/phone-input-with-mask';
import { isValidPhoneNumber } from 'libphonenumber-js/min';

const Profile = () => {
  const userData = useAppSelector(selectUser);
  const [isShowCodex, setIsShowCodex] = useState(false);
  const [hoverCodex, setHoverCodex] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [avaPath, setAvaPath] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [agreeToReceiveEmails, setAgreeToReceiveEmails] = useState(false);
  const [changeUser] = useChangeUserMutation();
  const [isLoadingAva, setIsLoadingAva] = useState(false);
  // const [changeAvatar] = useChangeAvatarMutation()
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (userData) {
      setFirstName(userData.first_name);
      setMiddleName(userData.middle_name);
      setLastName(userData.last_name);
      setPhone(userData.phone);
      setEmail(userData.email);
      setAvaPath(userData.avatar);
      setAgreeToReceiveEmails(userData.agree_to_receive_emails);
    }
  }, [userData]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  const changeUserData = async () => {
    if (!firstName.trim() || !lastName.trim() || !isValidPhoneNumber(phone)) {
      setFirstNameError(!firstName.trim() ? 'Заполните поле' : '');
      setLastNameError(!lastName.trim() ? 'Заполните поле' : '');
      setPhoneError(!isValidPhoneNumber(phone) ? 'Заполните поле' : '');
      toast.warn('Необходимо заполнить обязательные поля');
      return;
    }
    if (
      !userData ||
      (userData?.first_name === firstName &&
        userData?.middle_name === middleName &&
        userData?.last_name === lastName &&
        userData?.phone === phone &&
        userData?.email === email &&
        userData?.avatar === avaPath &&
        agreeToReceiveEmails === userData.agree_to_receive_emails)
    ) {
      return;
    }

    try {
      const newUserData = await changeUser({
        id: userData.id,
        first_name: firstName,
        last_name: lastName,
        middle_name: middleName,
        avatar: avaPath,
        agree_to_receive_emails: agreeToReceiveEmails,
        phone,
      });
      dispatch(setUserData(newUserData?.data || userData));
      toast.success('Данные успешно сохранены');
    } catch (e) {
      console.log(e);
    }
  };

  const getToken = () => localStorage.getItem('tokensZZ');
  const onChangeAvatar = async (file: ChangeEvent<HTMLInputElement>) => {
    const files = file.target.files;
    const format = files?.[0]?.name.split('.').at(-1)?.toLowerCase();
    if (format && !['jpg', 'jpeg', 'png'].includes(format)) {
      toast.warn('Загрузите изображение в формате - .jpg, .jpeg, .png');
      return;
    }
    if (!format || !files || !userData) return;

    const bodyFormData = new FormData();
    bodyFormData.append('file', files?.[0]);
    setIsLoadingAva(true);
    try {
      // @ts-ignore
      console.log(JSON.parse(getToken()).token);
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/upload-avatar`,
        bodyFormData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            // @ts-ignore
            Authorization: `Bearer ${JSON.parse(getToken()).token}`,
          },
        }
      );
      console.log(res);
      if (res.status >= 200 && res.status < 300) {
        setAvaPath(res?.data?.path);
      }

      // const newData = await changeAvatar({file: files?.[0]})
      // console.log(newData)
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoadingAva(false);
    }
  };

  return (
    <div className="w-full h-full pb-[36px]">
      <div className="w-full flex flex-col xl:flex-row gap-[40px] lg:gap-[60px]">
        <div className="flex flex-col items-center gap-[30px] shrink-0">
          <div className="w-full max-w-[330px] h-[350px] lg:h-[320px] flex items-center justify-center rounded-[20px] overflow-hidden relative">
            {isLoadingAva ? (
              <Loader />
            ) : (
              <img
                src={avaPath ? baseUrl + avaPath : photo}
                className="w-full h-full object-contain hover:bg-blackWithOpacity40"
                alt=""
              />
            )}
            <div className="w-full h-full absolute t-0 l-0 hover:bg-blackWithOpacity40 flex items-center justify-center opacity-0 hover:opacity-100">
              <input
                id="ava"
                type="file"
                accept=".png, .jpg, .jpeg"
                onChange={onChangeAvatar}
                className="w-full h-full absolute t-0 l-0 hover:cursor-pointer opacity-0"
              />
              <label
                htmlFor="ava"
                className="font-semibold text-white text-lg underline text-redBase relative z-10 hover:text-redBase hover:cursor-pointer">
                Изменить
              </label>
            </div>
          </div>
          <Button
            type="bordered"
            className="flex justify-between items-center gap-[10px] py-[10px] group"
            onMouseMove={() => setHoverCodex(true)}
            onMouseLeave={() => setHoverCodex(false)}
            onClick={() => setIsShowCodex(true)}>
            <span className="text-base">Этический кодекс психолога</span>
            <ArrowTopRight fill={hoverCodex ? 'white' : '#C00000'} />
          </Button>
        </div>

        <div className="flex flex-col">
          <h2 className="text-lg lg:text-xl font-comfortaa font-bold mb-[20px] lg:mb-[30px]">
            Общая информация
          </h2>
          <div className="flex flex-col md:flex-row md:flex-wrap md:justify-between gap-[20px] mb-[20px] lg:mb-[13px]">
            <Input
              classNameWrap="md:w-[47%]"
              label="Фамилия *"
              showRequired
              placeholder="Введите фамилию"
              value={lastName}
              onChange={(e) => {
                if (e.target.value.length > 20) {
                  return;
                }
                setLastNameError('');
                setLastName(e.target.value);
              }}
              onBlur={(e) => {
                const value = e.target.value;
                setLastNameError(value ? '' : 'Заполните поле');
              }}
              error={lastNameError}
              showError={false}
            />
            <Input
              classNameWrap="md:w-[47%]"
              label="Имя *"
              placeholder="Введите имя"
              showRequired
              value={firstName}
              onChange={(e) => {
                if (e.target.value.length > 16) {
                  return;
                }
                setFirstNameError('');
                setFirstName(e.target.value);
              }}
              onBlur={(e) => {
                const value = e.target.value;
                setFirstNameError(value ? '' : 'Заполните поле');
              }}
              error={firstNameError}
              showError={false}
            />
            <Input
              classNameWrap="md:w-[47%]"
              label="Отчество"
              placeholder="Введите отчество"
              value={middleName}
              onChange={(e) => {
                if (e.target.value.length > 20) {
                  return;
                }
                setMiddleName(e.target.value);
              }}
            />
            <PhoneInputWithMask
              classNameWrap="md:w-[47%]"
              label="Телефон"
              classNameLabel="text-[#585858]"
              selected={phone}
              onChange={(e) => {
                setPhone(e ? e : '');
                if (!e) {
                  setPhoneError('Введите телефон');
                } else {
                  setPhoneError('');
                }
              }}
              onBlur={(e) => {
                if (!e) {
                  setPhoneError('Введите телефон');
                } else {
                  setPhoneError('');
                }
              }}
              error={phoneError}
              required={true}
              showRequired={true}
              showError={false}
            />
            <Input
              classNameWrap="w-full"
              label="Email"
              placeholder="Введите email"
              value={email}
              icon={{
                type: 'suffix',
                icon: (
                  <Tooltip text="Для смены email надо обратиться к администратору сервиса info@zingizi.pro">
                    <QuestionsCircleIcon needHover={false} />
                  </Tooltip>
                ),
              }}
              disabled
            />
          </div>
          <div className="mb-[30px]">
            <Checkbox
              label="Я согласен получать новости и рекламные рассылки"
              onChange={(e) => setAgreeToReceiveEmails(e.target.checked)}
              checked={agreeToReceiveEmails}
            />
          </div>
          <Button
            type="black"
            className="font-medium md:w-auto md:self-end !p-[10px_30px]"
            onClick={changeUserData}>
            Сохранить
          </Button>
        </div>
      </div>

      {(isShowCodex || !userData?.agree_with_code) && (
        <ConfirmCodex
          closeCodex={() => {
            setIsShowCodex(false);
          }}
        />
      )}
    </div>
  );
};

export default Profile;
