import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft } from '../../components/icons/arrow-left';
import { Switch } from '../../components/common/switch';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { selectQuestion } from '../../store/psyTools.slice';
import { selectUser, setUserData } from '../../store/auth.slice';
import { toast } from 'react-toastify';
import { useChangeUserMutation } from '../../services/users.api';

const Question = () => {
  const tool = 'new_question';
  const dispatch = useAppDispatch();
  const userData = useAppSelector(selectUser);
  const questions = useAppSelector(selectQuestion);
  const [changeUser] = useChangeUserMutation();

  const handleCheck = async () => {
    try {
      if (!userData) return;
      if (userData?.active_psy_tools?.includes(tool)) {
        const arr = userData?.active_psy_tools.filter((el) => el !== tool);
        dispatch(setUserData({ ...userData, active_psy_tools: arr }));
        await changeUser({
          id: userData.id,
          active_psy_tools: arr,
        });
      } else {
        dispatch(
          setUserData({
            ...userData,
            active_psy_tools: [...userData?.active_psy_tools, tool],
          })
        );
        await changeUser({
          id: userData.id,
          active_psy_tools: [...userData?.active_psy_tools, tool],
        });
      }
      toast.success('Данные успешно сохранены');
    } catch (error) {
      toast.error('Произошла ошибка');
    }
  };

  return (
    <div className="pb-[85px]">
      <h2 className="flex items-center gap-[14px] font-comfortaa font-semibold text-xl text-blackBase mb-5">
        <Link to={'/tools'} className="group">
          <ArrowLeft className="fill-blackBase group-hover:fill-redBase" width={21} height={16} />
        </Link>
        <span>«Подкинь вопрос» - шпаргалка для психолог</span>
      </h2>
      <p className="text-sm text-greyBase mb-5">
        Универсальные вопросы, на все случаи жизни, направят терапевтическую беседу на более
        глубокий уровень самопознания и размышлений. В случайном порядке, выпавший вопрос можно
        перефразировать, адаптировать к теме беседы или заменить на более подходящий, неограниченное
        количество раз.
      </p>
      <div className="flex items-center gap-5 mb-[40px]">
        <Switch
          checked={userData?.active_psy_tools?.includes(tool)}
          setChecked={() => handleCheck()}
          label={'Использовать Подкинь вопрос в видео-сессииях'}
        />
      </div>

      <div className="font-comfortaa font-bold text-blackBase mb-[10px]">Список вопросов</div>
      <ul className="list-disc pl-[30px] text-sm text-greyBase">
        {questions && questions.psy_tool_settings[0].value.map((question) => <li>{question}</li>)}
      </ul>
    </div>
  );
};

export default Question;
