import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Outlet, useLocation } from 'react-router-dom';
import { useLazyGetClientsQuery, useLazyGetConsultationsQuery } from '../services/users.api';
import { ActiveClients } from '../components/journal/active-clients';
import { ArchiveClients } from '../components/journal/archive-clients';

const tabs = [
  {
    id: 1,
    title: 'Активные клиенты',
  },
  {
    id: 2,
    title: 'Архивные клиенты',
  },
];

const Journal = () => {
  const [getClients, { data: clients }] = useLazyGetClientsQuery();
  const [getConsultations, { data: consultations }] = useLazyGetConsultationsQuery();
  const params = useLocation();
  const [search, setSearch] = useState('');
  const [activeTab, setActiveTab] = useState(1);
  const [activeClient, setActiveClient] = useState<number | null>(null);

  useEffect(() => {
    if (!clients) return;
    if (clients.length > 0) {
      setActiveClient(clients[0].id);
    } else {
      setActiveClient(null);
    }
  }, [clients]);

  useEffect(() => {
    if (activeClient) {
      getConsultations({
        client_entity: `/api/v1/clients/${activeClient}`,
        'sort[created_at]': 'desc',
      });
    }
  }, [activeClient]);

  const getTab = () => {
    switch (activeTab) {
      case 1:
        return (
          <ActiveClients
            consultations={activeClient ? consultations || [] : []}
            activeClient={activeClient}
            setActiveClient={setActiveClient}
            search={search}
            setSearch={setSearch}
            clients={clients || []}
          />
        );
      case 2:
        return (
          <ArchiveClients
            consultations={activeClient ? consultations || [] : []}
            activeClient={activeClient}
            setActiveClient={setActiveClient}
            search={search}
            setSearch={setSearch}
            clients={clients || []}
          />
        );

      default:
        return null;
    }
  };

  useEffect(() => {
    if (activeTab === 1) {
      getClients({ is_active: true, full_name: search });
    } else {
      getClients({ is_active: false, full_name: search });
    }
  }, [activeTab, search]);

  return (
    <div className="w-full">
      {params.pathname.split('/').length === 2 && (
        <div className="w-full pb-[85px]">
          <h2 className="font-comfortaa font-semibold text-lg sm:text-2xl text-blackBase mb-5">
            Терапевтический журнал
          </h2>

          <div className="flex gap-[15px] md:gap-[30px] border-b border-grey7 mb-10">
            {tabs.map((item) => (
              <div
                key={item.id}
                onClick={() => setActiveTab(item.id)}
                className={classNames(
                  'font-semibold text-sm md:text-base text-greyBase cursor-pointer p-[10px] mb-[-1px]',
                  {
                    'text-redBase border-b border-b-redBase': item.id === activeTab,
                  }
                )}>
                {item.title}
              </div>
            ))}
          </div>

          {getTab()}
        </div>
      )}

      <Outlet />
    </div>
  );
};

export default Journal;
