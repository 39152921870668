import React, { FC, useState } from 'react';
import { IconProps } from '../../types/icon.types';

export const CalendarIcon: FC<IconProps> = ({
  width = 60,
  height = 60,
  fill = '#fff',
  className,
}) => {
  return (
    <svg
      className={`${className}`}
      width={width}
      height={height}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.5 26.5625H52.5M42.5 6.5625V16.5625M17.5 6.5625V16.5625M42.5 11.5625H17.5C14.8478 11.5625 12.3043 12.6161 10.4289 14.4914C8.55357 16.3668 7.5 18.9103 7.5 21.5625V43.4375C7.5 46.0897 8.55357 48.6332 10.4289 50.5086C12.3043 52.3839 14.8478 53.4375 17.5 53.4375H42.5C45.1522 53.4375 47.6957 52.3839 49.5711 50.5086C51.4464 48.6332 52.5 46.0897 52.5 43.4375V21.5625C52.5 18.9103 51.4464 16.3668 49.5711 14.4914C47.6957 12.6161 45.1522 11.5625 42.5 11.5625Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
