import React, { FC, useState } from 'react';
import { IconProps } from '../../../types/icon.types';

export const Joy: FC<IconProps> = ({
  width = 80,
  height = 80,
  fill = '#C8C8C8',
  className,
  stroke,
  needHover = true,
}) => {
  const [hover, setHover] = useState(false);

  return (
    <svg
      className={`hover:cursor-pointer ${className}`}
      width={width}
      height={height}
      onMouseMove={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        width="80"
        height="80"
        rx="40"
        fill={(hover && needHover) ? stroke || 'rgba(192, 0, 0, .5)' : fill}
        fillOpacity={(hover && needHover) && stroke ? '1' : '0.2'}
      />
      <path
        d="M65 40C65 53.8069 53.8069 65 40 65C26.1931 65 15 53.8069 15 40C15 26.1931 26.1931 15 40 15C53.8069 15 65 26.1931 65 40Z"
        fill="#FFCC4D"
      />
      <path
        d="M54.3221 36.6833C54.079 36.4861 53.7304 36.4791 53.4749 36.6639C53.4582 36.6764 51.7235 37.9166 48.3332 37.9166C44.9443 37.9166 43.2082 36.6764 43.1943 36.6666C42.9401 36.4764 42.5901 36.4819 42.3457 36.6791C42.0985 36.8764 42.0151 37.2153 42.1429 37.5041C42.2262 37.6916 44.2262 42.0833 48.3332 42.0833C52.4415 42.0833 54.4401 37.6916 54.5235 37.5041C54.6512 37.2166 54.5679 36.8805 54.3221 36.6833ZM36.8082 36.6639C36.7915 36.6764 35.0582 37.9166 31.6665 37.9166C28.2762 37.9166 26.5415 36.6764 26.5276 36.6666C26.2735 36.4764 25.9249 36.4819 25.679 36.6791C25.4318 36.8764 25.3485 37.2153 25.4762 37.5041C25.5596 37.6916 27.5596 42.0833 31.6665 42.0833C35.7748 42.0833 37.7735 37.6916 37.8568 37.5041C37.9846 37.2166 37.9012 36.8805 37.6554 36.6833C37.411 36.4847 37.061 36.4791 36.8082 36.6639ZM55.279 33.0555C54.8554 33.0555 54.4401 32.8639 54.1665 32.5C50.5665 27.7 44.4985 28.85 44.4387 28.8611C43.679 29.0111 42.9554 28.5236 42.804 27.7722C42.654 27.0208 43.1415 26.2889 43.8929 26.1375C44.2137 26.0722 51.7499 24.6486 56.3874 30.8319C56.8485 31.4458 56.7235 32.3166 56.1096 32.7764C55.861 32.9653 55.5693 33.0555 55.279 33.0555ZM24.7207 33.0555C24.4318 33.0555 24.1387 32.9653 23.8887 32.7778C23.2749 32.318 23.1512 31.4472 23.611 30.8333C28.2485 24.6472 35.786 26.075 36.1054 26.1389C36.8582 26.2889 37.3457 27.0208 37.1943 27.7736C37.0443 28.5236 36.3137 29.0111 35.5637 28.8639C35.3054 28.8153 29.4026 27.7416 25.8332 32.5C25.561 32.8639 25.1429 33.0555 24.7207 33.0555ZM47.2971 47.7458C47.036 47.5916 46.6971 47.6264 46.4679 47.8291C46.454 47.8403 45.061 49.0278 39.9999 49.0278C34.9429 49.0278 33.5471 47.8416 33.5471 47.8416C33.3262 47.6194 32.986 47.5778 32.7137 47.7278C32.4429 47.8819 32.3068 48.1986 32.3832 48.5014C32.3971 48.5625 33.9721 54.5833 39.9999 54.5833C46.0276 54.5833 47.6026 48.5625 47.6179 48.5014C47.6915 48.2055 47.5568 47.9028 47.2971 47.7458Z"
        fill="#664500"
      />
    </svg>
  );
};
