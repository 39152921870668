import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth, IResponseType } from './api'

interface Post {
  id: number,
  image: string;
  title: string;
  content:  string;
  link: string;
  link_text: string;
  created_at: string;
  updated_at: string;
  excerpt: string;
  is_fixed: boolean;
}

export const postsApi = createApi({
  reducerPath: 'post',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Post'],
  endpoints: (build) => ({
    getPost: build.query<Post, string>({
      query: (id) => ({
        url: `/posts/${id}`,
      }),
    }),
    getPosts: build.query<
      {posts: Post[], totalItems: number},
      {page: number, itemsPerPage: number, 'sort[is_fixed]'?: 'asc' | 'desc', 'sort[created_at]'?: 'asc' | 'desc'}
    >({
      query: (params) => ({
        url: `/posts`,
        params,
        headers: {
          'Accept': 'application/ld+json'
        }
      }),
      transformResponse(data: IResponseType<Post>) {
        return {posts: data['hydra:member'], totalItems: data['hydra:totalItems']}
      }
    }),
  }),
});

export const {useLazyGetPostQuery, useGetPostsQuery} = postsApi;
