import React, { useState } from 'react';
import Input from '../components/common/input';
import { checkEmailMask } from '../utils/validators';
import Checkbox from '../components/common/checkbox';

const UIKit = () => {
  const [value, setValue] = useState('');
  const [error, setError] = useState('');
  const [valueRequiredFields, setValueRequiredFields] = useState('');
  const [errorRequiredFields, setErrorRequiredFields] = useState(false);

  return (
    <div className="flex gap-[50px] flex-wrap p-[50px]">
      <div className="border border-[#ff0f0f] p-[10px_10px_40px]">
        <div className="mb-[10px] text-greyBase">Один компонент в разных состояниях</div>
        <div className="flex gap-[10px] flex-wrap items-end">
          <Input classNameWrap="!w-[190px]" placeholder="Плейсхолдер" label="Email" />
          <Input
            classNameWrap="!w-[190px]"
            placeholder="Плейсхолдер"
            label="Required"
            value={valueRequiredFields}
            onChange={(e) => {
              setValueRequiredFields(e.target.value);

              if (e.target.value) {
                setErrorRequiredFields(false);
              }
            }}
            onBlur={(e) => {
              if (!e.target.value.trim()) {
                setErrorRequiredFields(true);
              } else {
                setErrorRequiredFields(false);
              }
            }}
            error={errorRequiredFields ? 'Обязательное поле' : ''}
            required={true}
          />
          <Input classNameWrap="!w-[190px]" placeholder="Плейсхолдер" />
          <Input classNameWrap="!w-[190px]" value="Текст" disabled={true} />
          <Input
            classNameWrap="!w-[300px]"
            label="Поле с валидацией email"
            placeholder="Поле с валидацией email"
            error={error}
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            onBlur={(e) => {
              const isEmail = checkEmailMask(e.target.value);
              if (!isEmail) {
                setError('Email в формате email@email.ru');
              } else {
                setError('');
              }
            }}
          />
        </div>
      </div>
      <div className="border border-[#ff0f0f] p-[10px]">
        <div className="flex flex-col gap-[10px] flex-wrap">
          <Checkbox label={'Я даю согласие на обработку персональных данных'} />
        </div>
      </div>
      <div className="border border-[#ff0f0f] p-[10px_10px_40px]">
        <div className="mb-[10px] text-greyBase">Один компонент в разных состояниях</div>
        <div className="flex flex-col gap-[10px] flex-wrap"></div>
      </div>
    </div>
  );
};

export default UIKit;
