import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

export interface IPsySetting {
  id: number;
  name: string;
  settings: any[];
  value: string[];
}

export interface ITool {
  id: number;
  label: string;
  name: string;
  psy_tool_settings: IPsySetting[];
  status: string;
  description: string;
}
interface PsyToolState {
  tools: ITool[];
}

const initialState: PsyToolState = {
  tools: [],
};

export const psyToolsSlice = createSlice({
  name: 'psyTools',
  initialState,
  reducers: {
    setTools: (state, action: PayloadAction<ITool[] | []>) => {
      state.tools = action.payload;
    },
  },
});

export const { setTools } = psyToolsSlice.actions;
export default psyToolsSlice.reducer;

export const selectEmotions = (state: RootState) =>
  state.psyToolsSlice.tools.filter((item) => item.name === 'emotions_detection')[0];
export const selectMetaforicCards = (state: RootState) =>
  state.psyToolsSlice.tools.filter((item) => item.name === 'metaforic_cards')[0];
export const selectQuestion = (state: RootState) =>
  state.psyToolsSlice.tools.filter((item) => item.name === 'new_question')[0];
export const selectDpdg = (state: RootState) =>
  state.psyToolsSlice.tools.filter((item) => item.name === 'dpdg')[0];
