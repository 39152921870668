import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './store'
import { IUser } from './types'

interface ITokens {
  token: string
  refresh_token: string
}

interface AuthState {
  token: string
  refreshToken: string
  isLoading: boolean
  error: string
  isAuth: boolean
  userData: IUser | null
}

const initialState: AuthState = {
  token: '',
  refreshToken: '',
  isLoading: false,
  error: '',
  isAuth: false,
  userData: null
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action: PayloadAction<ITokens>) => {
      const {token, refresh_token} = action.payload
      localStorage.setItem('tokensZZ', JSON.stringify({
        token: action.payload.token,
        refresh_token: action.payload.refresh_token
      }))

      state.token = token
      state.refreshToken = refresh_token
      state.isAuth = true
    },
    setUserData: (state, action: PayloadAction<IUser | null>) => {
      state.userData = action.payload
    },
    logout: (state) => {
      localStorage.removeItem('tokensZZ')
      state.isAuth = false
      state.token = ''
      state.refreshToken = ''
      state.userData = null
    }
  }
})

export const {setCredentials, logout, setUserData} = authSlice.actions
export default authSlice.reducer
export const selectCurrentToken = (state: RootState) => state.authSlice.token
export const selectUser = (state: RootState) => state.authSlice.userData
export const selectIsAuthUser = (state: RootState) => state.authSlice.isAuth















