import React, { FC, useState } from 'react'
import { IconProps } from '../../types/icon.types'

export const CloseCrossBtn: FC<IconProps> = ({width = 20, height = 20, fill = '#fff', className}) => {
  const [hover, setHover] = useState(false)

  return (
    <svg
      className={`cursor-pointer ${className}`}
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onMouseMove={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <path d="M1.6665 1.66602L18.3332 18.3327M1.6665 18.3327L18.3332 1.66602"
            stroke={hover ? '#111026' : '#C00000'}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"/>
    </svg>

  )
}
