import { FC, useEffect, useState } from 'react';
import { PauseIcon } from '../icons/pause';
import { RepeatIcon } from '../icons/repeat';

export const DpdgPanel: FC<{ playerRef: any; isDpdg: boolean }> = ({ playerRef, isDpdg }) => {
  const [isPause, setIsPause] = useState(false);

  const handleReloadVideo = () => {
    setIsPause(true);
    playerRef?.stop();
  };
  useEffect(() => {
    if (!isDpdg && isPause) {
      setIsPause(false);
    }
  }, [isDpdg, isPause]);

  return (
    <div className="absolute bottom-[15px] mx-auto right-0 left-0 rounded-[10px] gap-5 py-[15px] px-5 bg-[#11102680] max-w-[132px] flex">
      <button
        onClick={() => {
          if (isPause) {
            playerRef?.play();
            setIsPause(false);
            return;
          }
          setIsPause(true);
          playerRef?.pause();
        }}>
        <PauseIcon checked={isPause} />
      </button>
      <button
        onClick={() => {
          handleReloadVideo();
        }}>
        <RepeatIcon />
      </button>
    </div>
  );
};
