import React, { FC, useEffect, useState } from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import classNames from 'classnames';
import ru from 'react-phone-number-input/locale/ru.json';
interface Props {
  label?: string;
  classNameWrap?: string;
  classNameLabel?: string;
  error?: string;
  showError?: boolean;
  required?: boolean;
  selected: string;
  onChange: (a: string) => void;
  onBlur?: (e: string) => void;
  showRequired?: boolean;
  disabled?: boolean;
}

export const PhoneInputWithMask: FC<Props> = ({
  label = '',
  classNameWrap = '',
  classNameLabel = '',
  required,
  error = '',
  showError = true,
  selected,
  onChange,
  showRequired = false,
  onBlur,
  disabled,
}) => {
  const [focus, setFocus] = useState(false);

  const handleChange = (a: string) => {
    onChange(a);
  };

  return (
    <div
      className={classNames('w-full h-[40px] md:h-[48px] relative', classNameWrap, {
        '!h-auto': label,
      })}>
      {label && (
        <div className={classNames('mb-[5px] flex items-center gap-[5px]', classNameLabel)}>
          {label}
          {required ? ' *' : ''}
          {showRequired && <span className="text-[10px]">- обязательное поле</span>}
        </div>
      )}
      <div className="relative">
        <PhoneInput
          className={classNames(
            'w-full h-[40px] lg:h-[48px] p-[10px_15px] md:p-[13px_13px] border border-blackBase focus:border-2 rounded-[10px] text-blackBase [&>input]:outline-none [&>div>.PhoneInputCountrySelectArrow]:hidden',
            {
              'border-2': focus,
              'border-redBase': error,
              'bg-[#f5f5f5] !border-[#d9d9d9] text-[#c6cbcf]': disabled,
            }
          )}
          labels={ru}
          placeholder="+7 (999) 999 99 99"
          international
          defaultCountry="RU"
          disabled={disabled}
          // countryCallingCodeEditable={false}
          value={selected || undefined}
          onChange={(value) => {
            handleChange(String(value));
          }}
          onFocus={() => setFocus(true)}
          onBlur={(e) => {
            console.log(e);
            setFocus(false);
            // @ts-ignore
            onBlur && onBlur(e.target.value);
          }}
        />
      </div>
      <div className="text-sm text-redBase absolute top-[110%]">{showError && error}</div>
    </div>
  );
};
