import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import classNames from 'classnames'

const combinePages = '...'

const PaginationCustom = ({totalItems, itemsPerPage}: {totalItems: number, itemsPerPage: number}) => {
  const [searchParams] = useSearchParams();
  const paramValue = searchParams.get('page')
  const [page, setPage] = useState(paramValue ? +paramValue : 1)
  const [pageArr, setPageArr] = useState<number[]>([])
  const location = useLocation()
  const navigate = useNavigate()
  const totalPage = Math.ceil(totalItems / itemsPerPage)

  useEffect(() => {
    navigate(location.pathname + `?page=${page}&items=${itemsPerPage}`)
  }, [navigate, location.pathname, page, itemsPerPage])

  useEffect(() => {
    const newArr: number[] = []
    for (let i = 0; i < totalPage; i++) {
      newArr.push(i + 1)
    }
    setPageArr(newArr)
  }, [totalItems, totalPage])

  const getPage = (number: number) => {
    if (page === number || number === 1 || number === totalPage) {
      return number
    }
    if (
      (totalPage > 5 && page >= 4 && number !== (page - 1) && number !== (page + 1)) ||
      (page <= 2 && number >= 4) ||
      (page === 3 && number >= 5)
    ) {
      return combinePages
    }

    return number
  }

  return (
    <div className="flex justify-center gap-5 pb-[85px]">
      {
        pageArr
          .filter((item, idx) => {
            return getPage(item) > 0 || (getPage(item) === combinePages && getPage(pageArr[idx - 1]) !== combinePages)
          })
          .map((pageItem, idx) => (
          <div key={idx} className={classNames('w-[38px] h-[38px] flex items-center justify-center font-semibold p-[8px] border border-greyBase rounded-[10px]', {
            'bg-blackBase text-white': page === pageItem,
            'hover:bg-greyBase md:cursor-pointer': page !== pageItem && getPage(pageItem) !== combinePages,
          })}
               onClick={() => {
                 if (getPage(pageItem) === combinePages) return
                 setPage(pageItem)
               }}>
            {getPage(pageItem)}
          </div>
        ))
      }
    </div>
  )
}

export default PaginationCustom