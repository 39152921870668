import React, { useEffect } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import { VideoJSPlayer } from '../../utils/videojs';

export const VideoJS = ({ options, onReady }) => {
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);

  useEffect(() => {
    if (!playerRef.current) {
      const videoElement = document.createElement('video-js');

      videoElement.classList.add('vjs-big-play-centered');
      videoRef.current.appendChild(videoElement);

      const player = (playerRef.current = videojs(videoElement, options, () => {
        videojs.log('player is ready');
        const videoJsPlayer = new VideoJSPlayer(videoElement, player, options);
        onReady && onReady(videoJsPlayer);
      }));
    } else {
      const player = playerRef.current;

      player.autoplay(options.autoplay);
      player.src(options.sources);
    }
  }, [options, onReady]);

  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div className="h-full" data-vjs-player>
      <div
        ref={videoRef}
        className="h-full [&>video-js]:!h-full [&>video-js]:bg-grey1 [&>video-js]:!pt-0"
      />
    </div>
  );
};
