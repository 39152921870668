export const timezoneList = [
  {
    name: '(GMT +02:00) Калининград',
    offset: 2,
  },
  {
    name: '(GMT +03:00) Москва',
    offset: 3,
  },
  {
    name: '(GMT +03:00) Санкт-Петербург',
    offset: 3,
  },
  {
    name: '(GMT +03:00) Казань',
    offset: 3,
  },
  {
    name: '(GMT +03:00) Нижний Новгород',
    offset: 3,
  },
  {
    name: '(GMT +03:00) Симферополь',
    offset: 3,
  },
  {
    name: '(GMT +03:00) Волгоград',
    offset: 3,
  },
  {
    name: '(GMT +03:00) Ростов-на-Дону',
    offset: 3,
  },
  {
    name: '(GMT +03:00) Краснодар',
    offset: 3,
  },
  {
    name: '(GMT +03:00) Воронеж',
    offset: 3,
  },
  {
    name: '(GMT +03:00) Киров',
    offset: 3,
  },
  {
    name: '(GMT +04:00) Астрахань',
    offset: 4,
  },
  {
    name: '(GMT +04:00) Самара',
    offset: 4,
  },
  {
    name: '(GMT +04:00) Саратов',
    offset: 4,
  },
  {
    name: '(GMT +04:00) Ульяновск',
    offset: 4,
  },
  {
    name: '(GMT +05:00) Екатеринбург',
    offset: 5,
  },
  {
    name: '(GMT +05:00) Тюмень',
    offset: 5,
  },
  {
    name: '(GMT +05:00) Уфа',
    offset: 5,
  },
  {
    name: '(GMT +05:00) Башкортостан',
    offset: 5,
  },
  {
    name: '(GMT +05:00) Челябинск',
    offset: 5,
  },
  {
    name: '(GMT +05:00) Пермь',
    offset: 5,
  },
  {
    name: '(GMT +06:00) Омск',
    offset: 6,
  },
  {
    name: '(GMT +07:00) Барнаул',
    offset: 7,
  },
  {
    name: '(GMT +07:00) Красноярск',
    offset: 7,
  },
  {
    name: '(GMT +07:00) Новокузнецк',
    offset: 7,
  },
  {
    name: '(GMT +07:00) Новосибирск',
    offset: 7,
  },
  {
    name: '(GMT +07:00) Томск',
    offset: 7,
  },
  {
    name: '(GMT +08:00) Иркутск',
    offset: 8,
  },
  {
    name: '(GMT +09:00) Чита',
    offset: 9,
  },
  {
    name: '(GMT +09:00) Хандыга',
    offset: 9,
  },
  {
    name: '(GMT +09:00) Якутск',
    offset: 9,
  },
  {
    name: '(GMT +10:00) Владивосток',
    offset: 10,
  },
  {
    name: '(GMT +10:00) Усть-Нера',
    offset: 10,
  },
  {
    name: '(GMT +11:00) Магадан',
    offset: 11,
  },
  {
    name: '(GMT +11:00) Южно-Сахалинск',
    offset: 11,
  },
  {
    name: '(GMT +11:00) Южно-Сахалинск',
    offset: 11,
  },
  {
    name: '(GMT +11:00) Среднеколымск',
    offset: 11,
  },
  {
    name: '(GMT +12:00) Анадырь',
    offset: 12,
  },
  {
    name: '(GMT +12:00) Петропавловск-Камчатский',
    offset: 12,
  },
];
