export const checkEmailSymbol = (email: string) => {
  if (!email) {
    return ""
  }
  const ru = /[а-яё]+/i.test(email);
  return ru ? "Недопустимые символы" : ""
}

export const checkEmailMask = (email: string) => {
  if (!email) {
    return false
  }
  return email.match(/^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i);
}

export const checkNumber = (val: string) => {
  const reg = /^\+?\d*(\d*)?$/;
  if (reg.test(val)) {
    return ''
  }
  return 'Недопустимые символы'
}
