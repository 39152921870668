import React, { FC, useEffect, useState } from 'react'
import { IconProps } from '../../types/icon.types'

export const CopyIcon: FC<IconProps> = ({width = 16, height = 16, fill = '#fff', className}) => {
  const [hover, setHover] = useState(false)
  const [copy, setCopy] = useState(false)

  useEffect(() => {
    if (copy) {
      setTimeout(() => setCopy(false), 2000)
    }
  }, [copy])

  return (
    <svg
      className={`hover:cursor-pointer ${className}`}
      width={width}
      height={height}
      onMouseMove={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() => setCopy(true)}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 11C6 8.172 6 6.757 6.879 5.879C7.757 5 9.172 5 12 5H15C17.828 5 19.243 5 20.121 5.879C21 6.757 21 8.172 21 11V16C21 18.828 21 20.243 20.121 21.121C19.243 22 17.828 22 15 22H12C9.172 22 7.757 22 6.879 21.121C6 20.243 6 18.828 6 16V11Z"
        stroke="#C00000"
        strokeWidth="1.5"
        strokeOpacity={copy ? '0.4' : '1'}
      />
      <path
        d="M6 19C5.20435 19 4.44129 18.6839 3.87868 18.1213C3.31607 17.5587 3 16.7956 3 16V10C3 6.229 3 4.343 4.172 3.172C5.343 2 7.229 2 11 2H15C15.7956 2 16.5587 2.31607 17.1213 2.87868C17.6839 3.44129 18 4.20435 18 5"
        stroke="#C00000"
        strokeWidth="1.5"
        strokeOpacity={copy ? '0.4' : '1'}
      />
    </svg>
  )
}
