import React, { useState } from 'react';
import ModalWrap from '../components/common/modal-wrap';
import { CloseCrossBtn } from '../components/icons/close-cross-btn';
import { useGetPostsQuery } from '../services/posts.api';
import { Link, Outlet, useParams, useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import PaginationCustom from '../components/common/pagination';
import Button from '../components/common/button';
import { baseUrlFiles } from '../configs/api/api.config';
import { useSetIdeasMutation } from '../services/idea.api';

const COUNT_NEWS_ON_PAGE = 4;

const Info = () => {
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page');
  const items = searchParams.get('items');
  const params = useParams();
  const roomName = params.id;
  const [showModal, setShowModal] = useState(false);
  const { data } = useGetPostsQuery({
    page: page ? +page : 1,
    itemsPerPage: items ? +items : COUNT_NEWS_ON_PAGE,
    'sort[is_fixed]': 'desc',
    'sort[created_at]': 'desc',
  });
  const [text, setText] = useState('');
  const [sendIdeas] = useSetIdeasMutation();

  if (roomName) {
    return <Outlet />;
  }

  const sendIdeasToServer = async () => {
    if (!text.trim()) {
      return;
    }
    try {
      const res = await sendIdeas(text);

      if (res) {
        toast.success('Ваше предложение отправлено');
        setText('');
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <div className="w-full pb-[85px]">
        <h2 className="font-comfortaa font-semibold text-lg sm:text-2xl text-blackBase mb-5">
          Информация
        </h2>

        <Button
          type="primary"
          onClick={() => setShowModal(true)}
          className="!text-sm p-[8px_30px] mb-[20px]">
          Предложить идею или улучшения существующего инструмента
        </Button>

        <div className="grid grid-cols-12 gap-[20px] mb-[40px]">
          {data?.posts &&
            data?.posts?.length > 0 &&
            data.posts.map((item) => (
              <div
                key={item.id}
                className="flex flex-col col-span-12 sm:col-span-6 min-h-[220px] p-5 bg-pink1 rounded-[20px]">
                <div className="flex flex-col md:flex-row gap-[10px] pb-[7px]">
                  <div className="md:w-1/2 h-[166px] flex">
                    {item?.image && (
                      <img
                        src={baseUrlFiles + item.image}
                        className="w-full h-full object-cover"
                        alt=""
                      />
                    )}
                  </div>
                  <h3 className="md:w-1/2 font-comfortaa font-bold text-[28px] text-blackBase mb-4">
                    {item.title}
                  </h3>
                </div>
                <div className="mb-[3px]">
                  <div className="min-h-[48px] font-semibold text-sm text-greyBase break-words">
                    {item?.excerpt}
                  </div>
                  <Link
                    to={`${item.id}`}
                    className="font-medium text-sm text-redBase1 hover:underline">
                    Читать подробнее...
                  </Link>
                </div>
                <div className="mt-auto">
                  <Link
                    to={item.link}
                    target={'_blank'}
                    className="font-medium text-sm text-redBase hover:underline">
                    {item.link_text}
                  </Link>
                  <div className="w-max p-[5px_15px] bg-goldBase font-medium text-sm rounded-[5px] mt-[7px]">
                    {dayjs(item.created_at).format('DD.MM.YYYY')}
                  </div>
                </div>
              </div>
            ))}
        </div>

        {data?.totalItems && data.totalItems > (items ? +items : COUNT_NEWS_ON_PAGE) && (
          <PaginationCustom
            totalItems={data.totalItems}
            itemsPerPage={items ? +items : COUNT_NEWS_ON_PAGE}
          />
        )}
      </div>

      <ModalWrap isShow={showModal} className="!p-0 md:p-[15px]">
        <div className="w-full h-full flex flex-col md:w-[650px] md:max-w-[90%] md:h-auto bg-white p-[20px_30px] md:rounded-[40px] relative">
          <div className="absolute top-[26px] right-[45px]" onClick={() => setShowModal(false)}>
            <CloseCrossBtn className="cursor-pointer" />
          </div>
          <h2 className="font-comfortaa font-bold text-lg md:text-2xl text-blackBase mb-[21px]">
            Предложить идею
          </h2>
          <textarea
            rows={7}
            className="w-full h-full p-[13px_20px] rounded-[6px] border border-greyBase resize-none text-base text-greyBase mb-[15px]"
            placeholder="Опишите идею инструмента для психолога или улучшение существующего, и ZiNGiZi поможет реализовать."
            value={text}
            onChange={(e) => setText(e.target.value)}></textarea>
          <div className="flex md:justify-center">
            <Button
              type="black"
              onClick={() => {
                sendIdeasToServer();
                setShowModal(false);
              }}
              className="md:w-auto !p-[10px_30px] !text-base !leading-4"
              disabled={!text.trim()}>
              Отправить
            </Button>
          </div>
        </div>
      </ModalWrap>
    </>
  );
};

export default Info;
