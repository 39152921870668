import React, { FC, InputHTMLAttributes, useId } from 'react';
import classNames from 'classnames';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
  label?: any;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
}

const Checkbox: FC<IProps> = ({ error = false, label, checked, ...restProps }) => {
  const name = useId();
  return (
    <div className="flex gap-[14px]">
      <label className="flex items-center gap-3.5 cursor-pointer">
        <input
          type="checkbox"
          id={name}
          checked={checked}
          className="absolute appearance-none peer"
          {...restProps}
        />
        <span
          className={classNames(
            'w-[16px] h-[16px] md:w-[20px] md:h-[20px] flex shrink-0 rounded-[5px] bg-white border border-greyBase relative pointer',
            'hover:border-redBase peer-focus:border-redBase peer-checked:border-redBase',
            "peer-checked:before:bg-redBase before:w-[10px] before:h-[10px] before:absolute before:left-[50%] before:top-[50%] before:content-['']",
            'before:-translate-y-2/4 before:-translate-x-2/4 before:w-[10px] before:h-[10px] before:rounded-sm before:bg-transparent',
            {
              'border-redBase': error,
            }
          )}></span>
        <span
          className={classNames('text-[13px] md:text-sm text-greyBase', {
            'text-redBase': error,
          })}>
          {label}
        </span>
      </label>
    </div>
  );
};

export default Checkbox;
