import React, { FC, useState } from 'react';
import { IconProps } from '../../types/icon.types';

export const VideoIcon: FC<IconProps> = ({
  width = 48,
  height = 48,
  fill = '#fff',
  className,
  checked,
}) => {
  const [hover, setHover] = useState(false);

  if (checked) {
    return (
      <svg
        className={`hover:cursor-pointer ${className}`}
        width={width}
        height={height}
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect width="48" height="48" rx="10" fill="#828282" />
        <path
          d="M14.7143 15H25.8571C26.8422 15 27.787 15.3755 28.4835 16.044C29.1801 16.7124 29.5714 17.619 29.5714 18.5644V29.4356C29.5714 30.381 29.1801 31.2876 28.4835 31.956C27.787 32.6245 26.8422 33 25.8571 33H14.7143C13.7292 33 12.7845 32.6245 12.0879 31.956C11.3913 31.2876 11 30.381 11 29.4356V18.5644C11 17.619 11.3913 16.7124 12.0879 16.044C12.7845 15.3755 13.7292 15 14.7143 15ZM29.5714 22.2178L34.0286 19.0099C34.3045 18.8113 34.6326 18.6904 34.9761 18.6607C35.3196 18.6309 35.6649 18.6936 35.9734 18.8416C36.2819 18.9896 36.5413 19.2172 36.7226 19.4987C36.904 19.7802 37 20.1047 37 20.4356V27.5644C37 27.8953 36.904 28.2198 36.7226 28.5013C36.5413 28.7828 36.2819 29.0104 35.9734 29.1584C35.6649 29.3064 35.3196 29.3691 34.9761 29.3393C34.6326 29.3096 34.3045 29.1887 34.0286 28.9901L29.5714 25.7822V22.2178Z"
          fill="white"
          fillOpacity="0.8"
        />
        <path d="M36 12L12 36" stroke="#DF0000" strokeWidth="3" strokeLinecap="round" />
      </svg>
    );
  }

  return (
    <>
      <svg
        className={`hover:cursor-pointer hidden sm:block ${className}`}
        width={width}
        height={height}
        onMouseMove={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect width="48" height="48" rx="10" fill={hover ? '#111026' : '#828282'} />
        <path
          d="M14.7143 15H25.8571C26.8422 15 27.787 15.3755 28.4835 16.044C29.1801 16.7124 29.5714 17.619 29.5714 18.5644V29.4356C29.5714 30.381 29.1801 31.2876 28.4835 31.956C27.787 32.6245 26.8422 33 25.8571 33H14.7143C13.7292 33 12.7845 32.6245 12.0879 31.956C11.3913 31.2876 11 30.381 11 29.4356V18.5644C11 17.619 11.3913 16.7124 12.0879 16.044C12.7845 15.3755 13.7292 15 14.7143 15ZM29.5714 22.2178L34.0286 19.0099C34.3045 18.8113 34.6326 18.6904 34.9761 18.6607C35.3196 18.6309 35.6649 18.6936 35.9734 18.8416C36.2819 18.9896 36.5413 19.2172 36.7226 19.4987C36.904 19.7802 37 20.1047 37 20.4356V27.5644C37 27.8953 36.904 28.2198 36.7226 28.5013C36.5413 28.7828 36.2819 29.0104 35.9734 29.1584C35.6649 29.3064 35.3196 29.3691 34.9761 29.3393C34.6326 29.3096 34.3045 29.1887 34.0286 28.9901L29.5714 25.7822V22.2178Z"
          fill={fill}
        />
      </svg>
      <svg
        className={`hover:cursor-pointer block sm:hidden ${className}`}
        width={width}
        height={height}
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect width="48" height="48" rx="10" fill={'#828282'} />
        <path
          d="M14.7143 15H25.8571C26.8422 15 27.787 15.3755 28.4835 16.044C29.1801 16.7124 29.5714 17.619 29.5714 18.5644V29.4356C29.5714 30.381 29.1801 31.2876 28.4835 31.956C27.787 32.6245 26.8422 33 25.8571 33H14.7143C13.7292 33 12.7845 32.6245 12.0879 31.956C11.3913 31.2876 11 30.381 11 29.4356V18.5644C11 17.619 11.3913 16.7124 12.0879 16.044C12.7845 15.3755 13.7292 15 14.7143 15ZM29.5714 22.2178L34.0286 19.0099C34.3045 18.8113 34.6326 18.6904 34.9761 18.6607C35.3196 18.6309 35.6649 18.6936 35.9734 18.8416C36.2819 18.9896 36.5413 19.2172 36.7226 19.4987C36.904 19.7802 37 20.1047 37 20.4356V27.5644C37 27.8953 36.904 28.2198 36.7226 28.5013C36.5413 28.7828 36.2819 29.0104 35.9734 29.1584C35.6649 29.3064 35.3196 29.3691 34.9761 29.3393C34.6326 29.3096 34.3045 29.1887 34.0286 28.9901L29.5714 25.7822V22.2178Z"
          fill={fill}
        />
      </svg>
    </>
  );
};
