export const getDevices = async () => {
  try {
    const devices = await navigator.mediaDevices.enumerateDevices();
    let haveAudio = false;
    let haveVideo = false;
    if (devices.some((device) => device.kind === 'audioinput')) {
      haveAudio = true;
    }
    if (devices.some((device) => device.kind === 'videoinput')) {
      haveVideo = true;
    }
    if (haveAudio && haveVideo) {
      await navigator.mediaDevices
        .getUserMedia({ audio: true, video: true })
        .then((stream) => stream.getTracks().forEach((x) => x.stop()))
        .catch((err) => console.log(err));
    } else if (haveAudio) {
      await navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => stream.getTracks().forEach((x) => x.stop()))
        .catch((err) => console.log(err));
    } else if (haveVideo) {
      await navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((stream) => stream.getTracks().forEach((x) => x.stop()))
        .catch((err) => console.log(err));
    }
    {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const audio = devices.filter((device) => device.kind === 'audioinput');
      const video = devices.filter((device) => device.kind === 'videoinput');
      const audioOutput = devices.filter((devices) => devices.kind === 'audiooutput');
      return { audio, video, audioOutput };
    }
  } catch (error) {
    console.error('Error getting devices: ', error);
  }
};
