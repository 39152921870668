import React, { FC, InputHTMLAttributes } from 'react';
import classNames from 'classnames';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  classNameWrap?: string;
  classNameLabel?: string;
  error?: string;
  showError?: boolean;
  showRequired?: boolean;
  icon?: {
    type: 'prefix' | 'suffix';
    icon: React.ReactNode;
  } | null;
}

const Input: FC<IProps> = ({
  label = '',
  type = 'text',
  className = '',
  classNameWrap = '',
  classNameLabel = '',
  required,
  error = '',
  showError = true,
  showRequired = false,
  icon = null,
  ...restProps
}) => {
  return (
    <div
      className={classNames('w-full h-[40px] lg:h-[48px] relative', classNameWrap, {
        '!h-auto': label,
      })}>
      {label && (
        <div className={classNames('mb-[5px] flex items-center gap-[5px]', classNameLabel)}>
          {label}
          {required ? ' *' : ''}
          {showRequired && <p className="text-[10px]">- обязательное поле</p>}
        </div>
      )}
      <div className={classNames('h-[40px] lg:h-[48px] relative', {
        '!h-full': !label,
      })}>
        <input
          className={classNames(
            'w-full h-full lg:h-[48px] p-[10px_15px] md:p-[13px_20px] border border-greyBase focus:border-2 rounded-[10px] text-blackBase',
            className,
            {
              'bg-greyBase15 !border-none': restProps.disabled,
              '!border-redBase': error,
              '!pr-[44px]': icon && icon.type === 'suffix',
            }
          )}
          type={type}
          {...restProps}
        />

        {icon && icon.type === 'suffix' && (
          <span className="absolute top-[50%] right-[20px] -translate-y-1/2">{icon.icon}</span>
        )}
      </div>
      <div className="text-sm text-redBase absolute top-[110%]">{showError && error}</div>
    </div>
  );
};

export default Input;
