import React, { FC, useState } from 'react'
import { IconProps } from '../../types/icon.types'

export const CloseCircleCrossBtn: FC<IconProps> = ({width = 30, height = 30, fill = '#fff', className}) => {
  const [hover, setHover] = useState(false)

  return (
    <svg
      className={`hover:cursor-pointer ${className}`}
      width={width}
      height={height}
      onMouseMove={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="30" height="30" rx="15" fill={hover ? '#111026' : 'white'}/>
      <g clipPath="url(#clip0_234_36)">
        <path
          d="M15.7833 15.0002L20.3889 10.3947C20.4799 10.2884 20.5275 10.1517 20.5221 10.0118C20.5167 9.87203 20.4587 9.7394 20.3598 9.64045C20.2608 9.54151 20.1282 9.48355 19.9884 9.47815C19.8486 9.47275 19.7118 9.52031 19.6056 9.61132L15 14.2169L10.3945 9.60577C10.2898 9.50115 10.148 9.44238 10 9.44238C9.85207 9.44238 9.71018 9.50115 9.60557 9.60577C9.50095 9.71038 9.44218 9.85227 9.44218 10.0002C9.44218 10.1482 9.50095 10.29 9.60557 10.3947L14.2167 15.0002L9.60557 19.6058C9.54741 19.6556 9.50018 19.7169 9.46683 19.7858C9.43349 19.8547 9.41475 19.9298 9.41179 20.0063C9.40884 20.0828 9.42173 20.1591 9.44966 20.2304C9.47759 20.3017 9.51995 20.3664 9.5741 20.4206C9.62824 20.4747 9.69299 20.5171 9.76428 20.545C9.83557 20.5729 9.91186 20.5858 9.98838 20.5829C10.0649 20.5799 10.14 20.5612 10.2089 20.5278C10.2778 20.4945 10.3391 20.4473 10.3889 20.3891L15 15.7835L19.6056 20.3891C19.7118 20.4801 19.8486 20.5277 19.9884 20.5223C20.1282 20.5169 20.2608 20.4589 20.3598 20.36C20.4587 20.261 20.5167 20.1284 20.5221 19.9886C20.5275 19.8488 20.4799 19.712 20.3889 19.6058L15.7833 15.0002Z"
          fill="#C00000"/>
      </g>
      <defs>
        <clipPath id="clip0_234_36">
          <rect width="20" height="20" fill="white" transform="translate(5 5)"/>
        </clipPath>
      </defs>
    </svg>


  )
}
