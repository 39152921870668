import React, { FC, useState } from 'react';
import { IconProps } from '../../../types/icon.types';

export const Anger: FC<IconProps> = ({
  width = 80,
  height = 80,
  fill = '#C8C8C8',
  stroke,
  className,
  needHover = true,
}) => {
  const [hover, setHover] = useState(false);

  return (
    <svg
      className={`hover:cursor-pointer ${className}`}
      width={width}
      height={height}
      onMouseMove={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        width="80"
        height="80"
        rx="40"
        fill={(hover && needHover) ? stroke || 'rgba(192, 0, 0, .5)' : fill}
        fillOpacity={(hover && needHover) && stroke ? '1' : '0.2'}
      />
      <path
        d="M65 40C65 53.8069 53.8069 65 40 65C26.1944 65 15 53.8069 15 40C15 26.1944 26.1944 15 40 15C53.8069 15 65 26.1944 65 40Z"
        fill="#DA2F47"
      />
      <path
        d="M50.3958 56.4985C50.3333 56.2499 48.7736 50.4166 40 50.4166C31.225 50.4166 29.6667 56.2499 29.6042 56.4985C29.5278 56.7999 29.6639 57.1124 29.9333 57.2679C30.2042 57.4193 30.5431 57.3777 30.7667 57.161C30.7931 57.1346 33.4806 54.5832 40 54.5832C46.5194 54.5832 49.2083 57.1346 49.2333 57.1596C49.3667 57.2916 49.5444 57.361 49.7222 57.361C49.8389 57.361 49.9569 57.3318 50.0639 57.2721C50.3361 57.1166 50.4722 56.8013 50.3958 56.4985ZM36.8153 39.0179C32.2944 34.4971 25.0292 34.4443 24.7222 34.4443C23.9556 34.4443 23.3347 35.0652 23.3347 35.8304C23.3333 36.5971 23.9542 37.2193 24.7208 37.2221C24.7611 37.2221 27.3944 37.2527 30.2528 38.2457C29.4292 39.1346 28.8889 40.5152 28.8889 42.0832C28.8889 44.7693 30.4431 46.9443 32.3611 46.9443C34.2792 46.9443 35.8333 44.7693 35.8333 42.0832C35.8333 41.8416 35.8069 41.611 35.7819 41.3791C35.8 41.3791 35.8167 41.3888 35.8333 41.3888C36.1889 41.3888 36.5444 41.2527 36.8153 40.9818C37.3583 40.4388 37.3583 39.561 36.8153 39.0179ZM55.2778 34.4443C54.9708 34.4443 47.7069 34.4971 43.1847 39.0179C42.6417 39.561 42.6417 40.4388 43.1847 40.9818C43.4556 41.2527 43.8111 41.3888 44.1667 41.3888C44.1847 41.3888 44.2 41.3791 44.2167 41.3791C44.1944 41.611 44.1667 41.8416 44.1667 42.0832C44.1667 44.7693 45.7208 46.9443 47.6389 46.9443C49.5569 46.9443 51.1111 44.7693 51.1111 42.0832C51.1111 40.5152 50.5708 39.1346 49.7472 38.2457C52.6056 37.2527 55.2389 37.2221 55.2806 37.2221C56.0458 37.2193 56.6667 36.5971 56.6653 35.8304C56.6639 35.0652 56.0444 34.4443 55.2778 34.4443Z"
        fill="#292F33"
      />
    </svg>
  );
};
