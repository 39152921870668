import React, { FC, useState } from 'react'
import { IconProps } from '../../types/icon.types'

export const FaceSmile: FC<IconProps> = ({
                                           width = 48,
                                           height = 48,
                                           fillBg = '#828282',
                                           fill = '#fff',
                                           className
                                         }) => {
  const [hover, setHover] = useState(false)

  return (
    <svg
      className={`hover:cursor-pointer ${className}`}
      width={width}
      height={height}
      onMouseMove={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="10" fill={hover ? '#111026' : fillBg}/>
      <path
        d="M24 37C27.4478 37 30.7544 35.6304 33.1924 33.1924C35.6304 30.7544 37 27.4478 37 24C37 20.5522 35.6304 17.2456 33.1924 14.8076C30.7544 12.3696 27.4478 11 24 11C20.5522 11 17.2456 12.3696 14.8076 14.8076C12.3696 17.2456 11 20.5522 11 24C11 27.4478 12.3696 30.7544 14.8076 33.1924C17.2456 35.6304 20.5522 37 24 37ZM19.3332 27.5293C20.2422 28.5805 21.7961 29.6875 24 29.6875C26.2039 29.6875 27.7578 28.5805 28.6668 27.5293C28.9613 27.1891 29.4742 27.1535 29.8145 27.448C30.1547 27.7426 30.1902 28.2555 29.8957 28.5957C28.7633 29.8957 26.798 31.3125 24 31.3125C21.202 31.3125 19.2367 29.8957 18.1043 28.5957C17.8098 28.2555 17.8453 27.7426 18.1855 27.448C18.5258 27.1535 19.0387 27.1891 19.3332 27.5293ZM18.3328 21.5625C18.3328 21.1315 18.504 20.7182 18.8088 20.4135C19.1135 20.1087 19.5268 19.9375 19.9578 19.9375C20.3888 19.9375 20.8021 20.1087 21.1069 20.4135C21.4116 20.7182 21.5828 21.1315 21.5828 21.5625C21.5828 21.9935 21.4116 22.4068 21.1069 22.7115C20.8021 23.0163 20.3888 23.1875 19.9578 23.1875C19.5268 23.1875 19.1135 23.0163 18.8088 22.7115C18.504 22.4068 18.3328 21.9935 18.3328 21.5625ZM28.0828 19.9375C28.5138 19.9375 28.9271 20.1087 29.2319 20.4135C29.5366 20.7182 29.7078 21.1315 29.7078 21.5625C29.7078 21.9935 29.5366 22.4068 29.2319 22.7115C28.9271 23.0163 28.5138 23.1875 28.0828 23.1875C27.6518 23.1875 27.2385 23.0163 26.9338 22.7115C26.629 22.4068 26.4578 21.9935 26.4578 21.5625C26.4578 21.1315 26.629 20.7182 26.9338 20.4135C27.2385 20.1087 27.6518 19.9375 28.0828 19.9375Z"
        fill={fill}
        fillOpacity="0.8"
      />
    </svg>
  )
}
