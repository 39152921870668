import React, { FC, } from 'react'
import { IconProps } from '../../types/icon.types'

export const ArrowTopRight: FC<IconProps> = ({width = 11, height = 12, fill = "#C00000", className}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 11 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M0.787131 10.7141L10.2152 1.28602M10.2152 1.28602V8.35709M10.2152 1.28602H3.14415"
            stroke={fill}
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"/>
    </svg>
  )
}
