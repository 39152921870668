import React, { FC, useState } from 'react';
import { IconProps } from '../../types/icon.types';

export const ArrowLeft: FC<IconProps> = ({
  width = 27,
  height = 22,
  fill = '#111026',
  className,
  needHover = true,
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 27 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.338423 11.8222C0.12172 11.604 1.21535e-07 11.3083 1.25211e-07 11C1.28888e-07 10.6917 0.12172 10.396 0.338423 10.1778L9.59592 0.870297C9.70186 0.755989 9.82961 0.664307 9.97156 0.600718C10.1135 0.537129 10.2667 0.502937 10.4221 0.500181C10.5775 0.497425 10.7318 0.526159 10.8759 0.584675C11.02 0.643191 11.1509 0.730286 11.2608 0.840763C11.3707 0.951243 11.4573 1.08284 11.5155 1.22771C11.5737 1.37258 11.6023 1.52775 11.5995 1.68397C11.5968 1.84018 11.5628 1.99424 11.4995 2.13696C11.4363 2.27967 11.3451 2.40812 11.2314 2.51463L3.94885 9.83656L25.8428 9.83656C26.1497 9.83656 26.4441 9.95913 26.6611 10.1773C26.8781 10.3955 27 10.6914 27 11C27 11.3086 26.8781 11.6045 26.6611 11.8227C26.4441 12.0409 26.1497 12.1634 25.8428 12.1634L3.94885 12.1634L11.2314 19.4854C11.3451 19.5919 11.4363 19.7203 11.4995 19.863C11.5628 20.0058 11.5968 20.1598 11.5995 20.316C11.6023 20.4722 11.5737 20.6274 11.5155 20.7723C11.4573 20.9172 11.3707 21.0488 11.2608 21.1592C11.1509 21.2697 11.02 21.3568 10.8759 21.4153C10.7318 21.4738 10.5775 21.5026 10.4221 21.4998C10.2667 21.4971 10.1135 21.4629 9.97156 21.3993C9.82961 21.3357 9.70186 21.244 9.59592 21.1297L0.338423 11.8222Z"
      />
    </svg>
  );
};
