import React, { FC } from 'react';
import { IconProps } from '../../types/icon.types';

export const MoveLeft: FC<IconProps> = ({
  width = 27,
  height = 22,
  fill = '#111026',
  className,
}) => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="30" height="30" rx="6" fill="#F7F7F7" />
      <path
        d="M6.46967 14.4697C6.17678 14.7626 6.17678 15.2374 6.46967 15.5303L11.2426 20.3033C11.5355 20.5962 12.0104 20.5962 12.3033 20.3033C12.5962 20.0104 12.5962 19.5355 12.3033 19.2426L8.06066 15L12.3033 10.7574C12.5962 10.4645 12.5962 9.98959 12.3033 9.6967C12.0104 9.40381 11.5355 9.40381 11.2426 9.6967L6.46967 14.4697ZM24.0303 15.5303C24.3232 15.2374 24.3232 14.7626 24.0303 14.4697L19.2574 9.6967C18.9645 9.40381 18.4896 9.40381 18.1967 9.6967C17.9038 9.98959 17.9038 10.4645 18.1967 10.7574L22.4393 15L18.1967 19.2426C17.9038 19.5355 17.9038 20.0104 18.1967 20.3033C18.4896 20.5962 18.9645 20.5962 19.2574 20.3033L24.0303 15.5303ZM7 15.75H17V14.25H7V15.75ZM17 15.75H23.5V14.25H17V15.75Z"
        fill="#C00000"
      />
    </svg>
  );
};
