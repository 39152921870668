import { apiSlice } from './api'

interface ITokens {
  token: string
  refresh_token: string
}

interface IStatusRegistration {
  success: boolean
}

interface IStatusAuth {
  result: boolean
}


const authApi = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: (build) => ({
    registration: build.mutation<IStatusRegistration, {
      captcha: string,
      email: string,
      agree_to_receive_emails: boolean,
      have_education: boolean
    }>({
      query: (body) => ({
        url: '/register',
        method: 'POST',
        body: JSON.stringify(body),
      }),
    }),
    login: build.mutation<IStatusAuth, { email: string }>({
      query: (body) => ({
        url: '/auth/start',
        method: 'POST',
        body: JSON.stringify(body),
      }),
    }),
    loginFinish: build.mutation<ITokens, { email: string, authCode: string }>({
      query: (body) => ({
        url: '/auth/finish',
        method: 'POST',
        body: JSON.stringify(body),
      }),
    })
  })
})

export const {useLoginMutation, useRegistrationMutation, useLoginFinishMutation} = authApi