import React, { FC, useState } from 'react';
import { IconProps } from '../../types/icon.types';

export const PauseIcon: FC<IconProps> = ({
  width = 11,
  height = 12,
  fill = '#C00000',
  className,
  checked,
}) => {
  return (
    <>
      {checked ? (
        <svg
          className="border rounded-[10px] border-[transparent] hover:border-[#C00000] cursor-pointer"
          width="36"
          height="36"
          viewBox="0 0 36 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <rect width="36" height="36" rx="10" fill="#DEC05E" />
          <path
            d="M25.8395 15.7943C26.2398 16.0071 26.5746 16.3248 26.808 16.7134C27.0415 17.102 27.1648 17.5468 27.1648 18.0001C27.1648 18.4534 27.0415 18.8982 26.808 19.2868C26.5746 19.6753 26.2398 19.9931 25.8395 20.2059L15.1629 26.0118C13.4437 26.9476 11.332 25.7309 11.332 23.8068V12.1943C11.332 10.2693 13.4437 9.05343 15.1629 9.98759L25.8395 15.7943Z"
            fill="#111026"
          />
        </svg>
      ) : (
        <svg
          className="border rounded-[10px] border-[transparent] hover:border-[#C00000] cursor-pointer"
          width="36"
          height="36"
          viewBox="0 0 36 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <rect width="36" height="36" rx="10" fill="#DEC05E" />
          <g clipPath="url(#clip0_489_5607)">
            <path
              d="M13.625 10.5C12.5898 10.5 11.75 11.3398 11.75 12.375V23.625C11.75 24.6602 12.5898 25.5 13.625 25.5H14.875C15.9102 25.5 16.75 24.6602 16.75 23.625V12.375C16.75 11.3398 15.9102 10.5 14.875 10.5H13.625ZM21.125 10.5C20.0898 10.5 19.25 11.3398 19.25 12.375V23.625C19.25 24.6602 20.0898 25.5 21.125 25.5H22.375C23.4102 25.5 24.25 24.6602 24.25 23.625V12.375C24.25 11.3398 23.4102 10.5 22.375 10.5H21.125Z"
              fill="#111026"
            />
          </g>
          <defs>
            <clipPath id="clip0_489_5607">
              <rect width="12.5" height="20" fill="white" transform="translate(11.75 8)" />
            </clipPath>
          </defs>
        </svg>
      )}
    </>
  );
};
