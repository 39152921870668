import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../../hooks/redux';
import { selectUser } from '../../../store/auth.slice';
import Button from '../../common/button';
import { timezoneList } from '../../../assets/timezone';
import { getTimeWithTZ, getTime, getMyTimeZone } from '../../../utils/getTime';
import { capitalizeFirstLetter } from '../../../utils/textHelpers';
import dayjs from 'dayjs';
import useTimeRemaining from '../../../hooks/useRemainingTime';

type CardProps = {
  item: {
    id: number;
    client_entity: string;
    datetime_start: string;
    datetime_end: string;
    client_timezone: string;
    code: string;
    link: string;
  };
  handleChangeCons: (a: number) => void;
  client?: {
    id: number;
    name: string;
  };
  openEditFormClient: (id: number) => void;
  sortConsultation: () => void;
};

export const ConsultationCard: FC<CardProps> = ({
  item,
  handleChangeCons,
  openEditFormClient,
  client,
  sortConsultation,
}) => {
  const userData = useAppSelector(selectUser);
  const [copy, setCopy] = useState(false);
  const { days, hours, minutes, seconds } = useTimeRemaining(new Date(item.datetime_start))
  const { days: daysEnd, hours: hoursEnd, minutes: minutesEnd, seconds: secondsEnd } = useTimeRemaining(new Date(item.datetime_end))

  useEffect(() => {
    if (!copy) return;

    const timer = setTimeout(() => {
      setCopy(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, [copy]);

  const tzOffset = timezoneList.find((el) => el.name === item.client_timezone)?.offset;

  const handleCopy = () => {
    navigator.clipboard.writeText(
      `У Вас назначена встреча в ZiNGiZi.
Психолог: ${userData?.last_name || ''} ${userData?.first_name || ''} ${userData?.middle_name || ''}
${capitalizeFirstLetter(dayjs().format('dddd'))}, ${dayjs().format('DD MMMM')} · ${getTimeWithTZ(
        new Date(item.datetime_start),
        Number(tzOffset)
      )}–${getTimeWithTZ(new Date(item.datetime_end), Number(tzOffset))}
Часовой пояс: ${item.client_timezone}
Ссылка на видеовстречу: ${item.link}`
    );
    setCopy(!copy);
  };

  useEffect(() => {
    if (daysEnd === 0 && hoursEnd === 0 && minutesEnd === 0 && secondsEnd === 1) {
      setTimeout(() => sortConsultation(), 2000)
    }
  }, [secondsEnd])

  return (
    <div className="w-full rounded-[20px] flex flex-col w-full bg-white p-[20px] gap-[25px] relative">
      {
        days === 0 && hours < 2 && (hours > 0 || minutes > 0 || seconds > 0) && (
          <div className="absolute top-0 right-[20px] p-[4px_8px] text-blackBase text-xs md:text-sm bg-goldBase rounded-tr-[5px] rounded-bl-[5px]">
            {
              days === 0 && hours === 0 && ((minutes > 0 && minutes < 5) || (minutes === 0 && seconds > 0))
                ? 'Осталось менее 5 минут'
                : hours === 0 && minutes > 0 && minutes < 15
                  ? 'Осталось менее 15 минут'
                  : hours === 0 && minutes > 0 && minutes < 30
                    ? 'Осталось менее 30 минут'
                    : hours === 0 && minutes >= 30
                      ? 'Осталось менее 1 часа'
                      : hours === 1 && minutes >= 0
                        ? 'Осталось менее 2 часов'
                        : `${days}:${hours}:${minutes}:${seconds}`
            }
          </div>
        )
      }
      <div className="flex flex-col gap-[5px]">
        <p className="text-[#878791] text-sm">Клиент:</p>
        <div className="flex gap-[10px] items-center">
          <p className="text-lg font-semibold text-blackBase">{client?.name?.split('-')[0]}</p>
          <button onClick={() => openEditFormClient(client?.id || 0)}>
            <svg
              viewBox="0 0 512.00007 512"
              width={16}
              height={16}
              xmlns="http://www.w3.org/2000/svg">
              <path d="m4.394531 507.601562c4.316407 4.324219 10.863281 5.613282 16.507813 3.179688l137.273437-58.839844-98.121093-98.121094-58.839844 137.273438c-2.417969 5.625-1.1562502 12.171875 3.179687 16.507812zm0 0" />
              <path d="m77.296875 328.644531 250.003906-250.003906 106.054688 106.054687-250.003907 250.003907zm0 0" />
              <path d="m422.761719 4.394531c-5.859375-5.859375-15.351563-5.859375-21.210938 0l-53.035156 53.035157 106.054687 106.054687 53.035157-53.035156c5.859375-5.859375 5.859375-15.351563 0-21.210938zm0 0" />
            </svg>
          </button>
        </div>
      </div>
      <div className="flex flex-col gap-[15px]">
        <div className="flex gap-[15px]">
          <p className="text-[#878791]">Ваше время:</p>
          <p className="text-blackBase">{`${getTime(new Date(item.datetime_start))} - ${getTime(
            new Date(item.datetime_end)
          )} (${getMyTimeZone()})`}</p>
        </div>
        <div className="flex gap-[15px]">
          <p className="text-[#878791]">Время у клиента:</p>
          <p className="text-blackBase">{`${getTimeWithTZ(
            new Date(item.datetime_start),
            Number(tzOffset)
          )} - ${getTimeWithTZ(new Date(item.datetime_end), Number(tzOffset))} (${
            item.client_timezone
          })`}</p>
        </div>
        <div className="flex flex-col md+:flex-row lg:flex-col xl:flex-row xl:items-center md+:gap-[15px]">
          <p className="text-[#878791]">Ссылка для подключения:</p>
          <div className="flex items-center gap-[5px]">
            <Link to={item.link} target="_blank" className="text-redBase underline">
              {item.link.split('://')[1]}
            </Link>
            <button onClick={() => handleCopy()}>
              <svg
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M6 11C6 8.172 6 6.757 6.879 5.879C7.757 5 9.172 5 12 5H15C17.828 5 19.243 5 20.121 5.879C21 6.757 21 8.172 21 11V16C21 18.828 21 20.243 20.121 21.121C19.243 22 17.828 22 15 22H12C9.172 22 7.757 22 6.879 21.121C6 20.243 6 18.828 6 16V11Z"
                  stroke="#C00000"
                  strokeWidth="1.5"
                  strokeOpacity={copy ? '0.4' : '1'}
                />
                <path
                  d="M6 19C5.20435 19 4.44129 18.6839 3.87868 18.1213C3.31607 17.5587 3 16.7956 3 16V10C3 6.229 3 4.343 4.172 3.172C5.343 2 7.229 2 11 2H15C15.7956 2 16.5587 2.31607 17.1213 2.87868C17.6839 3.44129 18 4.20435 18 5"
                  stroke="#C00000"
                  strokeWidth="1.5"
                  strokeOpacity={copy ? '0.4' : '1'}
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col md+:flex-row lg:flex-col xl:flex-row justify-between gap-4 items-center">
        <Button
          type="grey"
          className="!text-base !p-0 h-[38px] flex items-center w-full xl:max-w-[152px] !font-semibold"
          onClick={() => handleChangeCons(item.id)}>
          Изменить
        </Button>
        <Button
          onClick={() => {
            window.open(`/meet/${item.code}`, '_blank');
          }}
          className="!text-base !p-0 h-[38px] flex items-center w-full xl:max-w-[286px] !font-semibold"
          type="black">
          Подключиться к встрече
        </Button>
      </div>
    </div>
  );
};
