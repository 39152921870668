import { FC, useState } from 'react';
import { Joy } from '../icons/reactions/joy';
import { Love } from '../icons/reactions/love';
import Button from '../common/button';
import classNames from 'classnames';
import { Anger } from '../icons/reactions/anger';
import { Fear } from '../icons/reactions/fear';
import { Sadness } from '../icons/reactions/sadness';
import { socket } from '../../libs/socket';
import { useAppSelector } from '../../hooks/redux';
import { useParams } from 'react-router-dom';
import { IPsyBlock } from '../tools/new-questions';

export const Reactions: FC<IPsyBlock> = ({ onSetMenu }) => {
  const psyTools = useAppSelector((state) => state.psyToolsSlice.tools);
  const params = useParams();
  const roomName = params.id;
  const [reactionId, setReactionId] = useState<string[]>([]);
  const [selectedEmo, setSelectedEmo] = useState<string[]>([]);

  const settings = psyTools.find((el) => el.name === 'emotions_detection')?.psy_tool_settings;
  const handleSelect = () => {
    if (!reactionId) return;

    socket.emit('message', {
      roomId: roomName,
      message: {
        datetime: new Date().toISOString(),
        action: 'result_tool',
        data: {
          name: 'emotions_detection',
          result: {
            category: reactionId,
            emo: selectedEmo,
          },
        },
      },
    });
    onSetMenu(0);
  };

  const selectEmo = (a: string) => {
    if (reactionId.includes(a)) {
      setReactionId((prev) => prev.filter((el) => el !== a));
      return;
    }
    setReactionId((prev) => [...prev, a]);
  };

  return (
    <div
      className={classNames(
        'w-full h-full md:min-w-[300px] md:max-w-max bg-grey1 md:rounded-[20px] p-3.5 md:p-4 relative flex flex-col justify-between',
        {
          '!pr-[11px]': reactionId,
        }
      )}>
      <div className="gap-y-7 gap-x-[30px] overflow-y-auto flex flex-row md:flex-col flex-wrap md:flex-nowrap justify-center md:justify-start content-start items-start md:items-center white-scroll mb-3 relative z-[12]">
        <div className="w-full pr-4 flex flex-col gap-4">
          <div
            className="flex items-center gap-[15px] hover:cursor-pointer"
            onClick={() => selectEmo('Любовь')}>
            <Love className="w-[50px] h-[50px] md:w-[80px] md:h-[80px]" stroke="#fff" />
            <span className="text-white">Любовь</span>
          </div>
          {reactionId.includes('Любовь') && (
            <div className="md:w-auto">
              <div className="flex md:flex-col gap-[15px_12px] flex-wrap items-center">
                {settings
                  ?.find((el) => el.name === 'Любовь')
                  ?.value.map((item, idx) => (
                    <div
                      key={idx}
                      className={classNames(
                        'w-max lg:w-full text-center text-base text-white font-semibold bg-blackBaseOpacity20 rounded-[5px] p-[2px_10px] hover:cursor-pointer',
                        {
                          'bg-goldBase': selectedEmo.includes(item),
                        }
                      )}
                      onClick={() => {
                        if (selectedEmo.includes(item)) {
                          setSelectedEmo([...selectedEmo.filter((el) => el !== item)]);
                          return;
                        }
                        setSelectedEmo([...selectedEmo, item]);
                      }}>
                      {item}
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>

        <div className="w-full pr-4 flex flex-col gap-4">
          <div className="flex items-center gap-[15px]" onClick={() => selectEmo('Радость')}>
            <Joy className="w-[50px] h-[50px] md:w-[80px] md:h-[80px]" stroke="#fff" />
            <span className="text-white">Радость</span>
          </div>
          {reactionId.includes('Радость') && (
            <div className="md:w-auto">
              <div className="flex md:flex-col gap-[15px_12px] flex-wrap items-center">
                {settings
                  ?.find((el) => el.name === 'Радость')
                  ?.value.map((item, idx) => (
                    <div
                      key={idx}
                      className={classNames(
                        'w-max lg:w-full text-center text-base text-white font-semibold bg-blackBaseOpacity20 rounded-[5px] p-[2px_10px] hover:cursor-pointer',
                        {
                          'bg-goldBase': selectedEmo.includes(item),
                        }
                      )}
                      onClick={() => {
                        if (selectedEmo.includes(item)) {
                          setSelectedEmo([...selectedEmo.filter((el) => el !== item)]);
                          return;
                        }
                        setSelectedEmo([...selectedEmo, item]);
                      }}>
                      {item}
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>

        <div className="w-full pr-4 flex flex-col gap-4">
          <div className="flex items-center gap-[15px]" onClick={() => selectEmo('Грусть')}>
            <Sadness className="w-[50px] h-[50px] md:w-[80px] md:h-[80px]" stroke="#fff" />
            <span className="text-white">Грусть</span>
          </div>
          {reactionId.includes('Грусть') && (
            <div className="md:w-auto">
              <div className="flex md:flex-col gap-[15px_12px] flex-wrap items-center">
                {settings
                  ?.find((el) => el.name === 'Грусть')
                  ?.value.map((item, idx) => (
                    <div
                      key={idx}
                      className={classNames(
                        'w-max lg:w-full text-center text-base text-white font-semibold bg-blackBaseOpacity20 rounded-[5px] p-[2px_10px] hover:cursor-pointer',
                        {
                          'bg-goldBase': selectedEmo.includes(item),
                        }
                      )}
                      onClick={() => {
                        if (selectedEmo.includes(item)) {
                          setSelectedEmo([...selectedEmo.filter((el) => el !== item)]);
                          return;
                        }
                        setSelectedEmo([...selectedEmo, item]);
                      }}>
                      {item}
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>

        <div className="w-full pr-4 flex flex-col gap-4">
          <div className="flex items-center gap-[15px]" onClick={() => selectEmo('Страх')}>
            <Fear className="w-[50px] h-[50px] md:w-[80px] md:h-[80px]" stroke="#fff" />
            <span className="text-white">Страх</span>
          </div>
          {reactionId.includes('Страх') && (
            <div className="md:w-auto">
              <div className="flex md:flex-col gap-[15px_12px] flex-wrap items-center">
                {settings
                  ?.find((el) => el.name === 'Страх')
                  ?.value.map((item, idx) => (
                    <div
                      key={idx}
                      className={classNames(
                        'w-max lg:w-full text-center text-base text-white font-semibold bg-blackBaseOpacity20 rounded-[5px] p-[2px_10px] hover:cursor-pointer',
                        {
                          'bg-goldBase': selectedEmo.includes(item),
                        }
                      )}
                      onClick={() => {
                        if (selectedEmo.includes(item)) {
                          setSelectedEmo([...selectedEmo.filter((el) => el !== item)]);
                          return;
                        }
                        setSelectedEmo([...selectedEmo, item]);
                      }}>
                      {item}
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>

        <div className="w-full pr-4 flex flex-col gap-4">
          <div className="flex items-center gap-[15px]" onClick={() => selectEmo('Гнев')}>
            <Anger className="w-[50px] h-[50px] md:w-[80px] md:h-[80px]" stroke="#fff" />
            <span className="text-white">Гнев</span>
          </div>
          {reactionId.includes('Гнев') && (
            <div className="md:w-auto">
              <div className="flex md:flex-col gap-[15px_12px] flex-wrap items-center">
                {settings
                  ?.find((el) => el.name === 'Гнев')
                  ?.value.map((item, idx) => (
                    <div
                      key={idx}
                      className={classNames(
                        'w-max lg:w-full text-center text-base text-white font-semibold bg-blackBaseOpacity20 rounded-[5px] p-[2px_10px] hover:cursor-pointer',
                        {
                          'bg-goldBase': selectedEmo.includes(item),
                        }
                      )}
                      onClick={() => {
                        if (selectedEmo.includes(item)) {
                          setSelectedEmo([...selectedEmo.filter((el) => el !== item)]);
                          return;
                        }
                        setSelectedEmo([...selectedEmo, item]);
                      }}>
                      {item}
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="w-full md:relative  px-[15px] md:px-0">
        <Button
          type="primary"
          className="!text-[13px] lg:!text-sm p-[6px_10px] mb-[10px] md:mb-0"
          disabled={selectedEmo.length === 0}
          onClick={() => handleSelect()}>
          Выбрать
        </Button>
      </div>
    </div>
  );
};
