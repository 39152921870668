import Janus from '../libs/Janus';

const apisecret = 'm0vSIgfUfnOk';
const server = 'wss://dev.video.zingizi.pro/ws';

export class EchoTest {
  constructor(opaqueId, localVideoRef, remoteVideoRef) {
    this.opaqueId = opaqueId;
    this.localVideoRef = localVideoRef;
    this.remoteVideoRef = remoteVideoRef;
    this.selectedAudioDevice = null;
    this.selectedVideoDevice = null;
    this.janus = null;
    this.pluginHandle = null;
    this.bitrate = null;

    this.initPromise = new Promise((resolve, reject) => {
      Janus.init({
        debug: 'all',
        callback: resolve,
        error: reject,
      });
    });
  }

  start = () => {
    if (this.janus === null) {
      this.initPromise.then(() => {
        console.warn('this.initPromise');
        this.janus = new Janus({
          server,
          apisecret,
          success: this.successStart,
          error: (error) => {
            console.error('WebRTC error:', error);
            this.pluginHandle && this.pluginHandle.detach();
            this.janus.destroy();
            this.janus = null;
          },
        });
      });
    }
  };

  successStart = () => {
    console.warn('successStart');
    this.janus.attach({
      plugin: 'janus.plugin.echotest',
      opaqueId: this.opaqueId,
      success: (pluginHandle) => {
        console.warn('echotest attach', pluginHandle);
        this.pluginHandle = pluginHandle;
        this.pluginHandle.createOffer({
          media: {
            audio: this.selectedAudioDevice
              ? { deviceId: { exact: this.selectedAudioDevice } }
              : this.selectedAudioDevice !== ''
              ? true
              : false,
            video: this.selectedVideoDevice
              ? { deviceId: { exact: this.selectedVideoDevice } }
              : this.selectedVideoDevice !== ''
              ? true
              : false,
          },
          success: (jsep) => {
            console.warn('echotest success', jsep);
            this.pluginHandle.send({ message: { audio: true, video: true }, jsep });
            if (this.bitrate) {
              this.changeBitrate(this.bitrate);
            }
          },
        });
      },
      onmessage: (msg, jsep) => {
        if (jsep) {
          console.warn('echotest onmessage', jsep);
          this.pluginHandle.handleRemoteJsep({ jsep });
        }
      },
      onlocalstream: (stream) => {
        Janus.attachMediaStream(this.localVideoRef.current, stream);
      },
      onremotestream: (stream) => {
        Janus.attachMediaStream(this.remoteVideoRef.current, stream);
      },
    });
  };

  stop = () => {
    this.pluginHandle && this.pluginHandle.detach();
    this.pluginHandle = null;

    this.janus.destroy();
    this.janus = null;

    const v1 = this.localVideoRef.current;
    if (v1 && v1.srcObject) {
      v1.srcObject = null;
    }

    const v2 = this.remoteVideoRef.current;
    if (v2 && v2.srcObject) {
      v2.srcObject = null;
    }
  };

  changeAudioDevice = (deviceId) => {
    let media = { audio: { deviceId: { exact: deviceId } } };

    if (this.selectedAudioDevice) {
      media = {
        audio: { deviceId: { exact: deviceId } },
        replaceAudio: true,
      };
    }

    this.selectedAudioDevice = deviceId;

    console.warn(this.selectedAudioDevice);

    if (this.pluginHandle) {
      this.pluginHandle.createOffer({
        media,
        success: (jsep) => {
          this.pluginHandle.send({ message: { audio: true }, jsep });
        },
      });
    }
  };

  changeVideoDevice = (deviceId) => {
    let media = { video: { deviceId: { exact: deviceId } } };

    if (this.selectedVideoDevice) {
      media = {
        video: { deviceId: { exact: deviceId } },
        replaceVideo: true,
      };
    }

    this.selectedVideoDevice = deviceId;

    console.warn(this.selectedVideoDevice);

    if (this.pluginHandle) {
      this.pluginHandle.createOffer({
        media,
        success: (jsep) => {
          this.pluginHandle.send({ message: { video: true }, jsep });
        },
      });
    }
  };

  changeBitrate = (bitrate) => {
    if (this.pluginHandle) {
      bitrate = parseInt(bitrate) * 1000;
      this.pluginHandle.send({ message: { bitrate: bitrate } });
    } else {
      this.bitrate = bitrate;
    }
  };

  getBitrate = () => {
    if (this.pluginHandle) {
      return this.pluginHandle.getBitrate();
    }
  };
}
