import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useLazyGetPostQuery } from '../../services/posts.api'
import { baseUrlFiles } from '../../configs/api/api.config'
import { ArrowLeft } from '../../components/icons/arrow-left'

const Post = () => {
  const params = useParams();
  const [getPost, {data}] = useLazyGetPostQuery()

  useEffect(() => {
    if (params?.id) {
      getPost(params.id)
    }
  }, [params, getPost])

  if (!data) {
    return null
  }

  return (
    <div className="flex flex-col">
      <div className="hidden md:flex mb-[10px]">
        <Link to={'/info'} className="group w-[45px] h-[45px] flex items-center justify-center rounded-full hover:bg-redBase">
          <ArrowLeft className="fill-blackBase group-hover:fill-white" width={21} height={16} />
        </Link>
      </div>
      <div className="flex items-start mb-5">
        <div className="hidden md:flex w-full md:w-[43%] object-contain md:mr-4 shrink-0 xl:pt-[10px]">
          <img className="w-full h-full object-contain" src={baseUrlFiles + data.image} alt=""/>
        </div>
        <div>
          <div className="flex gap-[14px] font-comfortaa font-semibold text-lg md:text-2xl lg:text-[45px] xl:text-[50px] leading-[1rem] lg:leading-[45px] xl:leading-[75px] mb-4 md:mb-5 lg:mb-[25px]">
            <Link to={'/info'} className="md:hidden group w-auto">
              <ArrowLeft className="fill-blackBase" width={21} height={16} />
            </Link>
            <span>{data?.title}</span>
          </div>
          <p className="font-semibold text-sm md:text-sm xl:text-lg text-blackBase">{data.excerpt}</p>
        </div>
      </div>
      <div className="bg-pink1 md:bg-white rounded-[20px] order-2 px-[15px]">
        <img className="md:hidden w-full md:w-[50%] md:float-right md:ml-4 mb-[20px] mt-0 order-2" src={baseUrlFiles + data.image} alt=""/>
        <div className="order-3" dangerouslySetInnerHTML={{__html: data.content}}></div>
      </div>
    </div>
  )
}

export default Post