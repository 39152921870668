import React, { FC, useState } from 'react';
import { IconProps } from '../../types/icon.types';

export const MicroIcon: FC<IconProps> = ({
  width = 48,
  height = 48,
  fill = '#828282',
  className,
  checked = false,
  needHover = true,
}) => {
  const [hover, setHover] = useState(false);
  if (checked) {
    return (
      <svg
        className={`hover:cursor-pointer ${className}`}
        width={width}
        height={height}
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect width="48" height="48" rx="10" fill="#4D4D4D" fillOpacity="0.5" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.8395 12.7356L23.9967 12.7299C24.5519 12.7093 25.1055 12.8009 25.6245 12.9992C26.1436 13.1975 26.6172 13.4984 27.0173 13.884C27.4174 14.2695 27.7356 14.7317 27.953 15.2431C28.1703 15.7544 28.2824 16.3043 28.2824 16.8599V22.5713C28.2824 23.7079 27.8308 24.798 27.0271 25.6018C26.2234 26.4055 25.1333 26.857 23.9967 26.857C22.86 26.857 21.7699 26.4055 20.9662 25.6018C20.1625 24.798 19.7109 23.7079 19.7109 22.5713V17.0185C19.7109 15.909 20.1412 14.8428 20.9111 14.0441C21.6811 13.2453 22.7308 12.7763 23.8395 12.7356Z"
          fill="white"
          fillOpacity="0.8"
        />
        <path
          d="M31.1412 22.5713C31.1388 24.4372 30.4063 26.2282 29.1004 27.561C27.7945 28.8938 26.0189 29.6627 24.1534 29.7032C22.2879 29.7437 20.4806 29.0526 19.118 27.7777C17.7555 26.5028 16.946 24.7454 16.8626 22.8813L16.8555 22.5713M23.9983 29.7141V35.4284"
          stroke="white"
          strokeOpacity="0.8"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M36 12L12 36" stroke="#DF0000" strokeWidth="3" strokeLinecap="round" />
      </svg>
    );
  }

  return (
    <>
      <svg
        className={`hover:cursor-pointer hidden sm:block ${className}`}
        width={width}
        height={height}
        onMouseMove={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect width="48" height="48" rx="10" fill={needHover && hover ? '#111026' : fill} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.8429 12.7351L24.0001 12.7294C24.5553 12.7088 25.109 12.8005 25.628 12.9987C26.147 13.197 26.6207 13.498 27.0207 13.8835C27.4208 14.269 27.739 14.7313 27.9564 15.2426C28.1737 15.7539 28.2858 16.3038 28.2858 16.8594V22.5708C28.2858 23.7075 27.8343 24.7976 27.0305 25.6013C26.2268 26.405 25.1367 26.8565 24.0001 26.8565C22.8634 26.8565 21.7733 26.405 20.9696 25.6013C20.1659 24.7976 19.7144 23.7075 19.7144 22.5708V17.018C19.7143 15.9085 20.1446 14.8423 20.9145 14.0436C21.6845 13.2448 22.7342 12.7758 23.8429 12.7351Z"
          fill="white"
        />
        <path
          d="M31.1429 22.5703C31.1405 24.4363 30.408 26.2272 29.1021 27.56C27.7962 28.8929 26.0206 29.6618 24.1551 29.7023C22.2896 29.7428 20.4823 29.0517 19.1198 27.7768C17.7572 26.5019 16.9477 24.7444 16.8643 22.8803L16.8572 22.5703M24 29.7132V35.4275"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <svg
        className={`hover:cursor-pointer block sm:hidden ${className}`}
        width={width}
        height={height}
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect width="48" height="48" rx="10" fill={fill} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.8429 12.7351L24.0001 12.7294C24.5553 12.7088 25.109 12.8005 25.628 12.9987C26.147 13.197 26.6207 13.498 27.0207 13.8835C27.4208 14.269 27.739 14.7313 27.9564 15.2426C28.1737 15.7539 28.2858 16.3038 28.2858 16.8594V22.5708C28.2858 23.7075 27.8343 24.7976 27.0305 25.6013C26.2268 26.405 25.1367 26.8565 24.0001 26.8565C22.8634 26.8565 21.7733 26.405 20.9696 25.6013C20.1659 24.7976 19.7144 23.7075 19.7144 22.5708V17.018C19.7143 15.9085 20.1446 14.8423 20.9145 14.0436C21.6845 13.2448 22.7342 12.7758 23.8429 12.7351Z"
          fill="white"
        />
        <path
          d="M31.1429 22.5703C31.1405 24.4363 30.408 26.2272 29.1021 27.56C27.7962 28.8929 26.0206 29.6618 24.1551 29.7023C22.2896 29.7428 20.4823 29.0517 19.1198 27.7768C17.7572 26.5019 16.9477 24.7444 16.8643 22.8803L16.8572 22.5703M24 29.7132V35.4275"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};
