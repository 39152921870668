import React, { FC } from 'react';
import { IconProps } from '../../types/icon.types';

export const RepeatIcon: FC<IconProps> = ({
  width = 30,
  height = 30,
  fill = '#828282',
  className,
}) => {
  return (
    <svg
      className="border rounded-[10px] border-[transparent] hover:border-[#C00000] cursor-pointer"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="36" height="36" rx="10" fill="#DEC05E" />
      <path
        d="M22.8342 12.2657C24.1869 13.4061 25.0929 14.9879 25.392 16.7317C25.6912 18.4754 25.3643 20.2688 24.4691 21.7948C23.5738 23.3208 22.168 24.4812 20.5 25.0709C18.8319 25.6606 17.0091 25.6417 15.3537 25.0175C13.6983 24.3932 12.3168 23.2038 11.4535 21.6595C10.5902 20.1153 10.3006 18.3155 10.636 16.5783C10.9713 14.8412 11.9099 13.2785 13.286 12.1665"
        stroke="#111026"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 10.75H15.0397V15.5"
        stroke="#111026"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
