import React, { useEffect, useRef, useState } from 'react';
import { ArrowLeft } from '../components/icons/arrow-left';
import Input from '../components/common/input';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Checkbox from '../components/common/checkbox';
import { checkEmailMask, checkEmailSymbol } from '../utils/validators';
import { useRegistrationMutation } from '../services/auth.api';
import ConfirmAuth from '../components/confirm-auth';
import { toast } from 'react-toastify';
import { useAppSelector } from '../hooks/redux';
import { selectUser } from '../store/auth.slice';
import { LANDING } from '../configs/api/api.config';
import { SmartCaptcha } from '@yandex/smart-captcha';
import HCaptcha from '@hcaptcha/react-hcaptcha';

const Registration = () => {
  const [captcha, setCaptcha] = useState('');
  const [resetCaptcha, setResetCaptcha] = useState(0);
  const [email, setEmail] = useState('');
  const [haveEducation, setHaveEducation] = useState(false);
  const [agreePrivacy, setAgreePrivacy] = useState(false);
  const [error, setError] = useState('');
  const [errorAgreeWithRooles, setErrorAgreeWithRooles] = useState(false);
  const [errorAgreePrivacy, setErrorAgreePrivacy] = useState(false);
  const [isInputAuthCode, setIsInputAuthCode] = useState(false);
  const refRegistration = useRef<HTMLButtonElement>(null);
  const [registration] = useRegistrationMutation();
  const userData = useAppSelector(selectUser);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (userData) {
      navigate('/profile', { state: { previousLocationPathname: location.pathname } });
    }
    // eslint-disable-next-line
  }, [userData]);

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (email === '') {
        setError(' ');
        return;
      }
      const isEmail = checkEmailMask(email);
      if (!isEmail) {
        setError('Неверный формат Email');
        return;
      } else {
        setError('');
      }
      refRegistration?.current?.click();
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isCorrect = checkEmailSymbol(e.target.value);
    if (!isCorrect) {
      setEmail(e.target.value);
      setError('');
    }
  };

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === '') {
      setError(' ');
      return;
    }
    const isEmail = checkEmailMask(value);
    if (!isEmail) {
      setError('Неверный формат Email');
    } else {
      setError('');
    }
  };

  const onAgreeWithRooles = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHaveEducation(e.target.checked);
    setErrorAgreeWithRooles(!e.target.checked);
  };
  const onAgreePrivacy = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAgreePrivacy(e.target.checked);
    setErrorAgreePrivacy(!e.target.checked);
  };

  const onRegistration = async () => {
    if (email.trim() === '') {
      setError('Введите Email');
      setErrorAgreePrivacy(!agreePrivacy);
      setErrorAgreeWithRooles(!haveEducation);
      return;
    }
    const isEmail = checkEmailMask(email);
    if (!isEmail) {
      setError('Неверный формат Email');
      setErrorAgreePrivacy(!agreePrivacy);
      setErrorAgreeWithRooles(!haveEducation);
      return;
    }
    if (!haveEducation) {
      setErrorAgreePrivacy(!agreePrivacy);
      setErrorAgreeWithRooles(!haveEducation);
      return;
    }
    if (!agreePrivacy) {
      setErrorAgreePrivacy(!agreePrivacy);
      setErrorAgreeWithRooles(!haveEducation);
      return;
    }
    if (!captcha) {
      toast.error('Пройдите капчу');
      return;
    }

    try {
      const authCode = await registration({
        captcha,
        email,
        agree_to_receive_emails: false,
        have_education: haveEducation,
      }).unwrap();

      if (authCode) {
        toast.success('Вы успешно зарегистрировались');
        setIsInputAuthCode(true);
      }
    } catch (e: any) {
      console.log(e);
      if (e.status === 422) {
        setError(e.data.detail);
      }
    } finally {
      setResetCaptcha((prev) => prev + 1);
    }
  };

  return (
    <div className="w-screen h-screen flex items-center justify-center bg-black px-[15px] pt-[50px] lg:pt-[80px]">
      {!isInputAuthCode && (
        <div className="w-[420px] sm:w-[550px] max-h-[90%] overflow-y-auto rounded-[30px] bg-white p-[10px] md:p-[50px] small:p-[10px]">
          <div className="flex items-center font-comfortaa font-bold text-center text-2xl md:text-4xl small:text-2xl mb-2.5">
            <Link to="/" className="group">
              <ArrowLeft className="w-[14px] h-[18px] md:w-[21px] md:h-[27px] fill-blackBase group-hover:fill-redBase" />
            </Link>

            <span className="grow text-center">Регистрация</span>
          </div>
          <div className="text-center sm:px-[30px] small:px-[0px] text-sm md:text-base text-greyBase mb-5 small:mb-3 md:mb-10">
            Зарегистрируйтесь, чтобы получить доступ к личному кабинету
          </div>
          <div className="mb-9">
            <div className="text-xs text-greyBase mb-1">Email</div>
            <Input
              classNameWrap=""
              placeholder="Введите Email"
              error={error}
              value={email}
              onKeyDown={onKeyDown}
              onChange={onChange}
              onBlur={onBlur}
            />
          </div>
          <div className="flex flex-col gap-[16px] mb-7 small:mb-5">
            <div className="flex gap-[14px]">
              <Checkbox
                label="Я подтверждаю, что у меня есть высшее психологическое образование"
                onChange={onAgreeWithRooles}
                error={errorAgreeWithRooles}
              />
            </div>
            <div className="flex gap-[14px]">
              <Checkbox
                label={
                  <p>
                    Я принимаю условия{' '}
                    <Link
                      to={`${LANDING}/Пользовательское_соглашение_ЛОГОСТЕРАПИ_REV.pdf`}
                      target="_blank"
                      className="underline">
                      Пользовательского соглашения
                    </Link>{' '}
                    и даю{' '}
                    <Link
                      to={`${LANDING}/Политика_Конфиденциальности_ЛОГОСТЕРАПИ_REV.pdf`}
                      target="_blank"
                      className="underline">
                      согласие на обработку персональных данных
                    </Link>
                  </p>
                }
                onChange={onAgreePrivacy}
                error={errorAgreePrivacy}
              />
            </div>
          </div>
          <div className="mb-[30px]">
            {/* <SmartCaptcha
              sitekey="ysc1_07b8BpiGLrNwgzmaFYddH8Zrosc4JgewfRHcAJTO89cd5994"
              onSuccess={(token) => {
                setCaptcha(token);
              }}
              key={resetCaptcha}
              onTokenExpired={() => {
                setCaptcha('');
                setResetCaptcha((prev) => prev + 1);
              }}
              language={'ru'}
            />  */}
            <HCaptcha
              sitekey="92e461fa-ac46-44ce-8a04-d85a6b185f9e"
              onVerify={(token, ekey) => setCaptcha(token)}
              key={resetCaptcha}
              onExpire={() => {
                setCaptcha('');
                setResetCaptcha((prev) => prev + 1);
              }}
            />
          </div>
          <div className="mb-7 small:mb-5">
            <button
              ref={refRegistration}
              className="font-comfortaa font-bold flex justify-center p-[15px] bg-redBase text-white md:text-xl w-full rounded-[10px] hover:bg-redBaseWithBlur20"
              onClick={onRegistration}>
              Зарегистрироваться
            </button>
          </div>
          <div className="text-center">
            <div className="text-sm md:text-base text-greyBase">Есть аккаунт?</div>
            <a href="/" className="text-sm md:text-base text-redBase underline">
              Войти
            </a>
          </div>
        </div>
      )}
      {isInputAuthCode && (
        <ConfirmAuth
          onBackToAuth={() => setIsInputAuthCode(false)}
          email={email}
          confirmCodex={true}
        />
      )}
    </div>
  );
};

export default Registration;
