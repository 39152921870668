import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from './api';
import { IClient, IConsultation, IConsultationsParams, IUser } from '../store/types';

export const usersApi = createApi({
  reducerPath: 'user',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['User', 'Users', 'Consultations'],
  endpoints: (build) => ({
    me: build.query<IUser, void>({
      query: () => ({
        url: '/users/me',
      }),
    }),
    getConsultation: build.query<IConsultation, string>({
      query: (id) => ({
        url: `/consultations/${id}`,
      }),
    }),
    getConsultationByCode: build.query<IConsultation, string>({
      query: (id) => ({
        url: `/consultations/get/${id}`,
      }),
    }),
    getConsultations: build.query<any[], IConsultationsParams>({
      providesTags: () => ['Consultations'],
      query: (params) => ({
        url: '/consultations',
        params: {
          ...params,
        },
      }),
    }),
    getAllConsultations: build.query<IConsultation[], void>({
      providesTags: () => ['Consultations'],
      query: () => ({
        url: '/consultations',
        params: {
          'sort[created_at]': 'desc',
        },
      }),
    }),
    createConsultation: build.mutation<IConsultation, Partial<IConsultation>>({
      query: (data) => ({
        url: `/consultations`,
        method: 'POST',
        body: JSON.stringify(data),
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Consultations']),
    }),
    changeConsultation: build.mutation<IConsultation, Partial<IConsultation>>({
      query: (data) => ({
        url: `/consultations/${data.id}`,
        method: 'PUT',
        body: JSON.stringify(data),
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Consultations']),
    }),
    getClient: build.query<IClient, string>({
      providesTags: (result, error) => ['User'],
      query: (id) => ({
        url: `/clients/${id}`,
      }),
    }),
    getClients: build.query<IClient[], { is_active?: boolean; full_name?: string }>({
      providesTags: (result, error) => ['Users'],
      query: (params) => ({
        url: '/clients',
        params: {
          is_active: params.is_active,
          full_name: params.full_name !== '' ? params.full_name : undefined,
        },
      }),
    }),
    changeUser: build.mutation<IUser, Partial<IUser>>({
      query: (data) => ({
        url: `/users/${data.id}`,
        method: 'PUT',
        body: JSON.stringify(data),
      }),
      invalidatesTags: (result, error) => (error ? [] : ['User', 'Users']),
    }),
    changeClient: build.mutation<IClient, Partial<IClient>>({
      query: (data) => ({
        url: `/clients/${data.id}`,
        method: 'PUT',
        body: JSON.stringify(data),
      }),
      invalidatesTags: (result, error) => (error ? [] : ['User', 'Users']),
    }),
    changeAvatar: build.mutation<string, { file: File }>({
      query: (data) => {
        console.log(data);
        const bodyFormData = new FormData();
        bodyFormData.append('file', data.file);
        console.log(bodyFormData);

        return {
          url: `/upload-avatar`,
          method: 'POST',
          body: bodyFormData,
          formData: true,
        };
      },
    }),
  }),
});

export const {
  useLazyMeQuery,
  useLazyGetConsultationQuery,
  useLazyGetConsultationByCodeQuery,
  useLazyGetConsultationsQuery,
  useLazyGetAllConsultationsQuery,
  useCreateConsultationMutation,
  useLazyGetClientQuery,
  useLazyGetClientsQuery,
  useChangeUserMutation,
  useChangeAvatarMutation,
  useChangeClientMutation,
  useChangeConsultationMutation,
} = usersApi;
