import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { apiSlice } from '../services/api';
import authSlice from './auth.slice';
import { usersApi } from '../services/users.api';
import { psyToolsApi } from '../services/psyTools.api';
import { postsApi } from '../services/posts.api';
import psyToolsSlice from './psyTools.slice';
import { ideasApi } from '../services/idea.api';

export const store = configureStore({
  reducer: {
    authSlice,
    psyToolsSlice,
    [apiSlice.reducerPath]: apiSlice.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [psyToolsApi.reducerPath]: psyToolsApi.reducer,
    [postsApi.reducerPath]: postsApi.reducer,
    [ideasApi.reducerPath]: ideasApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredPaths: ['/upload-avatar'],
      },
    })
      .concat(
        apiSlice.middleware,
        usersApi.middleware,
        psyToolsApi.middleware,
        postsApi.middleware,
        ideasApi.middleware
      ),
});

setupListeners(store.dispatch);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
