import { FC, useEffect, useState } from 'react';
import { Love } from '../../icons/reactions/love';
import { Joy } from '../../icons/reactions/joy';
import { Sadness } from '../../icons/reactions/sadness';
import { Fear } from '../../icons/reactions/fear';
import { Anger } from '../../icons/reactions/anger';
import Button from '../../common/button';
import { IPsyBlock } from '../new-questions';
import { CloseCircleCrossBtn } from '../../icons/close-circle-cross-btn';
import { useAppSelector } from '../../../hooks/redux';
import { socket } from '../../../libs/socket';
import { declOfNum } from '../../../utils/decOfNum';
import { useParams } from 'react-router-dom';

export const DetectorEmotion: FC<IPsyBlock> = ({ onSetMenu, setResult, result }) => {
  const psyTools = useAppSelector((state) => state.psyToolsSlice.tools);
  const params = useParams();
  const roomName = params.id;
  const [reactionId, setReactionId] = useState<string[]>([]);
  const [resultEmo, setResultEmo] = useState<string[]>([]);
  const [resultCategory, setResultCategory] = useState<string[]>([]);
  const [menu1Step, setMenu1Step] = useState(false);

  const selectEmo = (a: string) => {
    if (reactionId.includes(a)) {
      setReactionId((prev) => prev.filter((el) => el !== a));
      return;
    }
    setReactionId((prev) => [...prev, a]);
  };

  const getLengthOfEmo = (a: string) => {
    const settings = psyTools.find((el) => el.name === 'emotions_detection')?.psy_tool_settings;
    const arr = settings?.find((el) => el.name === a)?.value;

    return arr?.length || 0;
  };

  const close = () => {
    socket.emit('message', {
      roomId: roomName,
      message: {
        datetime: new Date().toISOString(),
        action: 'close_tool',
        data: 'emotions_detection',
      },
    });
    onSetMenu(0);
    setReactionId([]);
    setResultEmo([]);
    setMenu1Step(false);
  };

  useEffect(() => {
    socket.on('message', (val: { message: { action: string; data: any; datetime: string } }) => {
      if (val.message.action === 'result_tool' && val.message.data?.name === 'emotions_detection') {
        setResultEmo(val.message.data?.result.emo);
        setResultCategory(val.message.data?.result.category);
      }
    });
    return () => {
      socket.off('message', (val: any) => console.log(val));
    };
  }, []);

  const settings = psyTools.find((el) => el.name === 'emotions_detection')?.psy_tool_settings;

  useEffect(() => {
    if (resultCategory.length === 0) return;
    const data = settings?.filter((el) => resultCategory.includes(el.name));
    const data1 = data?.map((el) => {
      return { ...el, value: el.value.filter((el) => resultEmo.includes(el)) };
    });
    const data2 = data1
      ?.filter((el) => el.value.length > 0)
      .map((el) => {
        return { category: el.name, items: el.value };
      });
    const res = {
      date_time: new Date().toISOString(),
      comment: '',
      emotions: data2 || [],
    };
    setResult &&
      setResult((prev) => {
        return { ...prev, emotions: [...prev.emotions, res] };
      });
  }, [resultCategory]);

  return (
    <>
      <div className="text-white text-sm lg:text-2xl font-comfortaa font-bold flex justify-between mb-2.5">
        Детектор эмоций
        {resultEmo.length > 0 && (
          <button
            className=" w-[20px] h-[20px] md:min-w-[30px] md:min-h-[30px] md:mt-px"
            onClick={() => close()}>
            <CloseCircleCrossBtn className="w-full h-full" />
          </button>
        )}
      </div>
      {!menu1Step ? (
        <div className="text-xs lg:text-lg text-white">
          Вы уверены, что хотите запросить у клиента его эмоциональное состояние в данный момент?
        </div>
      ) : (
        <div className="scroll-white overflow-y-auto mb-2.5">
          {resultEmo.length > 0 ? (
            <div className=" flex gap-[15px] flex-wrap md:flex-col md:items-center w-full">
              {resultEmo.map((el, idx) => (
                <div
                  className="font-semiBold lg:font-medium text-white text-xs lg:text-lg rounded-[5px] bg-blackBaseOpacity20 p-[2px_10px] lg:p-[5px_10px] w-full text-center max-w-[240px]"
                  key={idx}>
                  {el}
                </div>
              ))}
            </div>
          ) : (
            <div className=" flex flex-col justify-between h-full gap-2">
              <p className="font-semiBold lg:font-medium text-white text-xs lg:text-lg mb-2.5">
                Клиент выбирает из списка эмоций. Выбранные эмоции отобразятся здесь.
              </p>

              <div className="flex flex-col gap-4 hover:cursor-pointer">
                <div className="flex flex-col gap-4">
                  <div
                    className="flex items-center gap-[15px] hover:cursor-pointer"
                    onClick={() => selectEmo('Любовь')}>
                    <Love className="w-[50px] h-[50px] lg:w-[70px] lg:h-[70px]" stroke="#fff" />
                    <span className="text-white">
                      Любовь
                      <br />
                      {`(${getLengthOfEmo('Любовь')} ${declOfNum(getLengthOfEmo('Любовь'), [
                        'эмоция',
                        'эмоции',
                        'эмоций',
                      ])})`}
                    </span>
                  </div>
                  {reactionId.includes('Любовь') && (
                    <div className="md:w-auto">
                      <div className="flex md:flex-col gap-[15px_12px] flex-wrap items-center">
                        {settings
                          ?.find((el) => el.name === 'Любовь')
                          ?.value.map((item, idx) => (
                            <div
                              key={idx}
                              className="lg:w-full max-w-[240px] text-center text-xs lg:text-base text-white font-semibold bg-blackBaseOpacity20 rounded-[5px] p-[2px_10px]">
                              {item}
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                </div>

                <div className="flex flex-col gap-4">
                  <div
                    className="flex items-center gap-[15px]"
                    onClick={() => selectEmo('Радость')}>
                    <Joy className="w-[50px] h-[50px] lg:w-[70px] lg:h-[70px]" stroke="#fff" />
                    <span className="text-white">
                      Радость <br />
                      {`(${getLengthOfEmo('Радость')} ${declOfNum(getLengthOfEmo('Радость'), [
                        'эмоция',
                        'эмоции',
                        'эмоций',
                      ])})`}
                    </span>
                  </div>
                  {reactionId.includes('Радость') && (
                    <div className="md:w-auto">
                      <div className="flex md:flex-col gap-[15px_12px] flex-wrap items-center">
                        {settings
                          ?.find((el) => el.name === 'Радость')
                          ?.value.map((item, idx) => (
                            <div
                              key={idx}
                              className="lg:w-full max-w-[240px] text-center text-xs lg:text-base text-white font-semibold bg-blackBaseOpacity20 rounded-[5px] p-[2px_10px]">
                              {item}
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                </div>

                <div className="flex flex-col gap-4">
                  <div className="flex items-center gap-[15px]" onClick={() => selectEmo('Грусть')}>
                    <Sadness className="w-[50px] h-[50px] lg:w-[70px] lg:h-[70px]" stroke="#fff" />
                    <span className="text-white">
                      Грусть <br />
                      {`(${getLengthOfEmo('Грусть')} ${declOfNum(getLengthOfEmo('Грусть'), [
                        'эмоция',
                        'эмоции',
                        'эмоций',
                      ])})`}
                    </span>
                  </div>
                  {reactionId.includes('Грусть') && (
                    <div className="md:w-auto">
                      <div className="flex md:flex-col gap-[15px_12px] flex-wrap items-center">
                        {settings
                          ?.find((el) => el.name === 'Грусть')
                          ?.value.map((item, idx) => (
                            <div
                              key={idx}
                              className="lg:w-full max-w-[240px] text-center text-xs lg:text-base text-white font-semibold bg-blackBaseOpacity20 rounded-[5px] p-[2px_10px]">
                              {item}
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                </div>

                <div className="flex flex-col gap-4">
                  <div className="flex items-center gap-[15px]" onClick={() => selectEmo('Страх')}>
                    <Fear className="w-[50px] h-[50px] lg:w-[70px] lg:h-[70px]" stroke="#fff" />
                    <span className="text-white">
                      Страх
                      <br />
                      {`(${getLengthOfEmo('Страх')} ${declOfNum(getLengthOfEmo('Страх'), [
                        'эмоция',
                        'эмоции',
                        'эмоций',
                      ])})`}
                    </span>
                  </div>
                  {reactionId.includes('Страх') && (
                    <div className="md:w-auto">
                      <div className="flex md:flex-col gap-[15px_12px] flex-wrap items-center">
                        {settings
                          ?.find((el) => el.name === 'Страх')
                          ?.value.map((item, idx) => (
                            <div
                              key={idx}
                              className="lg:w-full max-w-[240px] text-center text-xs lg:text-base text-white font-semibold bg-blackBaseOpacity20 rounded-[5px] p-[2px_10px]">
                              {item}
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                </div>

                <div className="flex flex-col gap-4">
                  <div className="flex items-center gap-[15px]" onClick={() => selectEmo('Гнев')}>
                    <Anger className="w-[50px] h-[50px] lg:w-[70px] lg:h-[70px]" stroke="#fff" />
                    <span className="text-white">
                      Гнев
                      <br />
                      {`(${getLengthOfEmo('Гнев')} ${declOfNum(getLengthOfEmo('Гнев'), [
                        'эмоция',
                        'эмоции',
                        'эмоций',
                      ])})`}
                    </span>
                  </div>
                  {reactionId.includes('Гнев') && (
                    <div className="md:w-auto">
                      <div className="flex md:flex-col gap-[15px_12px] flex-wrap items-center">
                        {settings
                          ?.find((el) => el.name === 'Гнев')
                          ?.value.map((item, idx) => (
                            <div
                              key={idx}
                              className="lg:w-full max-w-[240px] text-center text-xs lg:text-base text-white font-semibold bg-blackBaseOpacity20 rounded-[5px] p-[2px_10px]">
                              {item}
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {resultEmo.length === 0 && (
        <div className="mt-auto">
          {!menu1Step && (
            <Button
              className="!text-[13px] lg:!text-sm p-[6px_10px] mb-[10px] lg:mb-3"
              onClick={() => {
                setMenu1Step(true);
                const name = psyTools.find((el) => el.name === 'emotions_detection')?.name;
                socket.emit('message', {
                  roomId: roomName,
                  message: {
                    datetime: new Date().toISOString(),
                    action: 'open_tool',
                    data: name,
                  },
                });
              }}>
              Запросить
            </Button>
          )}
          <Button
            type="grey"
            className="!text-[13px] lg:!text-sm p-[6px_10px]"
            onClick={() => close()}>
            Закрыть
          </Button>
        </div>
      )}
    </>
  );
};
