import React, { FC } from 'react';
import { IconProps } from '../../types/icon.types';
import classNames from 'classnames';

export const SquaresIcon: FC<IconProps> = ({
  width = 52,
  height = 52,
  fill = 'white',
  className,
  needHover = true,
}) => {
  return (
    <svg
      className={classNames(className, {
        'hover:cursor-pointer': needHover,
      })}
      width={width}
      height={height}
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19 15V7C19 5.89543 19.8954 5 21 5H29C30.1046 5 31 5.89543 31 7V15C31 16.1046 30.1046 17 29 17H21C19.8954 17 19 16.1046 19 15Z"
        fill={fill}
      />
      <path
        d="M0 10V2C0 0.895431 0.895431 0 2 0H10C11.1046 0 12 0.895431 12 2V10C12 11.1046 11.1046 12 10 12H2C0.895431 12 0 11.1046 0 10Z"
        fill={fill}
      />
      <path
        d="M0 33V25C0 23.8954 0.895431 23 2 23H10C11.1046 23 12 23.8954 12 25V33C12 34.1046 11.1046 35 10 35H2C0.895431 35 0 34.1046 0 33Z"
        fill={fill}
      />
      <path
        d="M40 50V42C40 40.8954 40.8954 40 42 40H50C51.1046 40 52 40.8954 52 42V50C52 51.1046 51.1046 52 50 52H42C40.8954 52 40 51.1046 40 50Z"
        fill={fill}
      />
      <path
        d="M40 35V27C40 25.8954 40.8954 25 42 25H50C51.1046 25 52 25.8954 52 27V35C52 36.1046 51.1046 37 50 37H42C40.8954 37 40 36.1046 40 35Z"
        fill={fill}
      />
      <path
        d="M25 35V27C25 25.8954 25.8954 25 27 25H35C36.1046 25 37 25.8954 37 27V35C37 36.1046 36.1046 37 35 37H27C25.8954 37 25 36.1046 25 35Z"
        fill={fill}
      />
      <path
        d="M25 50V42C25 40.8954 25.8954 40 27 40H35C36.1046 40 37 40.8954 37 42V50C37 51.1046 36.1046 52 35 52H27C25.8954 52 25 51.1046 25 50Z"
        fill={fill}
      />
      <path
        d="M10 50V42C10 40.8954 10.8954 40 12 40H20C21.1046 40 22 40.8954 22 42V50C22 51.1046 21.1046 52 20 52H12C10.8954 52 10 51.1046 10 50Z"
        fill={fill}
      />
      <path
        d="M40 20V12C40 10.8954 40.8954 10 42 10H50C51.1046 10 52 10.8954 52 12V20C52 21.1046 51.1046 22 50 22H42C40.8954 22 40 21.1046 40 20Z"
        fill={fill}
      />
    </svg>
  );
};
