import React, { useEffect } from 'react';
import { Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Login from './pages/login';
import Registration from './pages/registration';
import UIKit from './pages/ui-kit';
import Meet from './pages/meet';
import Profile from './pages/profile';
import Loader from './components/common/loader';
import PrivateRouter from './components/private-router';
import { useLazyMeQuery } from './services/users.api';
import { useAppDispatch, useAppSelector } from './hooks/redux';
import { RouteNames } from './configs/routes/routesNames';
import { logout, selectUser, setCredentials, setUserData } from './store/auth.slice';
import { toast, ToastContainer } from 'react-toastify';
import Header from './components/header';
import Layout from './components/layout';
import Consultations from './pages/Consultations';
import { useLazyGetToolsQuery } from './services/psyTools.api';
import { setTools } from './store/psyTools.slice';
import { EndMeetPage } from './pages/end-call';
import LinkRoom from './components/test/test';
import Info from './pages/info';
import Tools from './pages/tools';
import EmotionDetector from './pages/tools/emotion-detector';
import Dpdg from './pages/tools/dpdg';
import Question from './pages/tools/question';
import MetaphoricalCards from './pages/tools/metaphorical-cards';
import Post from './pages/post';
import Tariffs from './pages/tariffs';
import Journal from './pages/journal';
import JournalCard from './pages/journal/journal-card';
import dayjs from 'dayjs';
dayjs.locale('ru');

export const tokensLocal = JSON.parse(localStorage.getItem('tokensZZ') || '{}');
function App() {
  const [me, { data: data, error }] = useLazyMeQuery();
  const userData = useAppSelector(selectUser);
  const [getTools, { data: psyData }] = useLazyGetToolsQuery();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    getTools();
    if (JSON.parse(localStorage.getItem('tokensZZ') || '{}')) {
      if (Object.keys(JSON.parse(localStorage.getItem('tokensZZ') || '{}')).length === 0) return;
      dispatch(setCredentials(tokensLocal));
      me();
    } else {
      navigate(RouteNames.AUTHORIZATION);
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (psyData) {
      dispatch(setTools(psyData));
    }
    // eslint-disable-next-line
  }, [psyData]);

  useEffect(() => {
    // @ts-ignore
    if (error && error?.status === 422) {
      toast.error(
        'Ваш аккаунт заблокирован, чтобы разблокировать, обратитесь к администратору сервиса info@zingizi.pro'
      );
      dispatch(logout());
      navigate(RouteNames.AUTHORIZATION);
    }
    // eslint-disable-next-line
  }, [error]);

  useEffect(() => {
    if (data) {
      dispatch(setUserData(data));
    }
    // eslint-disable-next-line
  }, [data]);

  if (JSON.parse(localStorage.getItem('tokensZZ') || '{}')?.token && !userData) {
    return <Loader />;
  }

  return (
    <>
      <Routes>
        <Route
          element={
            <>
              <Header />
              <Outlet />
            </>
          }>
          <Route index path="/" element={<Login />} />
          <Route path="/registration" element={<Registration />} />
        </Route>
        <Route path="/test/:id" element={<LinkRoom />} />
        <Route path="/meet/:id" element={<Meet />} />
        <Route index path="/end-call/:id/:success?" element={<EndMeetPage />} />
        <Route element={<PrivateRouter />}>
          <Route element={<Layout />}>
            <Route index path="/profile" element={<Profile />} />
            <Route index path="/consultations" element={<Consultations />} />
            <Route path="/journal" element={<Journal />}>
              <Route path=":id" element={<JournalCard />} />
            </Route>

            <Route path="/tools" element={<Tools />}>
              <Route path="emotion-detector" element={<EmotionDetector />} />
              <Route path="metaphorical-cards" element={<MetaphoricalCards />} />
              <Route path="question" element={<Question />} />
              <Route path="dpdg" element={<Dpdg />} />
            </Route>
            <Route index path="/tariffs" element={<Tariffs />} />
            <Route path="/info" element={<Info />}>
              <Route path=":id" element={<Post />} />
            </Route>
          </Route>
          <Route path="/ui-kit" element={<UIKit />} />
        </Route>
        <Route
          path={'*'}
          element={<div className="flex py-[100px]">Упс, такой страницы не существует</div>}
        />
      </Routes>
      <ToastContainer />
    </>
  );
}

export default App;
