import React, { FC } from 'react';
import { IconProps } from '../../types/icon.types';

export const MoveTopRight: FC<IconProps> = ({
  width = 27,
  height = 22,
  fill = '#111026',
  className,
}) => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="30" height="30" rx="6" fill="#F7F7F7" />
      <path
        d="M7.25 22C7.25 22.4142 7.58579 22.75 8 22.75H14.75C15.1642 22.75 15.5 22.4142 15.5 22C15.5 21.5858 15.1642 21.25 14.75 21.25H8.75V15.25C8.75 14.8358 8.41421 14.5 8 14.5C7.58579 14.5 7.25 14.8358 7.25 15.25V22ZM22.75 8C22.75 7.58579 22.4142 7.25 22 7.25H15.25C14.8358 7.25 14.5 7.58579 14.5 8C14.5 8.41421 14.8358 8.75 15.25 8.75H21.25V14.75C21.25 15.1642 21.5858 15.5 22 15.5C22.4142 15.5 22.75 15.1642 22.75 14.75V8ZM8.53033 22.5303L22.5303 8.53033L21.4697 7.46967L7.46967 21.4697L8.53033 22.5303Z"
        fill="#C00000"
      />
    </svg>
  );
};
