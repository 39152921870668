import { FC } from 'react';

interface SwitchProps {
  label?: string;
  checked?: boolean;
  setChecked?: (a: boolean) => void;
}

export const Switch: FC<SwitchProps> = ({ label, checked, setChecked }) => {
  return (
    <div
      className="w-max cursor-pointer flex gap-5 items-center"
      onClick={() => {
        setChecked && setChecked(!checked);
      }}>
      <div className="relative flex flex-col items-center justify-center min-h-[30px] overflow-hidden shrink-0">
        <div className="flex">
          <label className="flex relative items-center cursor-pointer">
            <input
              type="radio"
              className="sr-only peer"
              checked={checked}
              readOnly={true}
              disabled={true}
            />
            <span
              className="w-[50px] h-[30px] bg-[#E0E0E8] rounded-[16px] peer  peer-focus:ring-redBase peer-checked:after:translate-x-[20px] peer-checked:after:border-redBase peer-checked:after:bg-redBase after:content-[''] after:absolute after:top-[5px] after:left-[5px] after:bg-[#585858] after:border-[#585858] after:border after:rounded-full after:h-[20px] after:w-[20px] after:shadow-switch after:transition-all
            hover:bg-[#b3b4b9]
            "></span>
          </label>
        </div>
      </div>
      <p className="text-base text-blackBase font-medium">{label}</p>
    </div>
  );
};
