import { FC, useState } from 'react';
import { useAppSelector } from '../../../hooks/redux';
import Button from '../../common/button';
import classNames from 'classnames';
import { IJournalResult } from '../../../store/types';

export interface IPsyBlock {
  result?: IJournalResult;
  setResult?: React.Dispatch<React.SetStateAction<IJournalResult>>;
  onSetMenu: (a: number) => void;
  setShowCards?: (b: boolean) => void;
}

export const NewQuestions: FC<IPsyBlock> = ({ onSetMenu, result, setResult }) => {
  const psyTools = useAppSelector((state) => state.psyToolsSlice.tools);
  const [questions, setQuestions] = useState<string[]>([]);
  const [finishQue, setFinishQue] = useState(false);
  const getQuestions = async () => {
    try {
      const new_question_block = psyTools.find((el) => el.name === 'new_question');
      const psy_questions = new_question_block?.psy_tool_settings[0].value || [];
      if (questions?.length !== 30) {
        const filtred_que = psy_questions.filter((el) => !questions.includes(el));
        if (filtred_que.length === 1) {
          const random_question = filtred_que[Math.floor(Math.random() * filtred_que.length)];
          setQuestions((prev) => [random_question, ...prev]);
          setFinishQue(true);
          return;
        }
        const random_question = filtred_que[Math.floor(Math.random() * filtred_que.length)];
        setQuestions((prev) => [random_question, ...prev]);
        const data = { date_time: new Date().toISOString(), question: random_question };

        setResult &&
          setResult((prev) => {
            return {
              ...prev,
              questions: [...prev.questions, data],
            };
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const setMenu = (a: number) => {
    onSetMenu(a);
    setQuestions([]);
    setFinishQue(false);
  };
  return (
    <>
      <div className="text-white text-sm lg:text-xl font-comfortaa font-bold mb-3">
        Подкинь вопрос
      </div>
      <div className="text-xs lg:text-base text-white mb-5">Вопросы видит только психолог</div>
      <div className="flex flex-col gap-[13px] md:gap-[20px] lg:gap-[15px] overflow-y-auto pr-[10px] white-scroll mb-5">
        {questions.map((el, idx) => (
          <div
            className={classNames(
              'text-xs lg:text-base text-white bg-blackBlue rounded-[5px] lg:rounded-[20px] p-[10px] lg:p-[20px]',
              {
                '!text-[#000] !bg-white': idx === 0,
              }
            )}
            key={idx}>
            {el}
          </div>
        ))}
      </div>
      <div className="mt-auto">
        <Button
          onClick={() => getQuestions()}
          className="!text-[13px] lg:!text-sm p-[6px_10px] mb-[10px] lg:mb-3"
          disabled={finishQue || questions.length === 30}>
          Покажи новый вопрос
        </Button>
        <Button
          type="grey"
          className="!text-[13px] lg:!text-sm p-[6px_10px] "
          onClick={() => {
            setMenu(0);
          }}>
          Закрыть
        </Button>
      </div>
    </>
  );
};
