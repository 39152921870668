import { useEffect, useState } from 'react'

type TimeRemaining = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

const useTimeRemaining = (targetDate: Date): TimeRemaining => {
  const calculateTimeRemaining = (): TimeRemaining => {
    const now = new Date();
    const utcDate = new Date(targetDate.getTime() + targetDate.getTimezoneOffset() * 60000)
    const difference = utcDate.getTime() - now.getTime();

    if (difference <= 0) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  };

  const [timeRemaining, setTimeRemaining] = useState<TimeRemaining>(calculateTimeRemaining());

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(interval);
  // eslint-disable-next-line
  }, [targetDate]);

  return timeRemaining;
};

export default useTimeRemaining