import React, { FC } from 'react';
import { IconProps } from '../../types/icon.types';

export const MoveTopLeft: FC<IconProps> = ({
  width = 27,
  height = 22,
  fill = '#111026',
  className,
}) => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="30" height="30" rx="6" fill="#F7F7F7" />
      <path
        d="M8 7.25C7.58579 7.25 7.25 7.58579 7.25 8L7.25 14.75C7.25 15.1642 7.58579 15.5 8 15.5C8.41421 15.5 8.75 15.1642 8.75 14.75L8.75 8.75L14.75 8.75C15.1642 8.75 15.5 8.41421 15.5 8C15.5 7.58579 15.1642 7.25 14.75 7.25L8 7.25ZM22 22.75C22.4142 22.75 22.75 22.4142 22.75 22L22.75 15.25C22.75 14.8358 22.4142 14.5 22 14.5C21.5858 14.5 21.25 14.8358 21.25 15.25L21.25 21.25L15.25 21.25C14.8358 21.25 14.5 21.5858 14.5 22C14.5 22.4142 14.8358 22.75 15.25 22.75L22 22.75ZM7.46967 8.53033L21.4697 22.5303L22.5303 21.4697L8.53033 7.46967L7.46967 8.53033Z"
        fill="#C00000"
      />
    </svg>
  );
};
