import {
  useChangeClientMutation,
  useLazyGetAllConsultationsQuery,
  useLazyGetClientsQuery,
  useLazyGetConsultationsQuery,
} from '../services/users.api';
import { useEffect, useRef, useState } from 'react';
import { tokensLocal } from '../App';
import { randomString } from '../utils/randomString';
import axios from 'axios';
import { useAppSelector } from '../hooks/redux';
import { selectUser } from '../store/auth.slice';
import { toast } from 'react-toastify';
import { getDateForFilter, getTime } from '../utils/getTime';
import { CreateClientForm, IFormDataClient } from '../components/create-client-form';
import { timezoneList } from '../assets/timezone';
import { SettingsBlock } from '../components/settings';
import { ConsultationTable } from '../components/consultation/consultation-table';
import { IClient, IConsultation } from '../store/types';
import { Schedule } from '../components/consultation/shedule';
import { ConsultationForm } from '../components/consultation/consultation-form';

export type FormDataConsultations = {
  id?: number;
  client: string;
  date: string;
  time_start: string;
  time_end: string;
  tz: string;
  link: string;
  tzOffset?: number;
  code?: string;
};

const Consultations = () => {
  const userData = useAppSelector(selectUser);
  const [getClients, { data: clients }] = useLazyGetClientsQuery();
  const [getConsultations, { data: consultations }] = useLazyGetConsultationsQuery();
  const [getAllConsultations, { data: allConsultations }] = useLazyGetAllConsultationsQuery();
  const [changeClient] = useChangeClientMutation();
  const [isCreate, setIsCreate] = useState(false);
  const [openCreateClient, setOpenCreateClient] = useState(false);
  const [isEditOnCalendar, setIsEditOnCalendar] = useState(false);

  const [clientsList, setClientsList] = useState<{ id: number; name: string }[]>([]);

  const [consultationList, setConsultationList] = useState<IConsultation[]>([]);
  const [allConsultationList, setAllConsultationList] = useState<IConsultation[]>([]);
  const [allConsultationListToday, setAllConsultationListToday] = useState<any[]>([]);

  const [openSettings, setOpenSettings] = useState(false);
  const [isReturnForm, setIsReturnForm] = useState(false);
  const [isReturnCalendar, setIsReturnCalendar] = useState(false);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [editedClient, setEditedClient] = useState<IClient | undefined>();

  useEffect(() => {
    if (allConsultations) {
      setAllConsultationList(allConsultations);
    }
  }, [allConsultations]);

  useEffect(() => {
    sortConsultation();
  }, [consultations]);

  const sortConsultation = () => {
    if (consultations) {
      setAllConsultationListToday(
        consultations
          .map((i) => i)
          .sort((a, b) => {
            const firstDate = new Date(a.datetime_end);
            const secondDate = new Date(b.datetime_end);
            const utcFirstDate = new Date(
              firstDate.getTime() + firstDate.getTimezoneOffset() * 60000
            );
            const utcSecondDate = new Date(
              secondDate.getTime() + secondDate.getTimezoneOffset() * 60000
            );

            if (utcFirstDate > new Date() && utcSecondDate > new Date()) {
              return firstDate.getTime() - secondDate.getTime();
            } else if (firstDate < new Date() && secondDate < new Date()) {
              return firstDate.getTime() - secondDate.getTime();
            } else {
              return utcFirstDate > new Date() ? -1 : 1;
            }
          })
      );
    }
  };

  useEffect(() => {
    if (consultations) {
      setConsultationList(consultations);
    }
  }, [consultations]);

  useEffect(() => {
    if (clients) {
      const arr = clients.map((el) => {
        return {
          id: el.id,
          name: `${el.full_name} ${el.phone || el.email ? '-' : ''} ${
            el.phone ? el.phone : el.email ? el.email : ''
          }`,
        };
      });
      setClientsList(arr);
    }
  }, [clients]);

  const [formData, setFormData] = useState<FormDataConsultations>({
    client: '',
    date: '',
    time_start: '',
    time_end: '',
    tz: '',
    link: '',
  });

  useEffect(() => {
    if (tokensLocal) {
      getConsultations({
        'date[before]': getDateForFilter(new Date()).end,
        'date[after]': getDateForFilter(new Date()).start,
        'sort[datetime_start]': 'asc',
      });
      getAllConsultations();
      getClients({});
    }
    // eslint-disable-next-line
  }, []);

  const getToken = () => localStorage.getItem('tokensZZ');

  const handleSaveClient = async (formDataClient: IFormDataClient) => {
    try {
      const data = {
        email: formDataClient.email,
        full_name: formDataClient.name,
        phone: formDataClient.phone,
        birthday: formDataClient.date ? formDataClient.date : null,
        time_zone: formDataClient.tz,
        telegram: formDataClient.tg,
        vk: formDataClient.vk,
        note: formDataClient.info,
        is_active: formDataClient.active,
        psychologists: [`/api/v1/users/${userData?.id}`],
      };
      if (editedClient) {
        await changeClient({ ...data, id: editedClient.id });
      }
      if (!editedClient) {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/clients`, data, {
          headers: {
            // @ts-ignore
            Authorization: `Bearer ${JSON.parse(getToken()).token}`,
          },
        });
        if (res.status >= 200 && res.status < 300) {
          const newEl = {
            id: res.data.id,
            name: `${formDataClient.name} ${
              formDataClient.phone || formDataClient.email ? '-' : ''
            } ${
              formDataClient.phone
                ? formDataClient.phone
                : formDataClient.email
                ? formDataClient.email
                : ''
            }`,
          };
          getClients({});
          setOpenCreateClient(false);

          setClientsList((prev) => [...prev, newEl]);
          setFormData({ ...formData, client: newEl.name, tz: data.time_zone });
          setIsCreate(true);
        }
      }

      setOpenCreateClient(false);
      setEditedClient(undefined);
      toast.success(!editedClient ? 'Клиент успешно создан' : 'Клиент успешно отредактирован');
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeCons = (id: number, isEdit?: boolean) => {
    const current = openCalendar
      ? allConsultationList.find((el) => el.id === id)
      : consultationList.find((el) => el.id === id);
    if (!current) return;
    const client = clientsList.find((el) => `/api/v1/clients/${el.id}` === current.client_entity);
    const tzOffset = timezoneList.find((el) => el.name === current.client_timezone)?.offset;
    const data = {
      id,
      client: client?.name ? client?.name : '',
      date: current.date,
      time_start:
        openCalendar && !isEdit
          ? current.datetime_start
          : getTime(new Date(current.datetime_start)),
      time_end:
        openCalendar && !isEdit ? current.datetime_end : getTime(new Date(current.datetime_end)),
      tz: current.client_timezone,
      tzOffset: Number(tzOffset),
      link: current.link,
      code: current.code,
    };
    setFormData(data);
    setIsCreate(true);
  };

  const closeClientForm = () => {
    if (!editedClient) {
      setIsCreate(true);
    }
    setEditedClient(undefined);
    setOpenCreateClient(false);
  };

  const closeForm = () => {
    setIsCreate(false);
    setIsEditOnCalendar(false);
    setFormData({
      client: '',
      date: '',
      time_start: '',
      time_end: '',
      tz: '',
      link: '',
    });
  };

  const openForm = () => {
    setFormData({
      ...formData,
      tz: '(GMT +03:00) Москва',
      link: `${process.env.REACT_APP_API_URL}/meet/${randomString(5)}`,
    });
    setIsCreate(true);
    setIsEditOnCalendar(true);
  };

  const handleOpenSettings = () => {
    if (openCalendar) {
      if (formData.id) {
        setIsReturnForm(true);
      }
      setIsReturnCalendar(true);
      setOpenCalendar(false);
    } else {
      setIsReturnForm(true);
    }
    setIsCreate(false);
    setOpenSettings(true);
  };

  const openEditFormClient = (id: number) => {
    const curr = clients?.find((el) => el.id === id);
    if (curr) {
      setEditedClient(curr);
      setOpenCreateClient(true);
    }
  };

  return (
    <div className="flex flex-col relative h-auto w-full">
      {openCalendar ? (
        <Schedule
          handleChangeCons={handleChangeCons}
          formData={formData}
          setFormData={setFormData}
          consultations={allConsultationList}
          clients={clientsList}
          close={() => setOpenCalendar(false)}
          openCreateForm={() => openForm()}
        />
      ) : (
        <ConsultationTable
          openEditFormClient={openEditFormClient}
          openForm={openForm}
          setOpenSettings={setOpenSettings}
          setOpenCalendar={setOpenCalendar}
          handleChangeCons={handleChangeCons}
          consultationList={allConsultationListToday || []}
          clientsList={clientsList}
          sortConsultation={sortConsultation}
        />
      )}

      <ConsultationForm
        isCreate={isCreate}
        setIsCreate={setIsCreate}
        setOpenCreateClient={setOpenCreateClient}
        isEditOnCalendar={isEditOnCalendar}
        openCalendar={openCalendar}
        formData={formData}
        setFormData={setFormData}
        consultationList={consultationList}
        allConsultationList={allConsultationList}
        clientsList={clientsList}
        clients={clients || []}
        closeForm={closeForm}
        handleOpenSettings={handleOpenSettings}
        handleChangeCons={handleChangeCons}
        setIsEditOnCalendar={setIsEditOnCalendar}
        setConsultationList={setConsultationList}
      />
      <CreateClientForm
        clientData={editedClient}
        open={openCreateClient}
        close={closeClientForm}
        save={(form) => handleSaveClient(form)}
      />

      {openSettings && (
        <SettingsBlock
          closeSettings={() => {
            if (isReturnCalendar) {
              if (isReturnForm) {
                setIsCreate(true);
              }
              setOpenCalendar(true);
              setOpenSettings(false);
              setIsReturnCalendar(false);
              return;
            }
            if (isReturnForm) {
              setIsCreate(true);
              setOpenSettings(false);
              setIsReturnForm(false);
              return;
            }
            setOpenSettings(false);
          }}
        />
      )}
    </div>
  );
};

export default Consultations;
