import { timezoneList } from '../assets/timezone';

export const getTime = (date: Date) => {
  const userTimezoneOffset = date.getTimezoneOffset() * 60000;
  const newDate = new Date(date.getTime() + userTimezoneOffset);
  const h = newDate.getHours();
  const m = newDate.getMinutes();
  return `${h < 10 ? `0${h}` : `${h}`}:${m < 10 ? `0${m}` : `${m}`}`;
};

export const getTimeTzNoLess = (date: Date) => {
  const newDate = new Date(date.getTime());
  const h = newDate.getHours();
  const m = newDate.getMinutes();
  return `${h < 10 ? `0${h}` : `${h}`}:${m < 10 ? `0${m}` : `${m}`}`;
};

export const getMyTimeZone = () => {
  const count = new Date().getTimezoneOffset() / -60;
  const curr = timezoneList.find((el) => el.offset === count);
  return curr?.name;
};

export const getTimeWithTZ = (date: Date, offset: number) => {
  // create Date object for current location
  var d = date;

  // convert to msec
  // subtract local time zone offset
  // get UTC time in msec
  var utc = d.getTime() + d.getTimezoneOffset() * 60000;

  // create new Date object for different city
  // using supplied offset
  var nd = new Date(utc + 3600000 * offset);

  // return time as a string
  return getTime(nd);
};

export const getMonday = (d: Date) => {
  const dt = new Date(d); // Create a Date instance from the input date
  const day = dt.getDay(); // Get the day of the week (0 for Sunday, 1 for Monday, and so on)
  const diff = dt.getDate() - day + (day === 0 ? -6 : 1); // Calculate the difference to Monday
  return new Date(dt.setDate(diff)); // Set the date to the calculated Monday
};

export const weekDates = (date: Date) => {
  let d = +(date || new Date()),
    days = [],
    i = 6;
  while (i--) {
    days.push(new Date((d += 8.64e7)));
  }
  return days;
};

export const addDateBy = (date: Date, count: number) => {
  const d = date;
  return new Date(d.setDate(d.getDate() + count));
};
export const lessDateBy = (date: Date, count: number) => {
  const d = date;
  return new Date(d.setDate(d.getDate() - count));
};

export const getDate = (date: Date, separator = '.') => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${day < 10 ? `0${day}` : `${day}`}${separator}${
    month < 10 ? `0${month}` : `${month}`
  }${separator}${year}`;
};

export const getDateForFilter = (date: Date) => {
  const updDate = getDate(date);
  const arr = updDate.split('.');
  return {
    start: `${arr[2]}-${arr[1]}-${arr[0]} 00:00`,
    end: `${arr[2]}-${arr[1]}-${arr[0]} 23:59`,
  };
};
