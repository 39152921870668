import { useEffect, useRef, useState } from 'react';
import Button from '../common/button';
import { CustomSelect } from '../common/select';
import { CloseCrossBtn } from '../icons/close-cross-btn';
import { toast } from 'react-toastify';
import { selectUser, setUserData } from '../../store/auth.slice';
import { EchoTest } from '../../hooks/useEchoTest';
import { randomString } from '../../utils/randomString';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { useChangeUserMutation } from '../../services/users.api';

const LinkRoom = () => {
  const echoTestRef = useRef<any>(null);
  const localVideoRef = useRef<any>(null);
  const remoteVideoRef = useRef<any>(null);
  const userData = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const [changeUser] = useChangeUserMutation();
  const [audioDevices, setAudioDevices] = useState<any[]>([]);
  const [videoDevices, setVideoDevices] = useState<any[]>([]);
  const [audioOutputDevices, setAudioOutputDevices] = useState<any[]>([]);
  const [selectedAudioDevice, setSelectedAudioDevice] = useState<string>('');
  const [selectedVideoDevice, setSelectedVideoDevice] = useState<string>('');
  const [selectedAudioOutputDevice, setSelectedAudioOutputDevice] = useState<string>('');
  const [isAudioMuted, setIsAudioMuted] = useState(false);
  const [mount, setMount] = useState<boolean>(false);
  useEffect(() => {
    if (selectedVideoDevice) {
      start();
    }
  }, [selectedVideoDevice]);

  const start = () => {
    if (echoTestRef.current) {
      echoTestRef.current.start();
    }
  };

  useEffect(() => {
    setMount(true);
  }, []);

  useEffect(() => {
    if (mount) {
      echoTestRef.current = new EchoTest(randomString(12), localVideoRef, remoteVideoRef);
    }
  }, [mount]);

  const saveSettings = async () => {
    if (!userData) {
      localStorage.setItem('video', selectedVideoDevice);
      localStorage.setItem('micro', selectedAudioDevice);
      localStorage.setItem('speaker', selectedAudioOutputDevice);
      toast.success('Данные успешно сохранены');
      stop();

      return;
    }
    try {
      const newUserData = await changeUser({
        id: userData?.id,
        settings: [
          {
            video: selectedVideoDevice,
            micro: selectedAudioDevice,
            speaker: selectedAudioOutputDevice,
          },
        ],
      });
      dispatch(setUserData(newUserData?.data || userData));
      localStorage.setItem('video', selectedVideoDevice);
      localStorage.setItem('micro', selectedAudioDevice);
      localStorage.setItem('speaker', selectedAudioOutputDevice);
      toast.success('Данные успешно сохранены');
      const data = videoDevices.find((el) => el.label === selectedVideoDevice);

      stop();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const getDevices = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();

        if (devices.some((device) => device.kind === 'audioinput')) {
          await navigator.mediaDevices
            .getUserMedia({ audio: true })
            .then((stream) => stream.getTracks().forEach((x) => x.stop()))
            .catch((err) => console.log(err));
        }

        if (devices.some((device) => device.kind === 'videoinput')) {
          await navigator.mediaDevices
            .getUserMedia({ video: true })
            .then((stream) => stream.getTracks().forEach((x) => x.stop()))
            .catch((err) => console.log(err));
        }

        {
          const devices = await navigator.mediaDevices.enumerateDevices();

          echoTestRef.current?.changeVideoDevice(
            // @ts-ignore
            devices.find((device) => device.kind === 'videoinput').deviceId || null
          );

          const a = devices.filter((device) => device.kind === 'audioinput');
          const v = devices.filter((device) => device.kind === 'videoinput');
          const d = devices.filter((device) => device.kind === 'audiooutput');

          setAudioDevices(a);
          setVideoDevices(v);
          setAudioOutputDevices(d);
          if (echoTestRef.current) {
            if (a.length > 0) {
              echoTestRef.current.changeAudioDevice(a[0].deviceId);
            }
            if (v.length > 0) {
              echoTestRef.current.changeVideoDevice(v[0].deviceId);
            }
          }
          if (localStorage.getItem('video')) {
            const find = v.find((el) => el.label === localStorage.getItem('video'));
            if (find) {
              echoTestRef.current.changeVideoDevice(find.deviceId);
              setSelectedVideoDevice(find.label);
            } else {
              echoTestRef.current.changeVideoDevice(v[0].deviceId);
              setSelectedVideoDevice(v[0].label);
            }
          } else {
            localStorage.setItem('video', v[0].label);
            setSelectedVideoDevice(v[0].label);
          }

          if (localStorage.getItem('micro')) {
            const find = a.find((el) => el.label === localStorage.getItem('micro'));
            if (find) {
              setSelectedAudioDevice(find.label);
            } else {
              setSelectedAudioDevice(a[0].label);
            }
          } else {
            localStorage.setItem('micro', a[0].label);
            setSelectedAudioDevice(a[0].label);
          }

          if (localStorage.getItem('speaker')) {
            const find = d.find((el) => el.label === localStorage.getItem('speaker'));
            if (find) {
              setSelectedAudioOutputDevice(find.label);
            } else {
              setSelectedAudioOutputDevice(d[0].label);
            }
          } else {
            localStorage.setItem('speaker', d[0].label);
            setSelectedAudioOutputDevice(d[0].label);
          }

          console.log(devices);
        }
      } catch (error) {
        console.error('Error getting devices: ', error);
      }
    };

    getDevices();
  }, []);

  useEffect(() => {
    if (selectedAudioOutputDevice && remoteVideoRef?.current) {
      const find = audioOutputDevices.find((el) => el.label === selectedAudioOutputDevice);
      if (find && find.deviceId !== 'default') {
        remoteVideoRef?.current && remoteVideoRef?.current?.setSinkId(find.deviceId);
      }
    }
  }, [selectedAudioOutputDevice, remoteVideoRef]);

  const stop = () => {
    if (echoTestRef.current.janus !== null) {
      echoTestRef.current.stop();
    }
  };

  const toggleAudioMute = () => {
    setIsAudioMuted(!isAudioMuted);
  };

  return (
    <div className="w-full h-full absolute bottom-0 top-0 right-0 left-0 z-[10000] bg-greyBase15 flex lg:items-center justify-center sm:p-[15px]">
      <div className="flex think-scroll flex-col w-full h-max pb-5 bg-white w-full md:max-w-[934px] p-[20px] sm:rounded-[30px]">
        <div className="w-full flex justify-between mb-[16px] lg:mb-[28px] relative">
          <h1 className="font-comfortaa font-bold text-2xl">Настройки</h1>

          <span className="cursor-pointer absolute -right-4 -top-4 p-5" onClick={() => stop()}>
            <CloseCrossBtn />
          </span>
        </div>
        <div className="w-full flex flex-col lg:flex-row justify-between gap-5">
          <div className="w-full max-w-[400px] flex flex-col gap-3">
            <p className="font-comfortaa font-bold text-xl">Видео</p>

            <div className="w-full flex flex-col  gap-3">
              <video
                className="w-full  h-[220px] bg-[#EEEEEE] rounded-[20px]"
                id="settings-video"
                ref={remoteVideoRef}
                autoPlay
                playsInline
                muted={!isAudioMuted}
              />

              <div className="flex flex-col w-full max-w-[400px] gap-5">
                <CustomSelect
                  className="z-[15]"
                  selected={selectedVideoDevice}
                  onChange={(a) => {
                    setSelectedVideoDevice(a);
                    const data = videoDevices.find((el) => el.label === a);
                    echoTestRef.current?.changeVideoDevice(data.deviceId);
                  }}
                  items={videoDevices.map(
                    (device) => device.label || `Audio Input ${device.deviceId}`
                  )}
                  isChange={false}
                  readOnly={true}
                  clickToOpenClose={true}
                />
              </div>
            </div>
          </div>
          <div className="w-full max-w-[400px] flex flex-col gap-3">
            <p className="font-comfortaa font-bold text-xl">Аудио</p>

            <div className="w-full  flex justify-between gap-5 flex-wrap">
              <div className="flex flex-col w-full  gap-3">
                <CustomSelect
                  className="z-[10]"
                  selected={selectedAudioDevice}
                  onChange={(a) => {
                    setSelectedAudioDevice(a);
                    const data = audioDevices.find((el) => el.label === a);
                    echoTestRef.current?.changeAudioDevice(data.deviceId);
                  }}
                  items={audioDevices.map(
                    (device) => device.label || `Audio Input ${device.deviceId}`
                  )}
                  isChange={false}
                  readOnly={true}
                  clickToOpenClose={true}
                />
                <CustomSelect
                  className="z-[5]"
                  selected={selectedAudioOutputDevice}
                  onChange={(a) => setSelectedAudioOutputDevice(a)}
                  items={audioOutputDevices.map(
                    (device) => device.label || `Audio Input ${device.deviceId}`
                  )}
                  isChange={false}
                  readOnly={true}
                  clickToOpenClose={true}
                />
                <Button
                  type="grey"
                  className="!py-[10px] !text-base font-semibold"
                  onClick={toggleAudioMute}>
                  {isAudioMuted ? 'Говорите' : 'Проверить звук'}
                </Button>
              </div>
            </div>
          </div>
        </div>

        <Button
          type={'black'}
          className="w-max ml-auto !p-[10px_30px] !text-base !font-medium !leading-4 group flex items-center gap-5 mt-[20px] lg:mt-[40px]"
          onClick={() => saveSettings()}>
          Сохранить
        </Button>
      </div>
    </div>
  );
};

export default LinkRoom;
