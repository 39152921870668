import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export function RemoteVideo({ fn, className }) {
  const videoRef = useRef();

  useEffect(() => {
    console.log('fn', fn);
    fn(videoRef?.current);
  }, [fn]);

  useEffect(() => {
    videoRef?.current?.setAttribute('playsinline', '');
  }, []);

  return (
    <div className={classNames('relative w-full h-full', className)}>
      <div className="w-full h-full z-10 absolute" />
      <video
        id="videoElement"
        className="w-full h-full hide-video-controls"
        ref={videoRef}
        controls={false}
        autoPlay></video>
    </div>
  );
}

RemoteVideo.propTypes = {
  fn: PropTypes.func.isRequired,
  className: PropTypes.string,
};
