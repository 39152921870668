import React, { FC } from 'react';
import { IconProps } from '../../types/icon.types';

export const MoveTop: FC<IconProps> = ({
  width = 27,
  height = 22,
  fill = '#111026',
  className,
}) => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="30" height="30" rx="6" fill="#F7F7F7" />
      <path
        d="M14.7197 23.7803C15.0126 24.0732 15.4874 24.0732 15.7803 23.7803L20.5533 19.0074C20.8462 18.7145 20.8462 18.2396 20.5533 17.9467C20.2604 17.6538 19.7855 17.6538 19.4926 17.9467L15.25 22.1893L11.0074 17.9467C10.7145 17.6538 10.2396 17.6538 9.9467 17.9467C9.65381 18.2396 9.65381 18.7145 9.9467 19.0074L14.7197 23.7803ZM15.7803 6.21967C15.4874 5.92678 15.0126 5.92678 14.7197 6.21967L9.9467 10.9926C9.65381 11.2855 9.65381 11.7604 9.9467 12.0533C10.2396 12.3462 10.7145 12.3462 11.0074 12.0533L15.25 7.81066L19.4926 12.0533C19.7855 12.3462 20.2604 12.3462 20.5533 12.0533C20.8462 11.7604 20.8462 11.2855 20.5533 10.9926L15.7803 6.21967ZM16 23.25L16 13.25L14.5 13.25L14.5 23.25L16 23.25ZM16 13.25L16 6.75L14.5 6.75L14.5 13.25L16 13.25Z"
        fill="#C00000"
      />
    </svg>
  );
};
