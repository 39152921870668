import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft } from '../../components/icons/arrow-left';
import { Switch } from '../../components/common/switch';
import mockMackCard8 from '../../assets/images/mac-cards/mac-card-8-full.png';
import Popup from '../../components/common/popup';
import { CloseCrossBtn } from '../../components/icons/close-cross-btn';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { selectMetaforicCards } from '../../store/psyTools.slice';
import { baseUrl } from '../../configs/api/api.config';
import { selectUser, setUserData } from '../../store/auth.slice';
import { toast } from 'react-toastify';
import { useChangeUserMutation } from '../../services/users.api';

const MetaphoricalCards = () => {
  const tool = 'metaforic_cards';
  const dispatch = useAppDispatch();
  const userData = useAppSelector(selectUser);
  const metaforicCards = useAppSelector(selectMetaforicCards);
  const [changeUser] = useChangeUserMutation();
  const [cards, setCards] = useState<string[] | null>(null);
  const [cardsName, setCardsName] = useState<string>('');
  const [card, setCard] = useState<string | null>(null);

  useEffect(() => {
    if (cards) {
      document.body.style.overflow = 'hidden';
    }
    if (!cards) {
      document.body.style.overflow = 'auto';
    }
  }, [cards]);

  const handleCheck = async () => {
    if (!userData) return;
    try {
      if (userData?.active_psy_tools?.includes(tool)) {
        const arr = userData?.active_psy_tools.filter((el) => el !== tool);
        dispatch(setUserData({ ...userData, active_psy_tools: arr }));
        await changeUser({
          id: userData.id,
          active_psy_tools: arr,
        });
      } else {
        dispatch(
          setUserData({
            ...userData,
            active_psy_tools: [...userData?.active_psy_tools, tool],
          })
        );
        await changeUser({
          id: userData.id,
          active_psy_tools: [...userData?.active_psy_tools, tool],
        });
      }
      toast.success('Данные успешно сохранены');
    } catch (error) {
      toast.error('Произошла ошибка');
    }
  };

  return (
    <div className="pb-[80px]">
      <h2 className="flex items-center gap-[14px] font-comfortaa font-semibold text-xl text-blackBase mb-5">
        <Link to={'/tools'} className="group">
          <ArrowLeft className="fill-blackBase group-hover:fill-redBase" width={21} height={16} />
        </Link>
        <span>Метафорические карты – дверь в подсознание</span>
      </h2>
      <p className="text-sm text-greyBase mb-5">
        В видео сервис встроены три универсальные колоды для диагностики и психотерапии: ресурсная,
        про деньги и про любовь. ZiNGiZi запишет в терапевтический журнал, какие карты помогли мягко
        преодолеть сопротивление и оказали трансформационный эффект.
      </p>

      <div className="flex items-center gap-5 mb-[40px]">
        <Switch
          checked={userData?.active_psy_tools?.includes(tool)}
          setChecked={() => handleCheck()}
          label={'Использовать Мак-карты в видео-сессиях'}
        />
      </div>

      <div className="flex flex-wrap gap-[26px]">
        {metaforicCards &&
          metaforicCards.psy_tool_settings
            .filter((card) => !card.value[0].includes('Релиз'))
            .map((card) => (
              <div
                key={card.id}
                className="w-[150px] md:cursor-pointer"
                onClick={() => {
                  setCards(card.value);
                  setCardsName(card.name);
                }}>
                <div className="w-[150px] h-[180px] mb-[15px]">
                  <img
                    src={baseUrl + card.value[0]}
                    alt=""
                    className="w-full h-full object-cover rounded-[10px]"
                  />
                </div>
                <p className="text-sm text-greyBase text-center">{card.name}</p>
              </div>
            ))}
        {metaforicCards &&
          metaforicCards.psy_tool_settings
            .filter((card) => card.value[0].toLowerCase().includes('релиз'))
            .map((card) => (
              <div key={card.id} className="w-[150px]">
                <div className="w-[150px] h-[180px] mb-[15px] relative">
                  <div className="absolute top-0 left-0 w-full h-full z-3 bg-blackWithOpacity40 rounded-[10px]"></div>
                  <div className="absolute top-0 right-0 z-1 font-medium text-blackBase text-xs rounded-[5px] p-[5px_15px] bg-goldBase">
                    {card.value[0]}
                  </div>
                  <img
                    src={mockMackCard8}
                    alt=""
                    className="w-full h-full object-cover rounded-[10px]"
                  />
                </div>
                <p className="text-sm text-greyBase text-center">{card.name}</p>
              </div>
            ))}
      </div>

      {cards && (
        <Popup classNameWindow="h-full">
          <div
            className="absolute top-[37px] right-[27px] md:top-[50px] md:right-[50px]"
            onClick={() => {
              setCards(null);
              setCardsName('');
            }}>
            <CloseCrossBtn className="cursor-pointer" />
          </div>
          <div className="h-full flex flex-col">
            <h2 className="font-comfortaa font-bold text-lg md:text-2xl text-blackBase mb-[5px]">
              Колода “{cardsName}”
            </h2>
            <p className="text-sm text-greyBase mb-[15px]">
              Автор колоды: <span className="font-bold text-blackBase">Марина Горбачева</span>
            </p>

            <div className="flex flex-wrap gap-[40px] overflow-y-auto scroll-white">
              {cards &&
                cards.map((card) => (
                  <div
                    className="w-[120px] md:w-[140px] h-[160px] md:h-[180px] md:cursor-pointer"
                    onClick={() => setCard(card)}>
                    <img
                      src={baseUrl + card}
                      alt=""
                      className="w-full h-full object-cover rounded-[10px]"
                    />
                  </div>
                ))}
            </div>
          </div>
        </Popup>
      )}

      {card && (
        <Popup classNameWindow="h-full !p-0 relative">
          <div className="absolute top-[5%] right-[5%]" onClick={() => setCard(null)}>
            <CloseCrossBtn className="md:cursor-pointer" />
          </div>
          <img
            src={baseUrl + card}
            alt=""
            className="w-auto h-full object-contain rounded-[10px]"
          />
        </Popup>
      )}
    </div>
  );
};

export default MetaphoricalCards;
