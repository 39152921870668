import React, { FC, useState } from 'react'
import { IconProps } from '../../types/icon.types'

export const CloseCall: FC<IconProps> = ({width = 48, height = 48, fill = '#fff', className}) => {
  const [hover, setHover] = useState(false)

  return (
    <svg
      className={`hover:cursor-pointer ${className}`}
      width={width}
      height={height}
      onMouseMove={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="10" fill={hover ? '#111026' : '#DF0000'}/>
      <g clipPath="url(#clip0_298_61)">
        <path
          d="M24.043 16.7797C19.063 16.7801 14.1086 17.8914 11.6361 20.3639C10.5244 21.4756 9.93879 22.8369 10.0164 24.4827C10.0682 25.4477 10.3781 26.3266 10.9637 26.9122C11.3945 27.3431 11.9721 27.5927 12.6782 27.4725L17.1936 26.7141C17.8741 26.5851 18.3479 26.3869 18.6582 26.0766C19.0545 25.6804 19.1751 25.1113 19.1839 24.3275L19.1924 23.1126C19.1914 22.9386 19.2595 22.7714 19.3817 22.6476C19.5195 22.5097 19.6919 22.4402 19.8298 22.3891C20.6396 22.1476 22.3369 22.0186 24.0346 22.0097C25.7234 22.0097 27.4206 22.1388 28.2305 22.3972C28.3954 22.4431 28.5463 22.5291 28.6698 22.6476C28.7815 22.7593 28.8598 22.906 28.8595 23.0953L28.8852 24.3268C28.8937 25.1117 29.0146 25.6804 29.4109 26.0766C29.7208 26.3865 30.1947 26.5847 30.8839 26.7056L35.3475 27.4637C36.0625 27.5758 36.6654 27.3346 37.1308 26.8692C37.6995 26.3005 38.0355 25.4477 38.0436 24.4566C38.0781 22.8192 37.4583 21.4583 36.3643 20.3643C33.8914 17.8914 29.0058 16.7797 24.043 16.7797Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_298_61">
          <rect width="30" height="30" fill="white" transform="translate(9 9)"/>
        </clipPath>
      </defs>
    </svg>
  )
}
