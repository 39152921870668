import React, { FC, useState } from 'react';
import { IconProps } from '../../../types/icon.types';

export const Fear: FC<IconProps> = ({
  width = 80,
  height = 80,
  fill = '#C8C8C8',
  stroke,
  className,
  needHover = true,
}) => {
  const [hover, setHover] = useState(false);

  return (
    <svg
      className={`hover:cursor-pointer ${className}`}
      width={width}
      height={height}
      onMouseMove={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        width="80"
        height="80"
        rx="40"
        fill={(hover && needHover) ? stroke || 'rgba(192, 0, 0, .5)' : fill}
        fillOpacity={(hover && needHover) && stroke ? '1' : '0.2'}
      />
      <path
        d="M40 65C53.8071 65 65 53.8071 65 40C65 26.1929 53.8071 15 40 15C26.1929 15 15 26.1929 15 40C15 53.8071 26.1929 65 40 65Z"
        fill="#FFCC4D"
      />
      <path
        d="M34.4917 35.1375C34.4917 37.8236 32.9375 39.9986 31.0194 39.9986C29.1014 39.9986 27.5472 37.8236 27.5472 35.1375C27.5472 32.4528 29.1014 30.2764 31.0194 30.2764C32.9375 30.2764 34.4917 32.4528 34.4917 35.1375ZM52.5472 35.1375C52.5472 37.8236 50.9931 39.9986 49.075 39.9986C47.1569 39.9986 45.6028 37.8236 45.6028 35.1375C45.6028 32.4528 47.1569 30.2764 49.075 30.2764C50.9931 30.2764 52.5472 32.4528 52.5472 35.1375ZM49.7236 52.5055H28.8889C27.5 52.5055 27.5 51.1166 27.5 51.1166V49.7278C27.5 49.7278 27.5 48.3347 28.8889 48.3347L49.7236 48.3389V52.5055Z"
        fill="#664500"
      />
      <path
        d="M63.9653 51.2273L53.2528 48.2814C52.2528 48.0078 51.7959 48.68 51.6264 49.0411L49.3486 47.9523L48.3334 50.0759V46.9453H45.5556V49.7231H42.7778V46.9453H39.9986V49.7231H37.2222V46.9453H34.4445V49.7231H31.6667V46.9453H28.8889V51.112H31.6667V53.8898H34.4445V51.112H37.2209V53.8898H39.9986V51.112H42.7778V53.8898H45.5556V51.112H47.8375L47.032 52.7953L49.3209 53.8898L49.1834 54.1773C49.1834 54.1773 48.8389 55.2009 49.7014 55.9481C50.5639 56.6939 57.0417 62.5245 57.0417 62.5245C57.0417 62.5245 58.2903 63.7273 59.4986 62.6342C60.5236 61.7036 64.5667 54.2731 64.9347 52.9356C65.3042 51.5967 63.9653 51.2273 63.9653 51.2273ZM58.7264 59.6592L55.0542 56.537L57.2959 52.2745L61.932 53.8467L58.7264 59.6592Z"
        fill="#99AAB5"
      />
    </svg>
  );
};
