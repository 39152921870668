import React, { FC, useState } from 'react';
import { IconProps } from '../../../types/icon.types';

export const Sadness: FC<IconProps> = ({
  width = 80,
  height = 80,
  fill = '#C8C8C8',
  stroke,
  className,
  needHover = true
}) => {
  const [hover, setHover] = useState(false);

  return (
    <svg
      className={`hover:cursor-pointer ${className}`}
      width={width}
      height={height}
      onMouseMove={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        width="80"
        height="80"
        rx="40"
        fill={(hover && needHover) ? stroke || 'rgba(192, 0, 0, .5)' : fill}
        fillOpacity={(hover && needHover) && stroke ? '1' : '0.2'}
      />
      <path
        d="M65 40C65 53.8069 53.8069 65 40 65C26.1944 65 15 53.8069 15 40C15 26.1944 26.1944 15 40 15C53.8069 15 65 26.1944 65 40Z"
        fill="#FFCC4D"
      />
      <path
        d="M30.9722 43.4722C32.8899 43.4722 34.4444 41.2958 34.4444 38.6111C34.4444 35.9264 32.8899 33.75 30.9722 33.75C29.0546 33.75 27.5 35.9264 27.5 38.6111C27.5 41.2958 29.0546 43.4722 30.9722 43.4722Z"
        fill="#664500"
      />
      <path
        d="M49.0279 43.4722C50.9455 43.4722 52.5001 41.2958 52.5001 38.6111C52.5001 35.9264 50.9455 33.75 49.0279 33.75C47.1102 33.75 45.5557 35.9264 45.5557 38.6111C45.5557 41.2958 47.1102 43.4722 49.0279 43.4722Z"
        fill="#664500"
      />
      <path
        d="M23.3318 33.7503C23.0429 33.7503 22.7498 33.66 22.4998 33.4725C21.8859 33.0128 21.7623 32.142 22.222 31.5281C26.7526 25.4864 32.7998 25.417 33.0554 25.417C33.822 25.417 34.4443 26.0392 34.4443 26.8059C34.4443 27.5712 33.8262 28.192 33.0609 28.1948C32.8457 28.1975 28.1054 28.3142 24.4443 33.1948C24.172 33.5587 23.754 33.7503 23.3318 33.7503ZM56.6679 33.7503C56.2443 33.7503 55.829 33.5587 55.5554 33.1948C51.8873 28.3045 47.1373 28.1962 46.9359 28.1948C46.172 28.1864 45.5554 27.5614 45.5595 26.7989C45.5637 26.0337 46.1804 25.417 46.9443 25.417C47.1998 25.417 53.2457 25.4864 57.7776 31.5281C58.2387 32.142 58.1137 33.0128 57.4998 33.4725C57.2498 33.66 56.9582 33.7503 56.6679 33.7503ZM47.6179 54.4156C47.6026 54.3545 46.0276 48.3337 39.9998 48.3337C33.972 48.3337 32.397 54.3545 32.3818 54.4156C32.3082 54.7114 32.4429 55.0142 32.704 55.1712C32.9651 55.3267 33.3054 55.2906 33.5318 55.0878C33.5443 55.0767 34.9387 53.8892 39.9998 53.8892C44.9859 53.8892 46.4123 55.042 46.4665 55.0878C46.5984 55.2128 46.7707 55.2781 46.9443 55.2781C47.0609 55.2781 47.179 55.2489 47.2859 55.1892C47.5582 55.0337 47.6943 54.7184 47.6179 54.4156Z"
        fill="#664500"
      />
      <path
        d="M37.2221 58.0559C37.2221 61.892 34.1138 65.0003 30.2777 65.0003C26.4416 65.0003 23.3333 61.892 23.3333 58.0559C23.3333 54.2198 28.8888 44.167 30.2777 44.167C31.6666 44.167 37.2221 54.2198 37.2221 58.0559Z"
        fill="#5DADEC"
      />
    </svg>
  );
};
