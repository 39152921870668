import { FC } from 'react';
import { PsychologEnd } from './psycholog';
import { ClientEnd } from './client';
import { useAppSelector } from '../../hooks/redux';
import { selectUser } from '../../store/auth.slice';

export const EndMeet: FC = () => {
  const userData = useAppSelector(selectUser);
  return <>{userData ? <PsychologEnd /> : <ClientEnd />}</>;
};
